import React from 'react';
import PropTypes from 'prop-types';
import './_Popup.scss';

const PopupWithoutDataCard = ({ classes, children }) => {
  return (
    <section className={`popup ${classes == null ? '' : classes}`}>
      <div className="popup__content">{children}</div>
    </section>
  );
};

PopupWithoutDataCard.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
  loading: PropTypes.bool,
  closeButton: PropTypes.bool,
  handleCloseButton: PropTypes.func,
};

export default PopupWithoutDataCard;
