import React, { useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import * as S from './styled';
import { DataCard } from '../../../components';
import { handleGraphQLErrors } from '../../../lib/utils';
import { useLoanApplicationContext } from '../../../hooks';
import { COMPLETE_APPLICATION } from '../mutations';
import { pages } from '../../../lib/constants';

const LoanPending = ({ history, actionType }) => {
  const {
    loanApplicationState,
    showErrorAlert,
    updateAuthType,
    setLoanApplicationState,
    createApplicationTrace,
  } = useLoanApplicationContext(actionType);
  const { applicationId } = loanApplicationState;
  const [completeApplication, { loading }] = useMutation(COMPLETE_APPLICATION, {
    onError(error) {
      showErrorAlert(
        handleGraphQLErrors(error) ||
          'An error occurred during your application.',
      );
      history.push('/sign-up/failure');
    },
    onCompleted({ completeApplication }) {
      if (!completeApplication) {
        showErrorAlert(
          'There was an error processing your loan. Please try again later.',
        );
      } else {
        setLoanApplicationState(prevState => ({
          ...prevState,
          portfolioId: completeApplication.application.id,
        }));
      }
    },
  });

  const handleSubmit = useCallback(() => {
    updateAuthType();
    completeApplication({ variables: { applicationId } });
  }, []);

  useEffect(() => {
    createApplicationTrace(pages.dashboard, 'Navigated to Dashboard');
    if (loanApplicationState.portfolioId) {
      createApplicationTrace(pages.dashboard, 'Navigated to Dashboard');
      history.push('/dashboard');
    }
  }, [loanApplicationState]);

  useEffect(() => {
    createApplicationTrace('loan-pending');
  }, []);
  return (
    <S.Wrapper>
      <DataCard loading={loading}>
        <S.Title>
          All Done! Your loan is under review and you'll get a feedback from us
          soon.
        </S.Title>
        <S.Aside>Want to track your loan?</S.Aside>

        <S.NextButton
          type="button"
          value="Go to your Dashboard"
          onClick={handleSubmit}
        />
      </DataCard>
    </S.Wrapper>
  );
};

export default LoanPending;
