import React, { useState, useEffect, createContext } from 'react';
import { initializeGA, logPageView } from '../../lib/GAHelper';
import { useClientInfoContext } from '../../hooks';
import { distanceInKm } from '../../lib/utils';

export const SignupContext = createContext({});

export const SignupProvider = ({ children }) => {
  const [signupState, setSignupState] = useState({});
  const {
    clientInfo: { allowedGeoLocationBoundaries },
  } = useClientInfoContext();

  useEffect(() => {
    if (
      !allowedGeoLocationBoundaries ||
      allowedGeoLocationBoundaries.length === 0
    )
      return;
    const useGeoFence = allowedGeoLocationBoundaries.length > 0;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setSignupState(prevState => ({
            ...prevState,
            latitude,
            longitude,
            useGeoFence,
          }));
        },
        () => {
          setSignupState(prevState => ({ ...prevState, locationError: true }));
        },
      );
    } else {
      setSignupState(prevState => ({ ...prevState, locationError: true }));
    }
  }, [allowedGeoLocationBoundaries]);

  useEffect(() => {
    const { latitude, longitude, useGeoFence } = signupState;
    if (latitude && longitude) {
      const pointLocations = allowedGeoLocationBoundaries;
      for (var i = 0; i < pointLocations.length; i++) {
        const dist = distanceInKm(
          latitude,
          longitude,
          pointLocations[i].lat,
          pointLocations[i].lon,
        );
        const withinRadius = Math.ceil(dist) <= pointLocations[i].radius;

        if (useGeoFence && pointLocations && withinRadius) {
          setSignupState(prevState => ({
            ...prevState,
            checkedRadius: true,
            withinRadius: true,
            pointLocations: true,
          }));
          return;
        }
      }
      setSignupState(prevState => ({
        ...prevState,
        checkedRadius: true,
        withinRadius: false,
      }));
    }
  }, [signupState.latitude, signupState.longitude]);

  useEffect(() => {
    setSignupState(prevState => ({
      ...prevState,
      closePayStack: false,
    }));
    initializeGA();
    logPageView();
  }, []);

  return (
    <SignupContext.Provider value={[signupState, setSignupState]}>
      {children}
    </SignupContext.Provider>
  );
};
