import React from 'react';
import Alert from '../Alert/Alert';
import './_LidyaMandateBanner.scss';
import { removeUnderscore } from '../../lib/utils';

const LidyaMandateBanner = ({ debitMandate }) => {
  const status = debitMandate.lidyaCollect?.collectStatus || 'Pending';
  return (
    <section className="lidya-banner">
      <Alert classes="error">
        <span>
          Your bank's direct debit mandate status is currently{' '}
          {removeUnderscore(status).toUpperCase()} and must be activated before
          the loan is disbursed.
        </span>
      </Alert>
    </section>
  );
};
export default LidyaMandateBanner;
