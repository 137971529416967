const graphQlRequest = require('../lib/requestClient');

const APPLICATION_FRAGMENT = /* GraphQL */ `
  fragment ApplicationDetails on Application {
    id
    amount
    taxAmount
    baseAmount
    chargesAmount
    status {
      name
    }
    fullAmount
    loanDuration
    dateOfRepayment
    requiredSteps
    completedSteps
    createdAt
  }
`;

const PORTFOLIO_FRAGMENT = `
    on Portfolio {
        id
        amount
        chargesAmount
        baseAmount
        fullAmount
        taxAmount
        loanDuration
        amountPaid
        amountDisbursed
        dateOfRepayment
        createdAt
    }
`;

export default class LoanService {
  static initiateApplication(
    clientId,
    amount,
    loanDuration,
    dateOfRepayment,
    dayOfRepayment,
    durationType,
    loanCategoryId,
  ) {
    amount = parseFloat(parseFloat(amount).toFixed(2));
    const query = `
            mutation($clientId: ID!, $amount: Float!, $loanDuration: Int!, $dateOfRepayment: DateTime!, $dayOfRepayment: Int, $durationType: DurationType, $loanCategoryId: ID, $source: Source) {
                initiateApplication(input: {clientId: $clientId, amount: $amount, loanDuration: $loanDuration, dateOfRepayment: $dateOfRepayment, dayOfRepayment: $dayOfRepayment, durationType:$durationType, loanCategoryId: $loanCategoryId, source: $source}) {
                    success
                    denied
                    application { ...ApplicationDetails }
                    errors{
                      message
                    }
                    warnings{
                      message
                    }
                }
            }
            ${APPLICATION_FRAGMENT}
        `;

    const data = {
      query,
      variables: {
        clientId,
        amount,
        loanDuration,
        dateOfRepayment,
        dayOfRepayment,
        durationType,
        loanCategoryId,
        source: 'WEB',
      },
    };

    return graphQlRequest()
      .post('graphql', data)
      .catch(() => {});
  }

  static getLoanCategories() {
    const query = `
        query {
          getLoanCategories {
            categories {
              id
              name
              description
              minAmount
              maxAmount
              status
              products {
                id
                name
              }
            }
          }
        }
    `;
    return graphQlRequest()
      .post('graphql', { query })
      .catch(() => {});
  }

  static customerEligibilityAmounts() {
    const query = `
        query{
            customerAmountEligibility {
                amount
                paid
            }
        }
    `;
    return graphQlRequest()
      .post('graphql', { query })
      .catch(() => {});
  }

  static applicableTenors(
    clientId,
    customerPhoneNumber,
    loanAmount,
    categoryId,
  ) {
    const query = `
        query($clientId: ID!,$customerPhoneNumber: String!,$loanAmount: Float!, $categoryId: ID){
            applicableTenors(input:{clientId: $clientId, customerPhoneNumber: $customerPhoneNumber, loanAmount: $loanAmount, categoryId: $categoryId}) {
                duration
                durationType
            }
        }
    `;
    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { clientId, customerPhoneNumber, loanAmount, categoryId },
      })
      .catch(() => {});
  }

  static generateApplicationOffers(applicationId) {
    const query = `
        mutation($applicationId: ID!) {
            generateApplicationOffers(input: {applicationId: $applicationId}) {
                application { ...ApplicationDetails }
                suggestedOffer {
                    id
                    data {
                        fullAmount
                        repaymentBreakdown{
                            principalBalance
                            expectedPayment
                            interestPortion
                            principalPortion
                            endingBalance
                            dueDate
                        }
                    }
                    policy{
                        id
                        processingFee {
                            value
                            name
                            type
                            calcBy
                        }
                    }
                }
            }
        }
        ${APPLICATION_FRAGMENT}
    `;

    return graphQlRequest()
      .post('graphql', { query, variables: { applicationId } })
      .catch(() => {});
  }

  static completeApplication(applicationId) {
    const query = `
            mutation($applicationId: ID!) {
                completeApplication(input: {applicationId: $applicationId}) {
                    approved
                    errors {
                        message
                    }
                    warnings {
                        message
                    }
                    portfolio {
                        id
                    }
                    application { ...ApplicationDetails }
                }
            }
            ${APPLICATION_FRAGMENT}
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { applicationId } })
      .catch(() => {});
  }

  static getApplicantCreditScore(applicationId) {
    const query = `
            mutation($applicationId: ID!) {
                getApplicantCreditScore(input: {applicationId: $applicationId}) {
                    success
                    application {
                        credit{
                            score
                        }
                        status {
                            name
                        }
                    }
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { applicationId } })
      .catch(() => {});
  }

  /**
   * @param applicationId
   * @return {*}
   */
  static getLoanApplicationById(applicationId) {
    const query = `
            query($applicationId: ID!) {
                applicationById(id: $applicationId) {
                    ... ${APPLICATION_FRAGMENT}
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { applicationId } })
      .catch(() => {});
  }

  /**
   * @param portfolioId
   * @return {*}
   */
  static getPortfolioById(portfolioId) {
    const query = `
            query($portfolioId: ID!) {
                portfolioById(id: $portfolioId) {
                    id
                    amount
                    chargesAmount
                    baseAmount
                    fullAmount
                    taxAmount
                    loanDuration
                    amountPaid
                    amountDisbursed
                    lateRepaymentFee
                    dateOfRepayment
                    createdAt
                    status {
                      name
                    }
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { portfolioId } })
      .catch(() => {});
  }

  static disburseLoanToBank(portfolioId, accountBankId) {
    const query = `
            mutation ($portfolioId: ID!, $accountBankId: ID!) {
                disburseLoanToBank(input: { portfolioId: $portfolioId, accountBankId: $accountBankId}) {
                    success
                    portfolio {
                        ...${PORTFOLIO_FRAGMENT}
                    }
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { portfolioId, accountBankId } })
      .catch(() => {});
  }

  /**
   * @param amount
   * @return {*}
   */
  static chargeCardOnFile() {
    const query = `
            mutation($amount: Float){
                chargeCardOnFile(input:{amount: $amount}) {
                    success
                    amount
                    error{
                        message
                    }
                }
            }
        `;
    return graphQlRequest()
      .post('graphql', { query })
      .catch(() => {});
  }
}
