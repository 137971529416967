import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 0;
  border-radius: 4px;
  cursor: pointer !important;
  padding: 1rem 1.5rem;
  width: 100%;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  text-align: center;
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  padding: 0 3rem;
`;

export const List = styled.ul`
  list-style: none;
  margin-top: -1px;
`;

export const ListItems = styled.li`
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
`;

export { Title, Aside, Wrapper, Subtitle } from '../Signup/styled';
