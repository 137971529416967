import React from 'react';
import PropTypes from 'prop-types';
import './_Tag.scss';
import { getStatusName, removeUnderscore } from '../../lib/utils';

const Tag = ({ status }) => {
  return (
    <div className={`badge ${status == null ? '' : status}`}>
      {getStatusName(status)}
      <span className="tooltip checked-width" data-title={status}>
        {' '}
        {removeUnderscore(status)}
      </span>
    </div>
  );
};

Tag.propTypes = {
  status: PropTypes.string,
};

export default Tag;
