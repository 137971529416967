import React from 'react';

const SettingsIcon = props => (
  <svg viewBox={'0 0 18 18'} fill="none" {...props}>
    <path
      d="M7.434 0a1.38 1.38 0 0 0-1.37 1.37v.862A7.19 7.19 0 0 0 3.95 3.503l-.777-.428a1.382 1.382 0 0 0-1.864.532L.17 5.662c-.36.65-.13 1.5.526 1.864l.777.435c-.05.342-.104.684-.104 1.039 0 .357.06.696.11 1.04l-.783.434a1.375 1.375 0 0 0-.526 1.858l1.138 2.054c.362.655 1.21.902 1.864.539l.783-.434c.626.53 1.326.976 2.11 1.271v.868c0 .749.62 1.37 1.369 1.37h2.348a1.38 1.38 0 0 0 1.37-1.37v-.868c.783-.295 1.482-.74 2.109-1.271l.782.434a1.384 1.384 0 0 0 1.865-.539l1.137-2.054a1.375 1.375 0 0 0-.526-1.859l-.782-.434c.05-.343.11-.682.11-1.039 0-.355-.054-.697-.104-1.04l.777-.434a1.382 1.382 0 0 0 .525-1.864l-1.137-2.055a1.382 1.382 0 0 0-1.865-.532l-.776.428a7.189 7.189 0 0 0-2.116-1.271V1.37c0-.749-.62-1.37-1.37-1.37H7.435zm0 1.174h2.348c.118 0 .195.077.195.196V2.64a.604.604 0 0 0 .416.557c.918.281 1.738.78 2.415 1.43.18.176.478.215.697.092l1.113-.617c.103-.058.205-.031.263.073l1.137 2.054c.06.108.029.213-.073.27l-1.113.617a.603.603 0 0 0-.287.63 6.116 6.116 0 0 1 0 2.5.604.604 0 0 0 .287.636l1.113.618c.102.056.133.155.073.263l-1.137 2.054c-.058.104-.16.137-.263.08l-1.113-.618a.604.604 0 0 0-.697.086 6.046 6.046 0 0 1-2.415 1.43.604.604 0 0 0-.416.563v1.271c0 .119-.077.196-.195.196H7.434c-.119 0-.196-.077-.196-.196V15.36c0-.25-.177-.49-.415-.563a6.045 6.045 0 0 1-2.416-1.43.604.604 0 0 0-.697-.086l-1.112.617c-.103.058-.206.025-.263-.08l-1.137-2.053c-.06-.108-.029-.207.073-.263l1.113-.618a.604.604 0 0 0 .287-.636 6.116 6.116 0 0 1 0-2.5.603.603 0 0 0-.287-.63L1.27 6.499c-.102-.056-.133-.161-.073-.269l1.137-2.054c.057-.104.16-.13.263-.073l1.112.617c.22.123.518.084.697-.092a6.045 6.045 0 0 1 2.416-1.43.604.604 0 0 0 .415-.557V1.37c0-.119.077-.196.196-.196zm1.174 3.522A4.313 4.313 0 0 0 4.304 9a4.313 4.313 0 0 0 4.304 4.304A4.313 4.313 0 0 0 12.912 9a4.313 4.313 0 0 0-4.304-4.304zm0 1.174A3.122 3.122 0 0 1 11.738 9a3.122 3.122 0 0 1-3.13 3.13A3.122 3.122 0 0 1 5.478 9a3.122 3.122 0 0 1 3.13-3.13z"
      fill="#fff"
    />
  </svg>
);

export default SettingsIcon;
