import React, { useEffect } from 'react';
import config from '../../config/config';
import { useClientInfoContext, useUserViewer } from '../../hooks';

const ProdCampNPSWidget = () => {
  const {
    clientInfo: { clientName },
  } = useClientInfoContext();

  const { viewerData } = useUserViewer();

  useEffect(() => {
    if (config.web.app_env === 'production' && clientName) {
      window.ProdCamp('initNPSWidget', {
        email: viewerData?.loaded?.viewer?.me?.email,
        company: {
          name: clientName,
        },
        firstName: viewerData?.loaded?.viewer?.me?.firstName,
        lastName: viewerData?.loaded?.viewer?.me?.lastName,
      });
    }
  }, [clientName, viewerData?.loaded?.viewer]);

  return <></>;
};
export default ProdCampNPSWidget;
