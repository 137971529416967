import * as React from 'react';

const CloseIcon_2 = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M1 1L10.5 10.5M20 20L10.5 10.5M10.5 10.5L20 1L1 20"
      stroke="#A5ADB6"
    />
  </svg>
);

export default CloseIcon_2;
