import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { useQuery } from '@apollo/client';
import { notify } from 'react-notify-toast';
import { handleGraphQLErrors } from '../../../lib/utils';

import { PAYMENT_INFO_QUERY } from './queries';
import Constants from '../../../lib/constants';
import { Loader } from '../../../components';

const getDefault = array => array.find(({ isDefault }) => isDefault === true);

const getData = queryResult => {
  const { data, loading, error, refetch } = queryResult;

  const props = {
    error,
    refetchCards: refetch,
    loading,
    email: '',
    bankAccounts: [],
    cards: [],
    defaultBankAccount: {},
    defaultCard: {},
    accountId: '',
  };

  if (data?.viewer && !loading && !error) {
    const { viewer } = data;
    props.email = viewer?.me?.email;
    props.bankAccounts = viewer?.account?.bankAccounts;
    props.defaultBankAccount = getDefault(viewer?.account?.bankAccounts);
    props.cards = viewer?.account?.cards;
    props.defaultCard = getDefault(viewer?.account?.cards);
    props.accountId = viewer?.account?.id;
  }
  return props;
};

export const usePaymentInformation = () => {
  const [errorMessage, setErrorMessage] = useState(false);

  const queryResult = useQuery(PAYMENT_INFO_QUERY, {
    onError(error) {
      setErrorMessage(handleGraphQLErrors(error));
      return;
    },
  });

  return {
    loading: queryResult.loading,
    errorMessage,
    data: getData(queryResult),
  };
};

const PaymentInformationProvider = ({ children }) => {
  return (
    <Query query={PAYMENT_INFO_QUERY}>
      {queryResult => {
        const { loading, error } = queryResult;

        if (loading) return <Loader />;

        if (error) {
          notify.show(
            'An error has occurred. Please try again later.',
            Constants.status.ERROR,
            4000,
          );
        }

        return children(getData(queryResult));
      }}
    </Query>
  );
};

export default PaymentInformationProvider;
