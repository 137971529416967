import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSignupContext, useUserViewer } from '../../hooks';

const SignupRoute = ({ path, component: Component }) => {
  const { viewerData } = useUserViewer();
  const { setSignupState } = useSignupContext();

  useEffect(() => {
    const user = viewerData?.loaded?.viewer?.me;
    setSignupState(prevState => ({
      ...prevState,
      isPhoneConfirmed: user?.isPhoneConfirmed || false,
      isEmailConfirmed: user?.isEmailConfirmed || false,
    }));
  }, [viewerData, setSignupState]);

  return <Route exact path={path} render={props => <Component {...props} />} />;
};

export default SignupRoute;
