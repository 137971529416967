import styled from 'styled-components';
export { Wrapper, Title, Subtitle, PreviousButton } from '../styled';

export const Aside = styled.aside`
  color: rgba(0, 44, 91, 0.5);
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  margin: 2.2rem 0 0;
`;

export const CheckBoxGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const Checkbox = styled.input`
  span {
    border: none;
  }
`;

export const Text = styled.h4`
  font-size: 14px;
`;
