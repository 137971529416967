import React, { useState, useMemo, useEffect } from 'react';
import { Popup, Button, Alert } from '../../components';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { REQUEST_MANDATE_OTP, VALIDATE_MANDATE_OTP } from './mutations';
import * as Yup from 'yup';
import { CheckIcon } from '../../components/Icon';
import {
  NumberInput,
  Checkbox,
  SubmitButton,
  Input,
} from '../../components/FormikElements';
import { numberWithCommas } from '../../lib/utils';
import * as S from './styled';

const ActivatePopup = ({
  handleCloseModal,
  requestMandateOtp,
  loading,
  loanAmount,
  otpRequestError,
}) => {
  const mandateActivationSchema = Yup.object().shape({
    termsandagreement: Yup.boolean().oneOf(
      [true],
      'We need your consent before we can continue',
    ),
  });

  return (
    <Popup
      loading={loading}
      title="Activate Direct Debit"
      closeButton={true}
      handleCloseButton={handleCloseModal}
    >
      {otpRequestError && (
        <Alert classes="error">
          There was an error sending your otp, please try again
        </Alert>
      )}

      <p>
        Your loan request of {numberWithCommas(loanAmount)} has been approved.{' '}
      </p>

      <p>
        Before disbursement, we require that you activate direct debit to enable
        us withdraw directly from your account on withdrawal dates. We require
        that you request and validate OTP for mandate activation.
      </p>

      <Formik
        initialValues={{
          termsandagreement: false,
        }}
        validationSchema={mandateActivationSchema}
        // eslint-disable-next-line no-unused-vars
        onSubmit={values => {
          requestMandateOtp();
        }}
      >
        <Form>
          <Checkbox name="termsandagreement">
            I consent to have my account directly debited.
          </Checkbox>

          <S.Btns>
            <SubmitButton value="Send OTP" />
          </S.Btns>
        </Form>
      </Formik>
    </Popup>
  );
};

const VerifyOtpPopup = ({
  setActivePopup,
  handleCloseModal,
  authParams,
  debitMandateId,
  clientId,
  loading,
  otpRequestError,
  debitMandate,
}) => {
  const [otpValidateError, setOtpValidateError] = useState(null);

  const [formattedAuthParams, setFormattedAuthParams] = useState([]);

  const [validateOtpForMandateActivation, { loading: validationLoading }] =
    useMutation(VALIDATE_MANDATE_OTP, {
      // eslint-disable-next-line no-unused-vars
      onError: error => {
        setOtpValidateError(
          'There was an error validating your OTP, please check your OTP and try again',
        );
      },

      onCompleted: ({ validateOtpForMandateActivation }) => {
        if (validateOtpForMandateActivation.success) {
          setActivePopup(2);
        } else {
          setOtpValidateError(
            'There was an error validating your OTP, please check your OTP and try again.',
          );
        }
      },
    });

  const validateMandateOtp = values => {
    const params = [];

    const keys = Object.keys(values);

    const getParamName = key => {
      let value = '';

      formattedAuthParams.forEach(formattedParam => {
        if (formattedParam[`${key}`] != null) {
          value = formattedParam[`${key}`];
        }
      });

      return value;
    };

    keys.forEach(key => {
      params.push({
        [`${key}`]: getParamName(key),

        value: values[`${key}`],
      });
    });

    validateOtpForMandateActivation({
      variables: {
        debitMandateId,
        clientId,
        authParams: params,
      },
    });
  };

  const initialValues = useMemo(() => {
    let values = {};

    formattedAuthParams.forEach((_, index) => {
      values[`param${index + 1}`] = '';
    });

    return values;
  }, [formattedAuthParams]);

  const validationSchema = useMemo(() => {
    let schema = {};

    formattedAuthParams.forEach((_, index) => {
      schema[`param${index + 1}`] = Yup.string()
        .required('Field is required')
        .max(8, 'Input should not be more than 8 characters');
    });

    return Yup.object().shape(schema);
  }, [formattedAuthParams]);

  const authFields = useMemo(() => {
    return formattedAuthParams.map((formattedParam, index) => {
      if (
        formattedParam[`param${index + 1}`] === 'OTP' ||
        formattedParam[`param${index + 1}`] === 'CARD'
      ) {
        return (
          <NumberInput
            key={index}
            label={formattedParam[`label${index + 1}`]}
            name={`param${index + 1}`}
            placeholder={formattedParam[`description${index + 1}`]}
          />
        );
      } else {
        return (
          <Input
            key={index}
            label={formattedParam[`label${index + 1}`]}
            name={`param${index + 1}`}
            placeholder={formattedParam[`description${index + 1}`]}
          />
        );
      }
    });
  }, [formattedAuthParams]);

  useEffect(() => {
    if (authParams) {
      let keys = Object.keys(authParams);

      let paramCount = 0;

      keys.forEach(key => {
        if (key.includes('param')) {
          paramCount++;
        }
      });

      let validationParams = [];

      for (let i = 1; i <= paramCount; i++) {
        validationParams.push({
          [`param${i}`]: authParams[`param${i}`],

          [`description${i}`]: authParams[`description${i}`],

          [`label${i}`]: authParams[`label${i}`],
        });
      }

      setFormattedAuthParams(validationParams);
    }
  }, [authParams]);

  return (
    <Popup
      loading={loading || validationLoading}
      title="Activate Direct Debit"
      closeButton={true}
      handleCloseButton={handleCloseModal}
    >
      {(otpValidateError || otpRequestError) && (
        <Alert classes="error">{otpValidateError || otpRequestError}</Alert>
      )}

      <p>Please provide the following details</p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          validateMandateOtp(values);
        }}
      >
        <Form>
          {authFields}
          <S.RemitaInstruction>
            Having trouble with electronic mandate?
            <a
              href={debitMandate.formUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Skip and download my mandate form
            </a>
            Please take the downloaded mandate form to your bank to activate
            your mandate.
          </S.RemitaInstruction>
          <S.Btns>
            <SubmitButton value="Validate OTP" />
          </S.Btns>
        </Form>
      </Formik>
    </Popup>
  );
};

const CompletedPopup = ({ handleCloseModal, refetch }) => {
  const refetchData = () => {
    refetch();

    handleCloseModal();
  };

  return (
    <Popup closeButton={true} handleCloseButton={refetchData}>
      <div className="center-text feedback-icon">
        <CheckIcon />

        <p>Thank you, Direct Debit is now activated on your account.</p>
      </div>

      <S.Btns>
        <Button classes="right" onClick={refetchData}>
          Back to Dashboard
        </Button>
      </S.Btns>
    </Popup>
  );
};

const ElectronicRemitalMandate = ({
  handleCloseModal,
  debitMandate,
  clientId,
  refetch,
  loanAmount,
}) => {
  const [activePopup, setActivePopup] = useState(0);

  const [authParams, setAuthParams] = useState([]);

  const [otpRequestError, setOtpRequestError] = useState(null);

  const [requestOtpForMandateActivation, { loading }] = useMutation(
    REQUEST_MANDATE_OTP,

    {
      // eslint-disable-next-line no-unused-vars
      onError: error => {
        setOtpRequestError(
          'There was an error sending the OTP, please try again',
        );
      },

      onCompleted: ({ requestOtpForMandateActivation }) => {
        if (requestOtpForMandateActivation.status === 'SUCCESS') {
          setActivePopup(1);

          setAuthParams(requestOtpForMandateActivation.authParams[0]);
        } else {
          setOtpRequestError(
            'There was an error sending the OTP, please try again',
          );
        }
      },
    },
  );

  const requestMandateOtp = () => {
    requestOtpForMandateActivation({
      variables: {
        debitMandateId: debitMandate.id,
        clientId,
      },
    });
  };

  const popup = useMemo(() => {
    switch (activePopup) {
      case 0:
        return (
          <ActivatePopup
            setActivePopup={setActivePopup}
            handleCloseModal={handleCloseModal}
            requestMandateOtp={requestMandateOtp}
            loanAmount={loanAmount}
            loading={loading}
            otpRequestError={otpRequestError}
          />
        );

      case 1:
        return (
          <VerifyOtpPopup
            setActivePopup={setActivePopup}
            handleCloseModal={handleCloseModal}
            authParams={authParams}
            payerPhone={debitMandate.payerPhone}
            debitMandateId={debitMandate.id}
            debitMandate={debitMandate}
            clientId={clientId}
            requestMandateOtp={requestMandateOtp}
            loading={loading}
            otpRequestError={otpRequestError}
          />
        );

      case 2:
        return (
          <CompletedPopup
            setActivePopup={setActivePopup}
            handleCloseModal={handleCloseModal}
            refetch={refetch}
          />
        );

      default:
        return null;
    }
  }, [activePopup, authParams, loading, otpRequestError]);

  return <S.Wrapper>{popup}</S.Wrapper>;
};

export default ElectronicRemitalMandate;
