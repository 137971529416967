import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';

export const CUSTOMER_BVN_STATUS = gql`
  query (
    $bvn: String!
    $clientId: ID!
    $bvnDOB: DateTime
    $bvnPhoneDigits: String
  ) {
    customerBvnStatus(
      input: {
        bvn: $bvn
        clientId: $clientId
        bvnDOB: $bvnDOB
        bvnPhoneDigits: $bvnPhoneDigits
      }
    ) {
      hasCustomerAccount
      firstName
      lastName
    }
  }
`;

export const useGetCustomerBvnStatus = () => {
  const [customerBvnStatus, setCustomerBvnStatus] = useState();

  const [getCustomerBvnStatusQuery, { loading, error }] = useLazyQuery(
    CUSTOMER_BVN_STATUS,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setCustomerBvnStatus(data?.customerBvnStatus);
      },
    },
  );

  const getCustomerBvnStatus = ({ bvn, clientId, bvnPhoneDigits, bvnDOB }) => {
    getCustomerBvnStatusQuery({
      variables: {
        bvn,
        clientId,
        bvnPhoneDigits,
        bvnDOB,
      },
    });
  };

  return {
    getCustomerBvnStatus,
    customerBvnStatus,
    loading,
    getCustomerBvnStatusError: error,
  };
};
