import styled from 'styled-components';

export const StyledBankCard = styled.div`
  cursor: pointer;
  .radio {
    input {
      :checked + span {
        :after {
          background-color: ${props => props.theme.secondaryColor} !important;
        }
      }
    }
  }
`;

export const CustomTop = styled.div`
  display: flex;
  align-items: center;

  .circle {
    width: 20px;
    height: 20px;
    border: 2px solid rgb(210 214 219);
    border-radius: 50%;
    background-color: ${({ theme, active }) =>
      active ? theme.secondaryColor : ''} !important;
  }

  .left_label {
    color: ${props => props.theme.secondaryColor} !important;
    margin-left: 12px;
    font-size: 12px;
    font-weight: bold;
  }
`;
