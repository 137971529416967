import { useMutation, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';

const SEND_BVN_OTP_TO_CHANNEL = gql`
  mutation ($channel: String!, $verificationId: ID!, $clientId: ID!) {
    sendBvnOtpToChannel(
      input: {
        channel: $channel
        verificationId: $verificationId
        clientId: $clientId
      }
    ) {
      detail
      verificationId
    }
  }
`;

const CONFIRM_BVN_OTP = gql`
  mutation ($otp: String!, $verificationId: ID!, $clientId: ID!) {
    confirmBvnOtp(
      input: { otp: $otp, verificationId: $verificationId, clientId: $clientId }
    ) {
      firstName
      gender
      lastName
      phone
      title
      dateOfBirth
    }
  }
`;

const TRIGGER_BVN_VERIFICATION = gql`
  query ($bvn: String!, $clientId: ID!) {
    getBvnOtpChannels(input: { bvn: $bvn, clientId: $clientId }) {
      contacts
      detail
      verificationId
    }
  }
`;

export const useGetOtpChannels = () => {
  const [bvnOtpChannels, setBvnOtpChannels] = useState();

  const [getBvnOtpChannelsQuery, { loading, error }] = useLazyQuery(
    TRIGGER_BVN_VERIFICATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setBvnOtpChannels(data?.getBvnOtpChannels);
      },
    },
  );

  const getBvnOtpChannels = ({ bvn, clientId }) => {
    getBvnOtpChannelsQuery({
      variables: {
        bvn,
        clientId,
      },
    });
  };

  return {
    getBvnOtpChannels,
    bvnOtpChannels,
    getBvnOtpChannelsIsLoading: loading,
    bvnOtpChannelsError: error,
  };
};

export const useSendBvnOtpToChannel = () => {
  const [sendBvnOtpToChannelData, setSendbvnOtpToChannelData] = useState();

  const [sendBvnOtpToChannelMutation, { loading, error }] = useMutation(
    SEND_BVN_OTP_TO_CHANNEL,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setSendbvnOtpToChannelData(data?.sendBvnOtpToChannel);
      },
    },
  );

  const sendOtpToChannel = ({ channel, verificationId, clientId }) => {
    sendBvnOtpToChannelMutation({
      variables: {
        channel,
        verificationId,
        clientId,
      },
    });
  };

  return {
    sendOtpToChannel,
    sendBvnOtpToChannelData,
    sendOtpToChannelIsLoading: loading,
    sendOtpToChannelError: error,
  };
};

export const useConfirmBvnOtp = () => {
  const [bvnDetails, setBvnDetails] = useState();

  const [confirmOtpMutation, { loading, error }] = useMutation(
    CONFIRM_BVN_OTP,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setBvnDetails(data?.confirmBvnOtp);
      },
    },
  );

  const confirmOtp = ({ otp, verificationId, clientId }) => {
    confirmOtpMutation({
      variables: {
        otp,
        verificationId,
        clientId,
      },
    });
  };

  return {
    confirmOtp,
    bvnDetails,
    confirmOtpChannelsIsLoading: loading,
    confirmOtpError: error,
  };
};
