import React, { Fragment } from 'react';
import moment from 'moment';

import './_MyLoans.scss';

import {
  DataCard,
  RepaymentCard,
  ReviewFeedback,
  Tag,
  TransactionTable,
} from '../../components';

import { ViewerProvider } from '../providers';
import { NairaAmount, NewLoanIcon, SadSmiley } from '../../components/Icon';
import { Link } from 'react-router-dom';
import {
  MANDATE_TYPES,
  PENDING_DISBURSEMENT_STATUSES,
  removeUnderscore,
} from '../../lib/utils';
import ProdCampNPSWidget from '../../components/ProdCampNPSWidget/ProdCampNPSWidget';
import RecovaMandateBanner from '../../components/RecovaMandateBanner/RecovaMandateBanner';
import LidyaMandateBanner from '../../components/LidyaMandateBanner/LidyaMandateBanner';

const MyLoans = () => (
  <Fragment>
    <ViewerProvider>
      {({ currentLoan, applications, latestLoan, refetch }) => {
        const currentLoanRepayment =
          (currentLoan.portfolioNumber &&
            currentLoan.repayments.find(
              repayment => repayment.status.name === 'PENDING',
            )) ||
          {};

        const pendingDebitMandate =
          PENDING_DISBURSEMENT_STATUSES.includes(currentLoan?.status?.name) &&
          currentLoan?.debitMandateMeta?.status?.name === 'PENDING_ACTIVATION';

        return applications.length !== 0 ? (
          <section className={'container'}>
            {pendingDebitMandate &&
              currentLoan?.debitMandateMeta?.type?.name ===
                MANDATE_TYPES.RECOVA && (
                <RecovaMandateBanner
                  debitMandate={currentLoan.debitMandateMeta}
                />
              )}

            {pendingDebitMandate &&
              currentLoan.debitMandateMeta?.type?.name ===
                MANDATE_TYPES.LIDYA && (
                <LidyaMandateBanner
                  debitMandate={currentLoan.debitMandateMeta}
                />
              )}

            {currentLoan.id ||
            (latestLoan && latestLoan.status.name === 'UNDER_REVIEW') ||
            (latestLoan && latestLoan.status.name === 'PENDING') ? (
              ''
            ) : (
              <Link
                to="/application/new"
                className="button icon-left visible-mobile"
              >
                <span className="icon">
                  <NewLoanIcon />
                </span>
                <span className={'nav-text'}>New Loan</span>
              </Link>
            )}

            {(latestLoan.status.name === 'UNDER_REVIEW' ||
              latestLoan.status.name === 'PENDING') && (
              <DataCard>
                <RepaymentCard
                  amountPaid="0.00"
                  fullAmount={latestLoan.fullAmount}
                  percent="0"
                  repaymentAmount="n/a"
                  repaymentDate={latestLoan.dateOfRepayment}
                  overlay={true}
                  status={removeUnderscore(latestLoan.status.name)}
                  completedSteps={latestLoan?.completedSteps}
                />
              </DataCard>
            )}

            {currentLoan.id && (
              <DataCard>
                <RepaymentCard
                  application={currentLoan}
                  amountPaid={currentLoan.amountPaid}
                  fullAmount={currentLoan.fullAmount}
                  percent={currentLoan.percent}
                  repaymentAmount={currentLoan.fullAmount}
                  repaymentDate={
                    currentLoanRepayment.dueDate || currentLoan.dateOfRepayment
                  }
                  portfolioNumber={currentLoan.portfolioNumber}
                />
              </DataCard>
            )}

            <DataCard>
              <TransactionTable
                txTableHeaders={[
                  'Date',
                  'Status',
                  'Loan ID',
                  'Loan Amount',
                  'Repayment Amount',
                  'Repayment Date',
                  '',
                ]}
              >
                {applications.map((data, index) => (
                  <li key={index}>
                    <div
                      className="tx-column transaction-date"
                      data-header="Date"
                    >
                      {moment(data.createdAt).format('ll')}
                    </div>
                    <div className="tx-column" data-header="Status">
                      <Tag
                        status={
                          data.portfolio
                            ? data.portfolio.status.name.toLowerCase() ===
                              'closed'
                              ? 'paid'
                              : data.portfolio.status.name.toLowerCase() ===
                                'disbursed'
                              ? 'active'
                              : data.portfolio.status.name.toLowerCase() ===
                                'pending_disbursement'
                              ? 'approved'
                              : removeUnderscore(
                                  data.portfolio.status.name,
                                ).toLowerCase()
                            : removeUnderscore(data.status.name).toLowerCase()
                        }
                      />
                    </div>
                    <div className="tx-column loan-id" data-header="Loan ID">
                      <span>
                        {data.portfolio
                          ? data.portfolio.portfolioNumber
                          : data.applicationNumber}
                      </span>
                    </div>
                    <div className="tx-column full" data-header="Loan Amount">
                      <span>
                        <NairaAmount amount={data.amount} />
                      </span>
                    </div>
                    <div className="tx-column" data-header="Repayment Amount">
                      <span>
                        <NairaAmount
                          amount={
                            data?.portfolio
                              ? data?.portfolio?.fullAmount
                              : data?.fullAmount
                          }
                        />
                      </span>
                    </div>
                    <div className="tx-column" data-header="Repayment Date">
                      <span>{moment(data.dateOfRepayment).format('ll')}</span>
                    </div>
                    <div className="tx-column full">
                      {data.status.name === 'PENDING' &&
                      !data?.completedSteps?.includes(
                        'COMPLETE_APPLICATION',
                      ) ? (
                        <Link
                          to={`/${
                            data.customApplicationForm
                              ? 'application-custom'
                              : 'application'
                          }/${data.applicationNumber}`}
                          data-testid="view-details"
                          className="button block active small"
                        >
                          Complete Application
                        </Link>
                      ) : (
                        <Link
                          to={`/loans/details?applicationNumber=${data.applicationNumber}`}
                          data-testid="view-details"
                          className="button block secondary small"
                        >
                          View Details
                        </Link>
                      )}
                    </div>
                  </li>
                ))}
              </TransactionTable>
            </DataCard>

            <ReviewFeedback application={latestLoan} refetch={refetch} />
          </section>
        ) : (
          <section className="container no-loan">
            <DataCard>
              <div className="smiley-holder">
                <SadSmiley />
              </div>
              <h3 className="center-text">You have no active loan</h3>
              <Link to="/application/new" className="button">
                Apply for a new loan
              </Link>
            </DataCard>
          </section>
        );
      }}
    </ViewerProvider>

    <ProdCampNPSWidget />
  </Fragment>
);

export default MyLoans;
