import React from 'react';
import { NewFailureIcon } from '../../Icon';
import { Popup } from '../../index';

const OfferLetterSignFailed = () => {
  return (
    <Popup classes="offer-letter-popup">
      <div className="response-icon-holder">
        <NewFailureIcon />
      </div>

      <h4 className={'data-card__title'}>Unable to Sign Letter</h4>

      <p className="center-text">
        Signing of your offer letter was unsuccessful. This may be due to the
        submission of incorrect OTP code. Click the button below to try again
      </p>
    </Popup>
  );
};

export default OfferLetterSignFailed;
