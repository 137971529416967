import React from 'react';
import { Route } from 'react-router-dom';

const ApplicationRoute = ({ component: Component, path }) => {
  return (
    <Route path={path}>
      <Component />
    </Route>
  );
};

export default ApplicationRoute;
