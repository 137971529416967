import { useLoanApplicationContext } from '../../../hooks';
import { useLazyQuery } from '@apollo/client';
import { RESOLVE_OKRA_BANK } from '../queries';
import { handleGraphQLErrors } from '../../../lib/utils';
import config from '../../../config/config';
import { pages } from '../../../lib/constants';
import Okra from 'npm-okrajs';
import { useCallback, useEffect, useRef, useState } from 'react';

const useOkraPageForm = () => {
  const okraDataRef = useRef({});
  const [okraError, setOkraError] = useState(null);
  const [isOkraWidgetClosed, setOkraWidgetClosed] = useState(false);
  const [bankStatementError, setBankStatementError] = useState(false);
  const {
    setLoanApplicationError,
    setLoanApplicationState,
    loanApplicationIsLoading,
    loanApplicationState,
    showErrorAlert,
    updateContextState,
    createApplicationTrace,
    clientInfo: { okraWidgetUrl, okraDirectDebitIsEnabled },
    goToNextPage,
  } = useLoanApplicationContext();

  const [runResolveOkraBank, { loading }] = useLazyQuery(RESOLVE_OKRA_BANK, {
    onError(error) {
      const errorMessage = handleGraphQLErrors(error);
      setLoanApplicationError(
        errorMessage ||
          'There was an error getting your bank details from okra, please fill manually.',
      );
      goToNextPage();
    },
    onCompleted({ resolveOkraBank }) {
      handleReturnedData(resolveOkraBank);
    },
  });

  const handleReturnedData = useCallback(
    resolveOkraBank => {
      if (!resolveOkraBank) {
        setLoanApplicationError(
          'There was an error getting your bank details from okra, please fill manually.',
        );
        return;
      }

      const { customer_id, record_id, accounts } = okraDataRef.current;
      const { id, name } = resolveOkraBank;

      const connectedAccount = config.okra.findAccount
        ? accounts?.find(account => account?.connected === false)
        : accounts?.find(account => account?.connected === true);

      setLoanApplicationState(prevState => ({
        ...prevState,
        bankId: id,
        okraBankName: name,
        accountNumber: connectedAccount?.nuban,
        okraAccountName: connectedAccount?.okraAccountName,
        okraCustomerId: customer_id,
        okraRecordId: record_id,
        okraAccountId: connectedAccount?.okraAccountId,
      }));
    },
    [okraDataRef.current],
  );

  const processOkraResponse = useCallback(okra_data => {
    const { bank_id, accounts } = okra_data;
    const connectedAccount = accounts.find(account => account.connected);
    okraDataRef.current = { ...okra_data, connectedAccount };
    runResolveOkraBank({ variables: { okraBankId: bank_id } });
  }, []);

  const handleOkraClose = useCallback(() => {
    setOkraWidgetClosed(true);
  }, []);

  const handleOkraError = useCallback(errObj => {
    // There seem to be an issue with the package `this.Okra` returns undefined so we're passing Okra as param (e) which is a fallback.
    Okra.forceClose(Okra);
    setOkraWidgetClosed(true);
    showErrorAlert(errObj && errObj.msg);
    setBankStatementError(true);
  }, []);

  useEffect(() => {
    const { current } = okraDataRef;
    const { okraCustomerId } = loanApplicationState;
    if (isOkraWidgetClosed && okraCustomerId) {
      if (Object.keys(current).length) {
        goToNextPage();
      } else {
        setOkraError(
          'There was an error getting your bank statement, please try again or contact customer care',
        );
      }
    }
  }, [okraDataRef, isOkraWidgetClosed, loanApplicationState]);

  useEffect(() => {
    createApplicationTrace(pages.okra, 'Triggered Okra Widget');
  }, []);

  const initiateOkra = useCallback(() => {
    const short_url = okraWidgetUrl.split('/');
    Okra.buildWithShortUrl({
      short_url: short_url[short_url.length - 1],
      onSuccess: data => {
        processOkraResponse(data);
      },
      onClose: () => {
        handleOkraClose();
      },
      onError: errObj => {
        handleOkraError(errObj);
      },
    });
    setOkraWidgetClosed(false);
  }, [okraWidgetUrl]);

  const handleProceedToNextStep = () => {
    initiateOkra();
  };

  const handleSkip = () => {
    // todo: Set Upload bank statement to true
    return goToNextPage();
  };

  useEffect(() => {
    const {
      loanDuration,
      payStackReference,
      onePipeAccountBanks,
      onePipeAccountCards,
      isFromOkraPage,
      bankStatementProvider,
    } = loanApplicationState;
    updateContextState('okra', {
      loanDuration,
      payStackReference,
      onePipeAccountBanks,
      onePipeAccountCards,
      isFromOkraPage,
      bankStatementProvider,
    });
  }, []);

  return {
    okraError,
    bankStatementError,
    loanApplicationIsLoading,
    clientInfo: { okraDirectDebitIsEnabled },
    handleProceedToNextStep,
    loading,
    setBankStatementError,
    initiateOkra,
    handleSkip,
  };
};
export default useOkraPageForm;
