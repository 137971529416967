import styled from 'styled-components';
import placeholder from '../../../static/icons/upload-icon.svg';

export { Wrapper, Title, Subtitle, Aside } from '../styled';

export const ImageUpload = styled.label`
  border: 1px dashed ${({ theme }) => theme.secondaryColor};
  border-radius: 5px;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  padding: 2rem;
  max-width: 40rem;

  input {
    display: none;
    height: 0;
    padding: 0;
    width: 0;
  }

  section {
    background: url(${placeholder}) center top no-repeat;
    font-size: 18px;
    font-weight: $font-semibold;
    line-height: 1.5em;
    padding-top: 50px;
    text-align: center;
  }
`;
