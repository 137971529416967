import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { NextButton as Next } from '../Signup/styled';
import { CloseIcon as Close } from '../../../components/Icon';

const phone = () => {
  return style => `@media screen and (max-width: 768px) { ${style} }`;
};

export const Modal = styled.div`
  background: #333;
  h4.data-card__title {
    text-align: center;
  }
`;

export const Summary = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 10px 8px;
  background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 4px;

  ${phone()`width: 100%`}
`;

export const Detail = styled.div`
  padding: 4px 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  p {
    margin: 0;
    &:first-child {
      font-weight: ${({ theme }) => theme.fonts.font_semibold};
      opacity: 0.9;
    }

    &:nth-child(2) {
      font-weight: ${({ theme }) => theme.fonts.font_bold};
    }
  }
`;

export const ProcessingFeeInfo = styled.div`
  margin: 15px 55px;
  max-width: 80%;
  text-align: center;
  font-size: ${({ theme }) => theme.to_rem('18px')};
`;

export const ViewRepaymentButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  width: 100%;
  margin: 2rem auto;
  text-align: center;
  font-size: ${({ theme }) => theme.to_rem('24px')};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const CloseIcon = styled(Close)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`;

const displayBlock = css`
  ${phone()`
    display: block;
  `}
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 1rem;
  border-collapse: separate;
  ${displayBlock}
  ${phone()`
    border: none;
  `}
`;

const tableData = css`
  padding: 0.5em;
  text-align: center;
  border: none;
`;

export const TableHeader = styled.thead`
  tr {
    ${phone()`
      position: absolute;
      top: -9999px;
      left: -9999px;
    `}
  }
`;

export const TableHeading = styled.th`
  background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
  ${tableData}
  ${displayBlock}
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.colors.text_color_primary};
  font-weight: ${({ theme }) => theme.fonts.font_bold};
  font-size: ${({ theme }) => theme.to_rem('20px')};
`;

export const TableBody = styled.tbody`
  ${displayBlock}
  ${phone()`
    td:nth-of-type(1):before {
      content: 'Repayment Date';
    }
    td:nth-of-type(2):before {
      content: 'Principal';
    }
    td:nth-of-type(3):before {
      content: 'Interest';
    }
    td:nth-of-type(4):before {
      content: 'Total Payment';
    }
  `}
  tr:nth-of-type(odd) {
    @media (max-width: 768px),
      (min-device-width: 768px) and (max-device-width: 1024px) {
      background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
      border: 1px solid ${({ theme }) => theme.primaryColor};
      border-radius: 1rem;
    }
  }
`;

export const TableRow = styled.tr`
  ${displayBlock}
  &:nth-of-type(even) {
    background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
    ${phone()`
      background: transparent;
    `}
  }
  ${phone()`
    padding: 4px;
    margin: 6px;
    line-height: 1rem;
  `}
  &:last-of-type {
    font-weight: ${({ theme }) => theme.fonts.font_bold};
    td:nth-of-type(1) {
      span {
        display: none;
      }
    }
    @media (max-width: 768px),
      (min-device-width: 768px) and (max-device-width: 1024px) {
      background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
      border: 1px solid ${({ theme }) => theme.primaryColor};
      border-radius: 1rem;
      td:nth-of-type(1) {
        color: ${({ theme }) => theme.colors.color_grey};
        b {
          display: none;
        }
        span {
          display: block;
        }
        &:before {
          content: 'Total';
          font-weight: 800;
          opacity: 1;
          color: ${({ theme }) => theme.colors.text_color_primary};
        }
      }
      &:nth-of-type(2) {
        display: none;
      }
    }
  }
`;

export const TableData = styled.td`
  font-weight: ${({ theme }) => theme.fonts.font_regular};
  ${tableData}
  ${displayBlock}
  ${phone()`
    border: none;
		position: relative;
    padding: 2px; 
		padding-left: 50%;
    text-align: right;
    font-weight: 800;
    &:before {
      position: absolute;
      font-weight: 600;
      vertical-align: -50%;
      top: 2px;
      left: 6px;
      width: 45%;
      padding-right: 10px; 
      white-space: nowrap;
      text-align: left;
      opacity: 0.8;
    }
    &:last-of-type {
      padding-right: 4px;
    }
  `}
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 0;
  cursor: pointer !important;
`;

export const NextButton = styled(Next)`
  text-align: center;
  margin: 1rem auto;
  cursor: pointer !important;
`;

export const ContinueButton = styled(Button)`
  text-align: center;
  margin: 1rem auto;
  width: 100%;
  border-radius: 4px;
  padding: 0.5rem 0;
`;
