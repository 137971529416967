const { hotjarIds } = require('./constants');

const getIgniteUrl = () => {
  return process.env.REACT_APP_BASE_URL;
};

const getHotjarId = () => {
  const hostname = window.location.hostname.toLowerCase();

  if (hostname.includes('cashadvance.ng')) {
    return hotjarIds.cashadvance;
  }
  if (hostname.includes('loan.kwikpaycredit.com')) {
    return hotjarIds.kwikpay;
  }

  return process.env.REACT_APP_HOTJAR_HJID
    ? process.env.REACT_APP_HOTJAR_HJID
    : hotjarIds.default;
};

const config = {
  web: {
    port: process.env.WEB_PORT || 80,
    app_env: process.env.REACT_APP_ENV,
    node_env: process.env.NODE_ENV,
    mode: process.env.REACT_APP_MODE_ENV,
    web_env: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    version: process.env.REACT_APP_IMAGE_TAG || 'latest',
  },
  ignite: {
    base_url: getIgniteUrl(),
    ws_url: process.env.REACT_APP_IGNITE_WEBSOCKET_URL,
    api_url: `${getIgniteUrl()}graphql`,
  },
  client: {
    interest_rate: process.env.REACT_APP_CLIENT_INTEREST_RATE,
    facebook_app_id: process.env.REACT_APP_CLIENT_FACEBOOK_APP_ID,
    minimum_loan_duration: process.env.REACT_APP_CLIENT_MINIMUM_LOAN_DURATION,
    requirements_popup: process.env.REACT_APP_CLIENT_REQUIREMENT_POPUP,
  },
  payStack: {
    public_key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  },
  hotjar: {
    hjid: getHotjarId(),
    hjsv: process.env.REACT_APP_HOTJAR_HJSV || 6,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  okra: {
    findAccount:
      process.env.REACT_APP_ENV !== 'production' ||
      process.env.REACT_APP_ENV !== 'live',
  },
};

module.exports = config;
