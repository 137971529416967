import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { handleGraphQLErrors } from '../../lib/utils';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { notify } from 'react-notify-toast';
import './_RecovaMandateBanner.scss';
import { duration, status } from '../../lib/constants';

const RESEND_MANDATE_SMS = gql`
  mutation ResendMandateSmsMutation($debitMandateMetaId: ID!) {
    resendConsentSMS(input: { debitMandateMetaId: $debitMandateMetaId }) {
      success
    }
  }
`;

const RecovaMandateBanner = ({ debitMandate }) => {
  const [resendConsentSMS, { loading }] = useMutation(RESEND_MANDATE_SMS, {
    onError(error) {
      notify.show(
        handleGraphQLErrors(error) ??
          'There was an error resending consent SMS',
        status.ERROR,
        duration.LONG,
      );
    },

    onCompleted() {
      notify.show(
        'Consent SMS has been sent to your phone.',
        status.SUCCESS,
        duration.LONG,
      );
    },
  });

  const resentMandateSms = () => {
    resendConsentSMS({
      variables: { debitMandateMetaId: debitMandate?.id },
    });
  };

  return (
    <section className="recova-banner">
      {loading ? <Loader /> : null}

      <Alert classes="error">
        <span>
          Please activate your bank's direct debit before the loan is disbursed.
        </span>
        <Button
          classes="right secondary"
          button_text="Resend Mandate SMS"
          onClick={resentMandateSms}
        />
      </Alert>
    </section>
  );
};
export default RecovaMandateBanner;
