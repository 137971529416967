import React from 'react';
import * as S from './styled';
import { useEffect } from 'react';
import { CircleCheckBig } from 'lucide-react';
import { useSignupContext } from '../../../hooks';
import { DataCard } from '../../../components';

const MinimumRequirements = ({ history }) => {
  const {
    updateContextState,
    clientInfo: { useRemita, clientName, kycConfiguration },
  } = useSignupContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Wrapper>
      <DataCard>
        <S.Title>Minimum Requirement</S.Title>
        <S.Subtitle>
          You will need the following in order to successfully get a loan
        </S.Subtitle>
        {kycConfiguration?.minimumRequirements?.builders[0]?.options?.map(
          requirement => (
            <S.ListWrapper key={requirement?.id}>
              <CircleCheckBig color="#27AE60" />
              <S.List>
                <S.ListItems>{requirement?.option}</S.ListItems>
              </S.List>
            </S.ListWrapper>
          ),
        )}

        <S.Aside>
          {useRemita ? (
            <p>
              By clicking on Continue, I consent to {clientName} obtaining
              information from relevant third parties as may be necessary, on my
              personal details, identification details, employment details,
              financial details, loan details and other related details
              including my Bank Verification Number (Data), to make a decision
              on my loan application. I also consent to the scheduled payments
              and loan amount to be deducted from my salary at source before
              credit to any of my bank accounts. I further consent that any
              outstanding loans shall be recovered automatically from any bank
              accounts linked to my Data, whether or not disclosed in my loan
              application, in the case of default.
            </p>
          ) : (
            <p>
              By clicking on Continue, I consent to {clientName} obtaining
              information from relevant third parties as may be necessary to
              make a decision on my loan application.
            </p>
          )}
        </S.Aside>

        <S.ButtonWrapper>
          <S.Button
            onClick={() => {
              history.push('/v1/sign-up/bvn-verification');
              updateContextState('bvn-verification');
            }}
          >
            Continue
          </S.Button>
        </S.ButtonWrapper>
      </DataCard>
    </S.Wrapper>
  );
};

export default MinimumRequirements;
