import { useImageUploader, useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { useCallback, useEffect, useState } from 'react';
import { pages } from '../../../lib/constants';
import { useHistory } from 'react-router';
import { handleCustomFormKyc } from '../../../lib/customFormNavigation';

const useWorkIdUploadForm = ({ customForm, handleNext }) => {
  const {
    loanApplicationState,
    applicationLoading,
    applicationNumber,
    applicationId,
    createApplicationTrace,
    stepCategory,
    clientInfo: {
      requiresSelfieUpload,
      requiresReferee,
      requiresGovernmentId,
      requireWorkEmail,
    },
    goToNextPage,
    user,
    goToPreviousPage,
    setCompletedStep,
  } = useLoanApplicationContext();
  const {
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
  } = useImageUploader();
  const [workIdDetails, setWorkIdDetails] = useState({});
  const history = useHistory();
  const type = 'WORKID';

  const handleUpload = useCallback(async () => {
    logEvent(stepCategory, 'Upload Work Id');

    const { firstName, lastName, id } = user;
    const fileName = `work_id_${firstName}_${lastName}_${id}`;
    uploadImage(workIdDetails.fileDetails, fileName, type);
  }, [workIdDetails, user]);

  const handlePreview = e => {
    handleImagePreview(e, setWorkIdDetails);
  };

  const pageRenderConditions = {
    requiresReferee,
    requiresSelfieUpload,
    requireWorkEmail,
    requiresGovernmentId,
  };

  useEffect(() => {
    if (data) {
      const {
        uploadImageAndSaveToUserMetaData: { status },
      } = data;

      if (status) {
        setCompletedStep('workId');

        if (customForm) {
          handleCustomFormKyc({
            pageRenderConditions,
            applicationId,
            handleNext,
            history,
            applicationNumber,
            currentStepCompleted: status,
          });
        } else {
          goToNextPage();
        }
      }
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.uploadWorkId,
      'Navigated to Work ID Upload Screen',
    );
  }, []);
  return {
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    stepCategory,
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
    type,
    workIdDetails,
    handleUpload,
    handlePreview,
    goToPreviousPage,
  };
};

export default useWorkIdUploadForm;
