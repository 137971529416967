import { useQuery } from '@apollo/client';
import React from 'react';
import { Loader } from '../../../components';
import { getClientId } from '../../../hooks';
import { GET_CUSTOMER_REMITA_STATUS } from './queries';

const CustomerRemitaStatus = ({ customerPhoneNumber, children }) => {
  let props = {
    hasRemitaData: false,
  };

  const clientId = getClientId();

  const { data, loading } = useQuery(GET_CUSTOMER_REMITA_STATUS, {
    skip: true,
    variables: {
      customerPhoneNumber,
      clientId,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return children(props);
  }

  const { customerRemitaStatus } = data;

  const {
    bankId,
    companyName,
    customerName,
    accountNumber,
    hasRemitaData,
    salaryPaymentDetails,
  } = customerRemitaStatus;

  props = {
    bankId,
    companyName,
    customerName,
    accountNumber,
    hasRemitaData,
    salaryPaymentDetails,
  };

  return children(props);
};

export default CustomerRemitaStatus;
