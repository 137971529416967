const graphQlRequest = require('../lib/requestClient');

export default class BankService {
  static getBanks() {
    const query = `
            query {
                getBanks {
                    id
                    name
                    code
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query })
      .catch(() => {});
  }

  static addAccountBank(bankId, accountName, accountNumber) {
    const query = `
            mutation ($bankId: ID!, $accountName: String!, $accountNumber: String!) {
                addAccountBank(input: {bankId: $bankId, accountName: $accountName, accountNumber: $accountNumber}) {
                    id
                    status
                    isDefault
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { bankId, accountName, accountNumber },
      })
      .catch(() => {});
  }

  static initiateBankStatementReq(applicationId, accountBankId, isRetried) {
    const query = `
            mutation($applicationId: ID!, $accountBankId: ID!, $isRetried: Boolean) {
              initiateBankStatementRequest(input: { applicationId: $applicationId, accountBankId: $accountBankId, isRetried: $isRetried}) {
                success
              }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { applicationId, accountBankId, isRetried },
      })
      .catch(() => {});
  }

  static completeBankStatementReq(
    applicationId,
    ticketNum,
    password,
    skipStep,
  ) {
    const query = `
            mutation($applicationId: ID!, $ticketNum: String, $password: String, $skipStep: Boolean) {
                completeBankStatementRequest(input: {applicationId: $applicationId, ticketNum: $ticketNum, password: $password, skipStep: $skipStep}){
                    success
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { applicationId, ticketNum, password, skipStep },
      })
      .catch(() => {});
  }

  static completeExternalBankStatementRequest(
    applicationId,
    ticketNum,
    password,
    skipStep,
  ) {
    const query = `
            mutation($applicationId: ID!, $ticketNum: String!, $password: String!, $skipStep: Boolean!) {
                completeExternalBankStatementRequest(input: {applicationId: $applicationId, ticketNum: $ticketNum, password: $password, skipStep: $skipStep}){
                    success
                    application {
                        requiredSteps
                        completedSteps
                    }
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { applicationId, ticketNum, password, skipStep },
      })
      .catch(() => {});
  }

  static setApplicationBankAccount(
    applicationId,
    accountBankId,
    skippedAutomaticBankStatement,
  ) {
    const query = `
            mutation($applicationId: ID!, $accountBankId: ID!, $skippedAutomaticBankStatement: BankStatementSkipMetaEnum) {
              setApplicationBankAccount(input: { applicationId: $applicationId, accountBankId: $accountBankId, skippedAutomaticBankStatement: $skippedAutomaticBankStatement }) {
                success
                application{
                    id
                    amount
                    taxAmount
                    baseAmount
                    chargesAmount
                    fullAmount
                    loanDuration
                    dateOfRepayment
                    requiredSteps
                    completedSteps
                }
              }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: {
          applicationId,
          accountBankId,
          skippedAutomaticBankStatement,
        },
      })
      .catch(() => {});
  }

  static connectBankToOkra(
    accountBankId,
    recordId,
    customerId,
    directDebitAuthorised,
    okraAccountId,
  ) {
    const query = `
            mutation connectBankToOkra($accountBankId: ID!, $recordId: String!, $customerId: String!, $directDebitAuthorised: Boolean, $okraAccountId: String) {
              connectBankToOkra(input: {accountBankId: $accountBankId, recordId: $recordId, customerId: $customerId, directDebitAuthorised: $directDebitAuthorised, okraAccountId: $okraAccountId}){
                    id
                    bank {
                      name
                      id
                      code
                      okraSlug
                    }
                    accountName
                    accountNumber
                    status
                    isDefault
                    okraRecord{
                      id
                    }
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: {
          accountBankId,
          recordId,
          customerId,
          okraAccountId,
          directDebitAuthorised,
        },
      })
      .catch(() => {});
  }

  static getCustomerOnepipeAccount() {
    const query = `
      query getCustomerOnePipeAccount{
        getCustomerOnePipeAccount {
          id
          accountBanks {
            id
            accountBankId
            bankCode
            bankName
            maskedAccountNumber
          }
          accountCards {
            id
            identifier
            maskedPan
            requireCvv
            requireExpiry
            requirePin
            bankName
            brand
          }
        }
      }
        `;

    return graphQlRequest()
      .post('graphql', { query })
      .catch(() => {});
  }

  static setDefaultAccountBank(accountId, accountBankId) {
    const query = `
      mutation setDefaultAccountBank($accountId: ID!, $accountBankId: ID!){
        setDefaultAccountBank(input: {accountId: $accountId, accountBankId: $accountBankId}) {
          isDefault
        }
      }
    `;
    return graphQlRequest()
      .post('graphql', { query, variables: { accountId, accountBankId } })
      .catch(() => {});
  }
}
