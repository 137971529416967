import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';

import { UPDATE_USER_EMAIL_MUTATION } from './queries';
import { useFormInput, useFlashMessage } from '../../Form';
import { UserService } from '../../../services';
import authService from '../../../services/authService';
import { validateEmail } from '../../../lib/utils';

const UpdateUserEmailProvider = ({ children }) => {
  const [setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const triggerEmailConfirmation = async () => {
    setLoading(true);

    const response = await authService.triggerEmailConfirmation();
    const error = 'Unable to send verification email.';
    setLoading(false);

    if (response == null || typeof response === 'undefined') {
      flashError(error);
      return;
    }

    const { ok } = response.data.data.triggerEmailConfirmation;

    if (!ok) {
      flashError(error);
      return;
    }

    if (ok) {
      flashSuccess('A verification link has been sent to your email.');
      setLoading(true);
      setTimeout(function () {
        handleShowPopup();
        window.location.reload();
      }, 1200);
    }
  };

  const inputs = {
    email: useFormInput(),
  };

  let id;

  const [errorMessage, flashError, setErrorMessage] = useFlashMessage();
  const [successMessage, flashSuccess] = useFlashMessage();

  const changeUserEmail = async (e, runMutation) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');

    const {
      data: {
        data: {
          viewer: { me },
        },
      },
    } = await UserService.viewer();
    id = me.id;

    if (id && validateEmail(inputs.email.value)) {
      setLoading(true);
      runMutation({
        variables: {
          input: {
            email: inputs.email.value,
            userId: id,
          },
        },
      });
    } else {
      flashError('Please enter a valid email address');
    }
  };

  return (
    <Mutation
      mutation={UPDATE_USER_EMAIL_MUTATION}
      onCompleted={({ updateUserEmail: { ok } }) => {
        if (ok) {
          setLoading(true);
          flashSuccess('Your email has been successfull updated');
          triggerEmailConfirmation();
        }
      }}
      onError={error => {
        if (error.graphQLErrors.length === 0)
          flashError('Something Went Wrong: Try again later');
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: e => changeUserEmail(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs,
          handleShowPopup,
          showPopup,
          id,
        })
      }
    </Mutation>
  );
};

export default UpdateUserEmailProvider;
