import styled from 'styled-components';
import { Title as Tit } from '../../SignUp/Signup/styled';
export { Wrapper, NextButton } from '../../SignUp/Signup/styled';

export const Title = styled(Tit)`
  margin-bottom: 2rem;
`;

export const FailureIcon = styled.div`
  display: flex;
  justify-content: center;
`;
