import { Form, Formik, useFormikContext } from 'formik';
import React, { Fragment, useEffect } from 'react';
import * as S from '../../../../containers/SignUp/CreateNewAccount/styled';
import { Alert, DataCard } from '../../../../components';
import { NumberInput, Select } from '../../../../components/FormikElements';
import { getClientId } from '../../../../hooks';
import {
  useConfirmBvnOtp,
  useGetOtpChannels,
  useSendBvnOtpToChannel,
} from './useIdentityPassBvnVerification';
import { logEvent } from '../../../../lib/GAHelper';
import { trimGraphQLErrors } from '../../../../lib/utils';
import { useUpdateUserData } from '../../../../hooks/useUpdateUserData';
import { notify } from 'react-notify-toast';
import { duration, status } from '../../../../lib/constants';

export const IdentityPassBvnVerification = ({
  setVerifiedUserData,
  setVerificationFormState,
  setUserHasVerifiedBvn,
  updateBvnVerificationState,
  setUpdateBvnVerificationState,
}) => {
  const clientId = getClientId();
  const {
    getBvnOtpChannelsIsLoading,
    bvnOtpChannelsError,
    bvnOtpChannels,
    getBvnOtpChannels,
  } = useGetOtpChannels();
  const {
    sendOtpToChannelIsLoading,
    sendOtpToChannelError,
    sendBvnOtpToChannelData,
    sendOtpToChannel,
  } = useSendBvnOtpToChannel();
  const {
    confirmOtp,
    confirmOtpChannelsIsLoading,
    confirmOtpError,
    bvnDetails,
  } = useConfirmBvnOtp();
  const {
    updateUserDataResponse,
    updateUserData,
    updateUserDataIsLoading,
    updateUserDataError,
  } = useUpdateUserData();

  useEffect(() => {
    if (bvnDetails) {
      if (updateBvnVerificationState?.isUpdateBvnData) {
        updateUserData({
          number: updateBvnVerificationState?.bvn,
          validateBvn: true,
        });
      } else {
        setUserHasVerifiedBvn(true);
        setVerifiedUserData(bvnDetails);
      }
    }
  }, [bvnDetails]);

  useEffect(() => {
    if (updateUserDataResponse?.success) {
      setUpdateBvnVerificationState(prev => ({ ...prev, openPopUp: false }));
      notify.show('BVN updated successfully', status.SUCCESS, duration.SHORT);
    }
  }, [updateUserDataResponse]);

  return (
    <S.Wrapper>
      <DataCard
        loading={
          getBvnOtpChannelsIsLoading ||
          confirmOtpChannelsIsLoading ||
          sendOtpToChannelIsLoading ||
          updateUserDataIsLoading
        }
      >
        <S.Title>
          {updateBvnVerificationState?.isUpdateBvnData
            ? 'Verifying BVN'
            : 'Create Account'}
        </S.Title>

        {(bvnOtpChannelsError ||
          sendOtpToChannelError ||
          confirmOtpError ||
          updateUserDataError) && (
          <Alert classes="error">
            {trimGraphQLErrors(
              bvnOtpChannelsError
                ? bvnOtpChannelsError
                : 'There was an error processing your request.',
            ) ||
              trimGraphQLErrors(
                sendOtpToChannelError
                  ? sendOtpToChannelError
                  : 'There was an error processing your request.',
              ) ||
              trimGraphQLErrors(
                confirmOtpError
                  ? confirmOtpError
                  : 'There was an error processing your request.',
              ) ||
              trimGraphQLErrors(
                updateUserDataError
                  ? updateUserDataError
                  : 'There was an error processing your request.',
              )}
          </Alert>
        )}

        <Formik
          initialValues={{
            bvn: '',
            channel: '',
            bvnOTP: '',
          }}
          onSubmit={values => {
            logEvent('Signup', 'confirm BVN otp');
            !updateBvnVerificationState?.isUpdateBvnData &&
              setVerificationFormState(prevState => ({
                ...prevState,
                bvnOtp: values?.bvnOTP,
              }));
            confirmOtp({
              otp: values?.bvnOTP,
              verificationId: bvnOtpChannels?.verificationId,
              clientId,
            });
          }}
        >
          {({ values, setFieldValue, handleBlur }) => (
            <>
              <Form>
                {(values, setFieldValue, handleBlur)}
                <S.PDisclosure className="active">
                  <Fragment>
                    <NumberInput
                      onChange={e => {
                        updateBvnVerificationState?.bvn &&
                          setUpdateBvnVerificationState(prev => ({
                            ...prev,
                            bvn: e.target.value,
                          }));
                        setFieldValue('bvn', e.target.value);
                      }}
                      name="bvn"
                      value={values.bvn}
                      placeholder="Kindly enter your BVN"
                      maxLength="11"
                      decimalSeparator={false}
                      disabled={updateBvnVerificationState?.isUpdateBvnData}
                    />

                    {!bvnOtpChannelsError && bvnOtpChannels && (
                      <div>
                        <Select
                          name="channel"
                          label={bvnOtpChannels?.detail}
                          onChange={e => {
                            setFieldValue('channel', e.target.value);
                          }}
                          value={values.channel}
                        >
                          <option value="">Select OTP Channel</option>
                          {bvnOtpChannels?.contacts?.map((contact, index) => (
                            <option key={index} value={contact}>
                              {contact}
                            </option>
                          ))}
                        </Select>
                      </div>
                    )}

                    {!bvnOtpChannelsError && sendBvnOtpToChannelData && (
                      <NumberInput
                        onChange={e => {
                          setFieldValue('bvnOTP', e.target.value);
                        }}
                        name="bvnOTP"
                        label={sendBvnOtpToChannelData?.detail}
                        value={values.bvnOTP}
                        placeholder="Kindly enter your OTP"
                        maxLength="6"
                        decimalSeparator={false}
                      />
                    )}

                    {updateBvnVerificationState?.isUpdateBvnData ? (
                      <S.ButtonsContainer>
                        <S.OutlineButton
                          button_text="Verify BVN"
                          type="submit"
                          value="Cancel"
                          onClick={() => {
                            setUpdateBvnVerificationState(prev => ({
                              ...prev,
                              openPopUp: !prev.openPopUp,
                            }));
                          }}
                        />
                        <S.Button
                          disabled={
                            !values.bvn ||
                            !values.channel ||
                            !(values.bvnOTP.length === 6)
                          }
                          button_text="Verify BVN"
                          type="submit"
                          value="Verify BVN"
                        />
                      </S.ButtonsContainer>
                    ) : (
                      <S.ButtonsContainer>
                        <S.Button
                          disabled={
                            !values.bvn ||
                            !values.channel ||
                            !(values.bvnOTP.length === 6)
                          }
                          button_text="Verify BVN"
                          type="submit"
                          value="Verify BVN"
                        />
                      </S.ButtonsContainer>
                    )}
                  </Fragment>
                </S.PDisclosure>
              </Form>
              <IdentityPassBvnValidation
                getBvnOtpChannels={getBvnOtpChannels}
                bvnOtpChannels={bvnOtpChannels}
                sendOtpToChannel={sendOtpToChannel}
                bvnDetails={bvnDetails}
                setVerificationFormState={setVerificationFormState}
                updateBvnVerificationState={updateBvnVerificationState}
              />
            </>
          )}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

const IdentityPassBvnValidation = ({
  getBvnOtpChannels,
  bvnOtpChannels,
  sendOtpToChannel,
  setVerificationFormState,
  updateBvnVerificationState,
}) => {
  const clientId = getClientId();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    updateBvnVerificationState?.bvn &&
      setFieldValue('bvn', updateBvnVerificationState?.bvn);
  }, []);

  useEffect(() => {
    const { bvn } = values;

    if (bvn?.length === 11 || updateBvnVerificationState?.bvn === 11) {
      logEvent('Signup', 'Check BVN Status');
      !updateBvnVerificationState?.isUpdateBvnData &&
        setVerificationFormState(prevState => ({ ...prevState, bvn }));
      getBvnOtpChannels({
        bvn: values?.bvn,
        clientId,
      });
    }
  }, [values.bvn]);

  useEffect(() => {
    const { channel } = values;

    if (channel) {
      logEvent('Signup', 'Check BVN Status');
      !updateBvnVerificationState?.isUpdateBvnData &&
        setVerificationFormState(prevState => ({
          ...prevState,
          otpChannel: channel,
        }));
      bvnOtpChannels &&
        sendOtpToChannel({
          channel: channel,
          verificationId: bvnOtpChannels?.verificationId,
          clientId,
        });
      values.bvnOTP = '';
    }
  }, [values.channel]);

  return null;
};
