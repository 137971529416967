import React from 'react';
import { Query } from '@apollo/client/react/components';

import { GET_BANKS_QUERY } from './queries';
import { Loader } from '../../../components';

const GetBanksProvider = ({ children }) => {
  return (
    <Query query={GET_BANKS_QUERY}>
      {({ data, loading, error }) => {
        const props = {
          error,
          loading,
          banks: [],
        };

        if (loading) return <Loader />;

        if (error) {
          return children(props);
        }

        if (data && !loading && !error) {
          const { getBanks } = data;

          props.banks = getBanks;
        }

        return children(props);
      }}
    </Query>
  );
};

export default GetBanksProvider;
