const theme = {
  primaryColor: '#4C5C6D',
  secondaryColor: '#A5ADB6',

  colors: {
    border_color: '#4C5C6D',
    document_background: 'rgba(157, 216, 88, 0.12)',
    disabled: '#D3D4D8',
    facebook_blue: '#3B5998',
    color_white: '#FFFFFF',
    color_black: '#000000',
    color_grey: '#A4A59A',
    color_green: '#219653',
    color_red: '#B35151',
    color_yellow: '#CC9E58',
    color_background_grey: '#E5E5E5',
    color_status_success: '#6FCF97',
    color_status_rejected: '#EB5757',
    color_system_fail: '#AE4036',
    color_system_success: '#219653',
    color_system_pending: '#2D9CDB',
    color_primary: '#FBFDFF',
    tertiary_color: '#002C5B',
    text_color_primary: '#4C5C6D',
    text_color_secondary: '#A5ADB6',
    text_color_tertiary: '#002C5B',
  },

  fonts: {
    font_regular: 400,
    font_semibold: 600,
    font_bold: 800,
  },

  font_family: {
    default_font:
      'Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol',
  },

  break_points: {
    mobile_width: '767px',
    tablet_width: '979px',
    medium_width: '1199px',
  },

  to_rem: pixelValueWithUnit => {
    const baseValue = 16;
    const pixelValueDigit = pixelValueWithUnit.split('px')[0];
    const remValue = pixelValueDigit / baseValue;
    return remValue + 'rem';
  },
};

export default theme;
