import { useMutation } from '@apollo/client';
import { removeCommas } from '../../../lib/utils';
import { NOTIFY_OFFLINE_REPAYMENT } from './queries';

const NotifyOfflineRepaymentProvider = ({ children }) => {
  const [mutate] = useMutation(NOTIFY_OFFLINE_REPAYMENT, {
    onError(...error) {
      return error;
    },
  });

  const notifyOfflineRepayment = data => {
    let payload;

    const {
      accountName,
      amount,
      bankBranch,
      bankId,
      chequeNo,
      portfolioId,
      service,
      supportingDocuments,
      tellerNo,
    } = data;

    let amountPaid = parseFloat(removeCommas(amount));

    switch (service) {
      default:
        payload = {
          portfolioId,
          service,
          bankId,
          bankBranch,
          tellerNo,
          amount: amountPaid,
          supportingDocuments,
        };
        break;
      case 'Cheque':
        payload = {
          portfolioId,
          service,
          bankId,
          chequeNo,
          amount: amountPaid,
          supportingDocuments,
        };
        break;
      case 'Transfer':
        payload = {
          portfolioId,
          service,
          bankId,
          accountName,
          amount: amountPaid,
          supportingDocuments,
        };
    }

    return mutate({
      variables: payload,
    });
  };

  const props = {
    notifyOfflineRepayment,
  };

  return children(props);
};

export default NotifyOfflineRepaymentProvider;
