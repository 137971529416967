import React from 'react';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import {
  Input,
  SubmitButton,
  Select,
} from '../../../components/FormikElements';
import WorkIdPlaceholder from '../../../static/images/workid-placeholder.jpg';
import { GovernmentIdCardSchema } from '../../../lib/validationSchemas';
import useGovernmentIdCardForm from './useGovernmentIdCardForm';

const GovernmentIDCardScreen = ({ customForm, handleNext, formIndex }) => {
  const {
    applicationLoading,
    uploadImageLoading,
    governmentIdDetails,
    uploadImageError,
    handlePreview,
    handleUpload,
  } = useGovernmentIdCardForm({
    customForm,
    handleNext,
    formIndex,
  });

  return (
    <S.Wrapper>
      <DataCard loading={applicationLoading || uploadImageLoading}>
        <S.Title>
          Upload a picture of your Government-issued ID e.g. International
          Passport, Drivers License, National ID
        </S.Title>
        {uploadImageError && <Alert classes="error">{uploadImageError}</Alert>}
        <Formik
          initialValues={{
            documentName: '',
            documentNo: '',
          }}
          validationSchema={GovernmentIdCardSchema}
          onSubmit={values => {
            handleUpload(values);
          }}
        >
          <Form>
            <S.ImageUpload>
              <input
                name="governmentId"
                type="file"
                accept="image/*, .heic"
                onChange={handlePreview}
              />
              <section>
                <img
                  src={governmentIdDetails.url || WorkIdPlaceholder}
                  alt="governmentId"
                />
              </section>
            </S.ImageUpload>
            <Select name="documentName">
              <option value="" disabled selected>
                --Select ID Card--
              </option>
              <option value="driver_license"> Driver's Licence</option>
              <option value="voter_card"> Voter's Card</option>
              <option value="international_passport">
                International Passport
              </option>
              <option value="national_id"> National ID Card</option>
            </Select>
            <Input name="documentNo" placeholder="ID Number" />
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default GovernmentIDCardScreen;
