import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import { RESET_PASSWORD_MUTATION } from './queries';
import { useFormInput, useFlashMessage } from '../../Form';

const ResetPasswordProvider = ({ children, history, match: { params } }) => {
  const { resetToken } = params;

  const inputs = {
    password: useFormInput(),
    confirmPassword: useFormInput(),
    resetToken: useFormInput(),
  };

  const [errorMessage, flashError, setErrorMessage] = useFlashMessage();
  const [successMessage] = useFlashMessage();

  const handleResetPwd = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');
    inputs.password.setErrorMessage('');

    if (inputs.password.value !== inputs.confirmPassword.value) {
      flashError("Password don't match");
    } else {
      if (inputs.password.value && inputs.confirmPassword.value) {
        runMutation({
          variables: {
            input: {
              password: inputs.password.value,
              resetToken,
            },
          },
        });
      } else {
        if (!inputs.password.value || !inputs.confirmPassword.value) {
          inputs.password.setErrorMessage('Please fill all required fields');
          inputs.confirmPassword.setErrorMessage(
            'Please fill all required fields',
          );
        }
      }
    }
  };

  return (
    <Mutation
      mutation={RESET_PASSWORD_MUTATION}
      onCompleted={({ resetPassword: { ok } }) => {
        if (ok) {
          history.push('/password-reset-complete');
        }
      }}
      onError={error => {
        if (error.message) flashError(error.message);
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: e => handleResetPwd(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs,
        })
      }
    </Mutation>
  );
};

export default withRouter(ResetPasswordProvider);
