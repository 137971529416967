/* eslint-disable no-unused-vars */
const graphQlRequest = require('../lib/requestClient');

export default class AuthService {
  /**
   *
   * @param email - Email Address of the user
   * @param password - Password of the user
   * @param clientId = ID of the loan provider
   * @returns {*}
   */

  static login(email, password, clientId) {
    const loginMutation = `
            mutation($email: String!, $password: String!, $clientId: ID!) {
                login(input: {email: $email, password: $password, clientId: $clientId}) {
                    token
                    user {
                      id
                      status
                    }
                }
            }
        `;

    const data = {
      query: loginMutation,
      variables: {
        email,
        password,
        clientId,
      },
    };

    return graphQlRequest()
      .post('graphql', data)
      .catch(err => {});
  }

  /**
   *
   * @param bvn
   * @param clientId = ID of the loan provider
   * @returns {*}
   */

  static customerBvnStatus(bvn, clientId) {
    const query = `
            query($bvn: String!, $clientId: ID!) {
                customerBvnStatus(input: {bvn: $bvn, clientId: $clientId}) {
                    hasCustomerAccount
                    firstName
                    lastName
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { bvn, clientId } })
      .catch(err => {});
  }

  /**
   *
   * @param firstName
   * @param lastName
   * @param email
   * @param phone
   * @param bvn
   * @param password
   * @param clientId
   * @returns {*}
   */

  static createCustomer(
    bvn,
    firstName,
    lastName,
    email,
    phone,
    password,
    title,
    maritalStatus,
    clientId,
    gender,
  ) {
    const createCustomerMutation = `
            mutation($bvn: String!, $firstName: String!, $lastName: String!, $email: String!, $phone: String!, $password: String!, $title: UserTitle, $maritalStatus: MaritalStatus, $clientId: ID!, $gender: Gender) {
                createCustomer( input: {bvn: $bvn, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, password: $password, title: $title, maritalStatus: $maritalStatus, clientId: $clientId, gender: $gender}) {
                    token
                    account {
                        id
                    }
                    user {
                        id
                        bvnStatus {
                            dateOfBirth
                            isVerified
                            isMatchingPhone
                        }
                    }
                }
            }
        `;

    const data = {
      query: createCustomerMutation,
      variables: {
        bvn,
        firstName,
        lastName,
        email,
        phone,
        password,
        title,
        maritalStatus,
        clientId,
        gender,
      },
    };

    return graphQlRequest()
      .post('graphql', data)
      .catch(err => {});
  }

  /**
   *
   * @returns {*}
   */

  static triggerEmailConfirmation() {
    const query = `
            mutation {
                triggerEmailConfirmation{
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query })
      .catch(err => {});
  }

  /**
   *
   * @returns {*}
   */

  static updatePhoneNumber(userId, phone) {
    const query = `
            mutation ($userId: ID!, $phone: String!) {
                updatePhoneNumber(input:{userId: $userId, phone: $phone}){
                    ok
                    record {
                        isPhoneConfirmed
                        phone
                    }
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { userId, phone } })
      .catch(err => {});
  }

  /**
   *
   * @param code
   * @param userId
   * @returns {*}
   */

  static confirmPhone(code, userId) {
    const query = `
            mutation($code: String!, $userId: ID!) {
                confirmPhone(input: {code: $code, userId: $userId}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { code, userId } })
      .catch(err => {});
  }

  /**
   *
   * @param userId
   * @returns {*}
   */

  static triggerPhoneConfirmation(userId) {
    const query = `
            mutation($userId: ID!) {
                triggerPhoneConfirmation(input: { userId: $userId }) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { userId } })
      .catch(() => {});
  }

  /**
   *
   * @returns {*}
   */

  static triggerWorkEmailConfirmation(clientId, workEmail) {
    const query = `
            mutation($clientId:ID!,$workEmail: String!) {
                triggerWorkEmailConfirmation(input: {clientId:$clientId,workEmail: $workEmail}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { clientId, workEmail } })
      .catch(() => {});
  }

  /**
   *
   * @param emailConfirmToken
   * @returns {*}
   */

  static confirmEmail(emailConfirmToken) {
    const query = `
            mutation($emailConfirmToken: String!) {
                confirmEmail(input: {emailConfirmToken: $emailConfirmToken}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { emailConfirmToken } })
      .catch(() => {});
  }

  /**
   *
   * @param code
   * @param workEmail
   * @returns {*}
   */

  static confirmWorkEmail(code, workEmail) {
    const query = `
            mutation($code: String!, $workEmail: String!) {
                confirmWorkEmail(input: {code: $code, workEmail: $workEmail}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { code, workEmail } })
      .catch(() => {});
  }

  /**
   *
   * @param bvnPhoneDigits
   * @param userId
   * @returns {*}
   */

  static triggerBvnPhoneConfirmation(bvnPhoneDigits, userId) {
    const query = `
            mutation($userId: ID!, $bvnPhoneDigits: String!) {
                triggerBvnPhoneConfirmation(input: {userId: $userId, bvnPhoneDigits: $bvnPhoneDigits}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { userId, bvnPhoneDigits } })
      .catch(() => {});
  }

  /**
   *
   * @param address
   * @param state
   * @param dateMovedIn
   * @param employmentStatus
   * @param employerName
   * @param workAddress
   * @param jobRole
   * @param workEmail
   * @param workStartDate
   * @param netIncome
   * @param refereeName
   * @param refereePhone
   * @param refereeWorkPlace
   * @param refereeEmail
   * @param selfie
   * @param workId
   * @param facebookProfileUrl
   * @returns {*}
   */

  static updateUserData(
    address,
    state,
    dateMovedIn,
    employmentStatus,
    employerName,
    workAddress,
    jobRole,
    workEmail,
    workStartDate,
    netIncome,
    workId,
    selfie,
    refereeName,
    refereePhone,
    refereeWorkPlace,
    refereeEmail,
    facebookProfileUrl,
  ) {
    const query = `
            mutation($address: String, $state: String, $dateMovedIn: DateTime, $employmentStatus: String, $employerName: String, $workAddress: String, $jobRole: String, $workEmail: String, $workStartDate: DateTime, $netIncome: String, $workId: String, $selfie: String, $refereeName: String, $refereePhone: String, $refereeWorkPlace: String, $refereeEmail: String, $facebookProfileUrl: String) {
                updateUserData( input: {address: $address, state: $state, dateMovedIn: $dateMovedIn, employmentStatus: $employmentStatus, employerName: $employerName, workAddress: $workAddress, jobRole: $jobRole, workEmail: $workEmail, workStartDate: $workStartDate, netIncome: $netIncome, workId: $workId, selfie: $selfie, refereeName: $refereeName, refereePhone: $refereePhone, refereeWorkPlace: $refereeWorkPlace, refereeEmail: $refereeEmail, facebookProfileUrl: $facebookProfileUrl}) {
                    success
                }
            }
        `;

    const data = {
      query,
      variables: {
        address,
        state,
        dateMovedIn,
        employmentStatus,
        employerName,
        workAddress,
        jobRole,
        workEmail,
        workStartDate,
        netIncome,
        selfie,
        workId,
        refereeName,
        refereePhone,
        refereeWorkPlace,
        refereeEmail,
        facebookProfileUrl,
      },
    };

    return graphQlRequest()
      .post('graphql', data)
      .catch(() => {});
  }

  /**
   *
   * @param accessToken
   * @returns {*}
   */
  static authenticateCustomerFacebook(accessToken) {
    const query = `
            mutation($accessToken: String!) {
                authenticateCustomerFacebook(input: {accessToken: $accessToken}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { accessToken } })
      .catch(() => {});
  }

  /**
   *
   * @param bankId
   * @param accountNumber
   * @returns {*}
   */
  static resolveAccountNumber(bankId, accountNumber) {
    const query = `
            query($bankId: ID!, $accountNumber: String!) {
                resolveAccountNumber(bankId: $bankId, accountNumber: $accountNumber) {
                    accountName
                    accountNumber
                }
            }
        `;

    const data = {
      query,
      variables: {
        bankId,
        accountNumber,
      },
    };

    return graphQlRequest()
      .post('graphql', data)
      .catch(err => {});
  }

  static viewerQueryClone(token) {
    const query = `
            query ViewerQuery {
                viewer {
                    me {
                        id
                        isBvnVerified
                        isPhoneConfirmed
                        isEmailConfirmed
                        bvnStatus {
                            isVerified
                            isMatchingPhone
                            dateOfBirth
                            phone
                        }
                        userMetadata {
                            id
                            name
                            value
                        }
                    }
                    account {
                        id
                        applications {
                            nodes {
                                id
                            }
                        }
                        bankAccounts {
                            id
                        }
                        cards {
                            id
                        }
                        contextStates {
                          state
                          page
                          context
                        }
                    }
                }
            }
        `;

    return graphQlRequest({ Authorization: `Bearer ${token}` })
      .post('graphql', { query })
      .catch(() => {});
  }

  /**
   *
   * @param image
   * @returns {*}
   */

  static uploadImage(image) {
    const query = `
            mutation($image: Upload!) {
                uploadImage(image: $image) {
                    imageUrl
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { image } })
      .catch(() => {});
  }

  static customerRemitaStatus(customerPhoneNumber, clientId) {
    const query = `
            query customerRemitaStatus ($clientId: ID, $customerPhoneNumber: String) {
                customerRemitaStatus(input: { clientId: $clientId, customerPhoneNumber: $customerPhoneNumber }) {
                    bankId
                    customerId
                    companyName
                    hasRemitaData
                    accountNumber
                    countSalaryCredits
                    salaryPaymentDetails {
                        amount
                        bankCode
                        paymentDate
                        accountNumber
                    }
                    loanHistoryDetails{
                        loanAmount
                        loanProvider
                        repaymentFreq
                        outstandingAmount
                        loanDisbursementDate
                    }
                }
            }
        `;
    return graphQlRequest()
      .post('graphql', { query, variables: { customerPhoneNumber, clientId } })
      .catch(() => {});
  }

  static updateContextState(payload) {
    const query = `
            mutation updateContextState($id: ID!, $state: JSON, $context: ContextType, $page: String) {
                updateContextState(input: { id: $id, state: $state, context: $context, page: $page }) {
                    id
                    state
                    context
                    page
                }
            }`;

    return graphQlRequest()
      .post('graphql', { query, variables: payload })
      .catch(() => {});
  }

  static createContextState(payload) {
    const query = `
            mutation createContextState($state: JSON, $context: ContextType!, $page: String) {
                createContextState(input: { state: $state, context: $context, page: $page }) {
                    id
                    state
                    context
                    page
                }
            }`;

    return graphQlRequest()
      .post('graphql', { query, variables: payload })
      .catch(() => {});
  }

  static getEnum(type) {
    const query = `
            query getEnum($type: Enum!) {
                enumValues(type: $type) {
                    name
            }
        }
        `;

    return graphQlRequest()
      .post('graphql', { query, variables: { type } })
      .catch(() => {});
  }
}
