import React from 'react';
import './_Table.scss';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTable = styled.table`
  button,
  .button {
    border-color: 1px solid ${props => props.theme.primaryColor};
  }
`;

const Table = ({ tableHeaders, children }) => {
  return (
    <StyledTable className={'table'}>
      {tableHeaders === '' || tableHeaders == null ? (
        ''
      ) : (
        <thead>
          <tr>
            {tableHeaders.map(val => (
              <th key={val}>{val}</th>
            ))}
          </tr>
        </thead>
      )}
      {children}
    </StyledTable>
  );
};

Table.propTypes = {
  tableHeaders: PropTypes.array,
};

export default Table;
