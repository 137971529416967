import gql from 'graphql-tag';

export const GET_BANKS_QUERY = gql`
  query {
    getBanks {
      id
      name
      code
    }
  }
`;
