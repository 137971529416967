/* eslint-disable prettier/prettier */
import gql from "graphql-tag";

export const CUSTOMER_BVN_STATUS = gql`
  query (
    $bvn: String!
    $clientId: ID!
    $bvnDOB: DateTime
    $bvnPhoneDigits: String
    $accountId: ID
  ) {
    customerBvnStatus(
      input: {
        accountId: $accountId
        bvn: $bvn
        clientId: $clientId
        bvnDOB: $bvnDOB
        bvnPhoneDigits: $bvnPhoneDigits
      }
    ) {
      hasCustomerAccount
      isVerified
      phone
      firstName
      lastName
    }
  }
`;
