import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import Okra from 'npm-okrajs';

import { useLoanApplicationContext } from '../../../hooks';
import { handleGraphQLErrors } from '../../../lib/utils';
import { pages } from '../../../lib/constants';
import {
  ADD_BANK_ACCOUNT,
  SET_APPLICATION_BANKACCOUNT,
  CONNECT_BANK_TO_OKRA,
} from '../mutations';
import { GET_BANKS, RESOLVE_OKRA_BANK } from '../queries';
import { NEW_LOAN_ROUTES } from '../routes';
import { usePaymentInformation } from '../../providers/Payment/PaymentInformationProvider';
import { PAYMENT_INFO_QUERY } from '../../providers/Payment/queries';
import { APPLICATION_STEPS, formBuilderSubStep } from '../../../lib/applicationSteps';

export const useBanks = (opts = { filtered: true }) => {
  let categoryId;
  if (opts.filtered) {
    categoryId = useLoanApplicationContext().categoryId;
  }

  const { data, loading } = useQuery(GET_BANKS, {
    variables: { loanCategoryId: categoryId },
  });

  const { getBanks: banks = [] } = data || {};

  return {
    banks,
    loading,
  };
};

export const useConnectBankToOkra = ({
  accountBankId,
  okraCustomerId,
  okraRecordId,
  okraAccountId,
}) => {
  const {
    showErrorAlert,
    clientInfo: { okraDirectDebitIsEnabled },
  } = useLoanApplicationContext();

  const [runConnectOkraBankAccount, { loading }] = useMutation(
    CONNECT_BANK_TO_OKRA,
    {
      // refetchQueries: ,
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(errorMessage || 'Error adding your bank account');
      },
    },
  );

  const handleConnectOkraToBank = () => {
    runConnectOkraBankAccount({
      variables: {
        accountBankId,
        okraCustomerId,
        okraRecordId,
        okraAccountId,
        directDebitAuthorised: okraDirectDebitIsEnabled,
      },
    });
  };

  return {
    handleConnectOkraToBank,
    loading,
  };
};

export const useSetApplicationBankAccount = ({
  accountBankId,
  skipAutomaticBankStatement,
  customForm,
  handleNext,
  formIndex,
  customApplicationForm
}) => {
  const {
    showErrorAlert,
    application: applicationData,
    updateContextState,
    createApplicationTrace,
    setCompletedStep,
    clientInfo,
  } = useLoanApplicationContext();

  const { application } = applicationData || {};
  const history = useHistory();

  const [runSetApplicationBank, { loading }] = useMutation(
    SET_APPLICATION_BANKACCOUNT,
    {
      onError(error) {
        updateContextState(NEW_LOAN_ROUTES.bankAccount, {
          setApplicationBankError: {
            message: error.message,
            stack: error.stack,
            error,
          },
        });
        createApplicationTrace(
          pages.bankDetails,
          'Error while setting Application Bank Account',
          true,
          {
            setApplicationBankError: {
              message: error.message,
              stack: error.stack,
              error,
            },
          },
        );
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(
          errorMessage || 'Error linking bank account to application',
        );
      },
    },
  );

  const getPath = path =>
    `/application-custom/${application?.applicationNumber}${path}`;


  useEffect(() => {
   if (customApplicationForm?.subStep) {
      history.push(getPath(formBuilderSubStep?.mbs?.[customApplicationForm?.subStep]));
    }
      createApplicationTrace(pages.bankDetails, 'Navigated to Bank Details Screen');
  }, [application]);

  const handleCustomNext = (path, subStep) => {
    handleNext({
      variables: {
        applicationId: application.id,
        data: { path, subStep },
      },
    });
    history.push(path);
    return;
  };


  const handleSetApplicationBank = (customFormNext = false) => {
    runSetApplicationBank({
      variables: {
        applicationId: application.id,
        accountBankId,
        skipBankStatementProvider: skipAutomaticBankStatement || undefined,
      },
      onCompleted({ setApplicationBankAccount }) {
        if (!setApplicationBankAccount) {
          showErrorAlert(
            'There was an error processing your loan. Please try again later.',
          );
        } else {
          setCompletedStep('bankAccount');
          if (customForm && customFormNext) {
            const { requiredSteps } =
              setApplicationBankAccount.application || {};
            if (
              requiredSteps?.includes(
                APPLICATION_STEPS.completeExternalBankStatementRequest.name,
              ) ||
              requiredSteps?.includes(
                APPLICATION_STEPS.initiateBankStatementRequest.name,
              )
            ) {
              handleCustomNext(getPath(formBuilderSubStep?.mbs[customApplicationForm?.substep] || '/mbs'), 1);
              return;
            }
            if (
              clientInfo?.requiresBankStatementUpload ||
              requiredSteps.includes(APPLICATION_STEPS.uploadBankStatement.name)
            ) {
              handleCustomNext(getPath(formBuilderSubStep?.mbs[customApplicationForm?.substep] || '/upload-bank-statement'), 2);
              return;
            }
            handleNext({
              variables: {
                applicationId: application.id,
                data: { step: formIndex + 1, path: '' },
              },
            });
          }
          if (customForm && !customFormNext) {
            handleCustomNext(getPath(formBuilderSubStep?.mbs[customApplicationForm?.substep] || '/upload-bank-statement'), 2);
          }
        }
      },
    });
  };

  return {
    loading,
    handleSetApplicationBank,
  };
};

export const useOkraWidget = () => {
  const okraDataRef = useRef({});
  const [isOkraWidgetClosed, setOkraWidgetClosed] = useState(false);

  const {
    showErrorAlert,
    clientInfo: { okraWidgetUrl },
    createApplicationTrace,
  } = useLoanApplicationContext();

  const [runResolveOkraBank, { loading, data }] = useLazyQuery(
    RESOLVE_OKRA_BANK,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(
          errorMessage ||
            'There was an error getting your bank details from okra, please fill manually.',
        );
      },
    },
  );

  const okraData = useMemo(() => {
    if (!data?.resolveOkraBank?.id) {
      return;
    }

    const { customer_id, record_id, accounts } = okraDataRef.current;
    const { id, name } = data.resolveOkraBank;

    // TODO: get array of connected bank accounts
    const connectedAccount = accounts?.find(
      account => account?.connected === true,
    );

    return {
      hasData: true,
      okraBankId: id,
      okraBankName: name,
      okraAccountNumber: connectedAccount?.nuban + '',
      okraAccountName: connectedAccount?.okraAccountName,
      okraCustomerId: customer_id,
      okraRecordId: record_id,
      okraAccountId: connectedAccount?.okraAccountId,
    };
  }, [okraDataRef.current, data]);

  const processOkraResponse = useCallback(okra_data => {
    const { bank_id, accounts } = okra_data;
    const connectedAccount = accounts.find(account => account.connected);
    okraDataRef.current = { ...okra_data, connectedAccount };
    runResolveOkraBank({ variables: { okraBankId: bank_id } });
  }, []);

  const handleOkraClose = useCallback(() => {
    setOkraWidgetClosed(true);
  }, []);

  const handleOkraError = useCallback(errObj => {
    // There seem to be an issue with the package `this.Okra` returns undefined so we're passing Okra as param (e) which is a fallback.
    Okra.forceClose(Okra);
    setOkraWidgetClosed(true);
    showErrorAlert(errObj?.msg);
  }, []);

  const initiateOkra = useCallback(() => {
    const short_url = okraWidgetUrl.split('/');
    Okra.buildWithShortUrl({
      short_url: short_url[short_url.length - 1],
      onSuccess: processOkraResponse,
      onClose: handleOkraClose,
      onError: handleOkraError,
    });
    setOkraWidgetClosed(false);
    createApplicationTrace(pages.okra, 'Triggered Okra Widget');
  }, [okraWidgetUrl]);

  return {
    loading,
    isOkraWidgetClosed,
    initiateOkra,
    ...okraData,
  };
};

const useSelectBankAccount = ({ accountId, bankAccounts }) => {
  const {
    clientInfo: { requiresOkraTransactions },
  } = useLoanApplicationContext();

  const selectedBankAccount = useMemo(
    () => bankAccounts.find(({ id }) => id === accountId),
    [accountId, bankAccounts],
  );

  const okraConnected = useMemo(
    () => selectedBankAccount?.id && selectedBankAccount?.okraRecord?.id,
    [selectedBankAccount],
  );
  const okraBank = useMemo(
    () => selectedBankAccount?.id && selectedBankAccount?.bank?.okraSlug,
    [selectedBankAccount],
  );

  const showContinueButton = useMemo(
    () =>
      (!requiresOkraTransactions && selectedBankAccount?.id) ||
      (requiresOkraTransactions && okraConnected),
    [selectedBankAccount],
  );

  const showUploadBankStatementButton = useMemo(
    () =>
      selectedBankAccount?.id &&
      requiresOkraTransactions &&
      (!okraBank || !okraConnected),
    [selectedBankAccount],
  );

  const showTriggerOkraButton = useMemo(
    () => requiresOkraTransactions && !okraConnected && okraBank,
    [selectedBankAccount],
  );

  return {
    selectedBankAccount,
    showContinueButton,
    showUploadBankStatementButton,
    showTriggerOkraButton,
    requiresOkraTransactions,
  };
};

export const useSelectApplicationBank = ({
  customForm,
  handleNext,
  formIndex,
  customApplicationForm
}) => {
  const { banks, loading: banksLoading } = useBanks();

  const {
    data: { bankAccounts },
    loading: paymentInfoLoading,
  } = usePaymentInformation();

  const [selectedBankAccountId, setSelectedBankAccountId] = useState();
  const [selectedBankId, setSelectedBankId] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [skipAutomaticBankStatement, setSkipAutomaticBankStatement] =
    useState(false);
  const [showAddNewBankAccountForm, setShowAddNewBankAccountForm] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleShowAddNewBankAccountForm = () =>
    setShowAddNewBankAccountForm(prevState => !prevState);

  const {
    showErrorAlert,
    clientInfo: { okraDirectDebitIsEnabled },
    createApplicationTrace,
  } = useLoanApplicationContext();

  const handleSelectBankStatement = id => {
    setSelectedBankId(null);
    setAccountNumber(null);
    setSkipAutomaticBankStatement(false);
    setSelectedBankAccountId(id);
  };

  const handleAccountBankSwitch = id => {
    setSelectedBankAccountId(id);
  };

  const {
    selectedBankAccount,
    showContinueButton,
    showTriggerOkraButton,
    showUploadBankStatementButton,
    requiresOkraTransactions,
  } = useSelectBankAccount({
    accountId: selectedBankAccountId,
    bankAccounts,
  });

  const {
    loading: okraLoading,
    isOkraWidgetClosed,
    initiateOkra,
    okraAccountId,
    okraBankId,
    okraAccountNumber,
    okraCustomerId,
    okraRecordId,
  } = useOkraWidget();

  const [accountName, setAccountName] = useState();

  const [runAddBankAccount, { loading: addBankLoading }] = useMutation(
    ADD_BANK_ACCOUNT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(errorMessage || 'Error adding your bank account');
        createApplicationTrace(
          pages.bankDetails,
          'Error adding bank account',
          true,
          {
            errorAddingBankAccount: {
              message: error.message,
              stack: error.stack,
              error,
            },
          },
        );
      },
      onCompleted({ addAccountBank }) {
        if (!addAccountBank?.id) {
          showErrorAlert('Error adding your bank account');
        }
        setShowAddNewBankAccountForm(false);
      },
      refetchQueries: [
        {
          query: PAYMENT_INFO_QUERY,
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const handleAddBankDetails = ({
    inputBankId,
    inputAccountNumber,
    inputAccountName,
  }) => {
    let variables = {
      bankId: inputBankId,
      accountName: inputAccountName,
      accountNumber: inputAccountNumber,
    };

    if (inputAccountNumber === okraAccountNumber) {
      variables = {
        ...variables,
        okraCustomerId,
        okraRecordId,
        okraAccountId,
        directDebitAuthorised: okraDirectDebitIsEnabled,
      };
    }

    runAddBankAccount({
      variables,
    });
  };

  const { handleConnectOkraToBank, loading: connectBankToOkraLoading } =
    useConnectBankToOkra({
      accountBankId: selectedBankAccountId,
      okraAccountId,
      okraCustomerId,
      okraRecordId,
    });

  const {
    handleSetApplicationBank,
    loading: setApplicationBankAccountLoading,
  } = useSetApplicationBankAccount({
    accountBankId: selectedBankAccountId,
    skipAutomaticBankStatement,
    customForm,
    handleNext,
    formIndex,
    customApplicationForm
  });

  const handleContinueButton = () => handleSetApplicationBank(true);

  const handleUploadBankStatementButton = () =>
    setSkipAutomaticBankStatement('OKRA');

  useEffect(() => {
    if (skipAutomaticBankStatement) {
      handleSetApplicationBank();
    }
  }, [skipAutomaticBankStatement]);

  const handleTriggerOkraButton = () => initiateOkra();

  useEffect(() => {
    if (!okraAccountNumber || !okraBankId) return;

    const existingBankAccount = bankAccounts.find(
      acc =>
        acc.accountNumber === okraAccountNumber && acc.bank?.id === okraBankId,
    );

    if (!existingBankAccount) {
      setSelectedBankId(okraBankId);
      setAccountNumber(okraAccountNumber);
      setSelectedBankAccountId(null);
      setShowAddNewBankAccountForm(true);
      return;
    }

    setSelectedBankAccountId(existingBankAccount.id);
  }, [okraAccountNumber, okraBankId]);

  useEffect(() => {
    if (
      okraAccountNumber &&
      okraBankId &&
      selectedBankAccount?.accountNumber === okraAccountNumber &&
      !selectedBankAccount?.okraRecord?.id
    ) {
      handleConnectOkraToBank();
    }
  }, [selectedBankAccount, okraAccountNumber, okraBankId]);

  return {
    loading:
      isLoading ||
      paymentInfoLoading ||
      banksLoading ||
      addBankLoading ||
      okraLoading ||
      connectBankToOkraLoading ||
      setApplicationBankAccountLoading,
    banks,
    bankAccounts,
    selectedBankAccountId,
    handleSelectBankStatement,
    handleAddBankDetails,
    setAccountNumber,
    isOkraWidgetClosed,
    handleConnectOkraToBank,
    handleContinueButton,
    handleTriggerOkraButton,
    handleUploadBankStatementButton,
    showAddNewBankAccountForm,
    toggleShowAddNewBankAccountForm,
    selectedBankAccount,
    showContinueButton,
    showUploadBankStatementButton,
    showTriggerOkraButton,
    accountName,
    setAccountName,
    setIsLoading,
    selectedBankId,
    accountNumber,
    setShowAddNewBankAccountForm,
    showErrorAlert,
    handleAccountBankSwitch,
    initiateOkra,
    requiresOkraTransactions,
  };
};
