import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 3rem;
`;

export const Select = styled.select`
  border-radius: 0.25rem;
  line-height: ${({ theme }) => theme.to_rem('20px')};
  padding: 0.5rem 0rem;
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: 0;
  padding: 8px;
`;

export const Label = styled.label``;

export const Error = styled.label`
  color: red;
  font-size: 1.1rem;
  margin: 0;
`;
