import { useApolloClient, useSubscription, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import { Loader } from '../../../components';
import Constants from '../../../lib/constants';
import { authGetter } from '../../../lib/utils';
import { useClientInfoContext } from '../../../hooks';
import {
  PORTFOLIO_BY_ID_SUBSCRIPTION,
  VIEWER_QUERY,
  GET_ENUM,
} from './queries';

const { status, duration } = Constants;

const ViewerProvider = ({ children, history, supportingDocumentFilter }) => {
  const [skipSubscribe, setSkipSubcribe] = useState(true);
  const [portfolioId, setPortfolioId] = useState('');
  const { clientInfo } = useClientInfoContext();

  const apolloClient = useApolloClient();
  useSubscription(PORTFOLIO_BY_ID_SUBSCRIPTION, {
    variables: { portfolioId },
    onSubscriptionData: ({
      subscriptionData: { data: { portfolioByIdUpdates } = {} },
    }) => {
      if (portfolioByIdUpdates) {
        switch (portfolioByIdUpdates.event) {
          case 'RAVE_BANK_TRANSFER_SUCCESS':
            // TODO:
            break;
          case 'RAVE_BANK_TRANSFER_FAILURE':
            // TODO:
            break;
          case 'RAVE_BANK_TRANSFER_ERROR':
            // TODO:
            break;
          default:
          // TODO:
        }
      }
    },
    skip: skipSubscribe,
  });

  const redirectToSignIn = async (message, type, duration) => {
    notify.show(message, type, duration);
    localStorage.clear();
    history.push('/sign-in');
    // Clear apollo cache on sign out
    await apolloClient.resetStore();
  };

  const checkUserLoggedIn = () => {
    const apiKey = authGetter();

    if (!apiKey.hasApiKey) {
      redirectToSignIn(
        'Your session has expired. Kindly re-login to proceed',
        status.ERROR,
        duration.LONG,
      );
    }
  };

  const getCurrentLoan = portfolios => {
    const loan = portfolios.find(({ status }) => status.name !== 'CLOSED');

    if (loan) {
      const percent =
        ((loan.fullAmount - loan.amountPaid) / loan.fullAmount) * 100 - 100;
      return {
        percent,
        ...loan,
      };
    }
    return {};
  };

  const subscribeToPortfolio = portfolioId => {
    setPortfolioId(portfolioId);
    setSkipSubcribe(false);
  };

  const { data: supportingDocumentEnum } = useQuery(GET_ENUM, {
    variables: {
      type: 'SupportingDocumentType',
    },
  });

  return (
    <Query query={VIEWER_QUERY} variables={{ supportingDocumentFilter }}>
      {({ data, loading, error, refetch }) => {
        const props = {
          error,
          refetch,
          loading,
          checkUserLoggedIn,
          user: {},
          account: {},
          portfolios: [],
          currentLoan: {},
          applications: [],
          latestLoan: {},
          supportingDocumentEnum,
        };

        if (loading) return <Loader />;

        if (error) {
          notify.show(
            'An error has occurred. Please try again later.',
            Constants.status.ERROR,
            4000,
          );
          return children;
        }

        if (data && !loading && !error) {
          const { viewer } = data;
          const portfolios = viewer && viewer.account.portfolios.nodes;

          const watchPortfolio = portfolios.find(
            ({ status }) =>
              status.name === 'DISBURSING' ||
              status.name === 'PENDING_DISBURSEMENT',
          );
          if (watchPortfolio) subscribeToPortfolio(watchPortfolio.id);

          props.viewer = viewer;
          props.user = viewer.me;
          props.account = viewer.account;
          props.portfolios = portfolios;
          props.currentLoan = getCurrentLoan(viewer.account.portfolios.nodes);
          props.applications = viewer.account.applications.nodes;
          props.latestLoan = viewer.account.applications.nodes[0];
          props.supportingDocumentEnum = supportingDocumentEnum;
          props.clientInfo = clientInfo;
        }
        return children(props);
      }}
    </Query>
  );
};

export default withRouter(ViewerProvider);
