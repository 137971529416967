import React from 'react';
import './_TransactionTable.scss';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledUl = styled.ul`
  li {
    .tx-column {
      .button {
        border-color: ${props => props.theme.primaryColor};
      }
    }
  }
`;

const TransactionTable = ({ txTableHeaders, children }) => {
  return (
    <StyledUl className={'transaction-table'}>
      {txTableHeaders === [] || txTableHeaders == null ? (
        ''
      ) : (
        <li className="transaction-table__header">
          {txTableHeaders.map((val, index) => (
            <div key={index}>{val}</div>
          ))}
        </li>
      )}
      {children}
    </StyledUl>
  );
};

TransactionTable.defaultProps = {
  txTableHeaders: [],
};

TransactionTable.propTypes = {
  txTableHeaders: PropTypes.array,
};

export default TransactionTable;
