import React, { useState } from 'react';
import { Formik } from 'formik';
import * as S from './styled';
import { DataCard } from '../../../components';
import { Select } from '../../../components/FormikElements';
import useEmploymentDetailsForm from './useEmploymentDetailsForm';
import { EmployedForm, SelfEmployedForm, UnemployedForm } from './Forms';

const EmploymentDetailsScreen = () => {
  const {
    applicationLoading,
    currentEmploymentDetails,
    loanApplicationIsLoading,
    userLoading,
  } = useEmploymentDetailsForm();
  const { remitaEmploymentStatus, remitaNetIncome, remitaEmployerName } = {};
  const [isLoading, setIsLoading] = useState(false);

  return (
    <S.Wrapper>
      <DataCard
        loading={
          isLoading ||
          applicationLoading ||
          userLoading ||
          loanApplicationIsLoading
        }
      >
        <S.Title>Do you have a job?</S.Title>
        {remitaEmploymentStatus}
        <Formik
          initialValues={{
            employmentStatus:
              remitaEmploymentStatus === 'employedfulltime'
                ? 'Employed Full Time'
                : currentEmploymentDetails?.employmentStatus,
          }}
          onSubmit={() => {
            // do nothing
          }}
        >
          {({ values }) => (
            <>
              <Select
                name="employmentStatus"
                disabled={!(remitaEmploymentStatus == null)}
              >
                <option value="">--Employment Status--</option>
                <option value="Employed Full Time">Employed Full Time</option>
                <option value="Employed Part Time">Employed Part Time</option>
                <option value="Self Employed">Self Employed</option>
                <option value="unemployed">Unemployed</option>
              </Select>

              {(remitaEmploymentStatus === 'employedfulltime' ||
                values.employmentStatus === 'Employed Full Time' ||
                values.employmentStatus === 'Employed Part Time') && (
                <EmployedForm
                  employmentStatus={values.employmentStatus}
                  remitaNetIncome={remitaNetIncome}
                  remitaEmployerName={remitaEmployerName}
                  setIsLoading={setIsLoading}
                />
              )}

              {values.employmentStatus === 'Self Employed' && (
                <SelfEmployedForm
                  employmentStatus={values.employmentStatus}
                  setIsLoading={setIsLoading}
                />
              )}

              {values.employmentStatus === 'unemployed' && (
                <UnemployedForm
                  employmentStatus={values.employmentStatus}
                  setIsLoading={setIsLoading}
                />
              )}
            </>
          )}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default EmploymentDetailsScreen;
