import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { MAKE_PAYSTACK_REFERENCE_REPAYMENT } from './queries';
import { handleGraphQLErrors } from '../../../lib/utils';

const MakePaystackReferenceRepaymentProvider = () => {
  const [makeReferenceRepaymentError, setMakeReferenceRepaymentError] =
    useState(null);
  const [mutate] = useMutation(MAKE_PAYSTACK_REFERENCE_REPAYMENT, {
    onError: errors => {
      setMakeReferenceRepaymentError(handleGraphQLErrors(errors));
      return;
    },
  });

  const makeReferenceRepayment = paystackReference => {
    const payload = {
      paystackReference,
    };

    return mutate({
      variables: payload,
    });
  };

  const props = {
    makeReferenceRepayment,
    makeReferenceRepaymentError,
  };

  return props;
};

export default MakePaystackReferenceRepaymentProvider;
