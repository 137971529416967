import React, { Fragment, useEffect } from 'react';
import { Formik, Form } from 'formik';
import DatePicker from 'react-datepicker';
import Notifications from 'react-notify-toast';
import { employedSchema } from '../../../../lib/validationSchemas';
import {
  Input,
  Select,
  NumberInput,
  SubmitButton,
} from '../../../../components/FormikElements';
import {
  maxSelectableDate,
  populateStates,
  removeCommas,
} from '../../../../lib/utils';
import useEmploymentDetailsForm from '../useEmploymentDetailsForm';

const EmployedForm = ({
  employmentStatus,
  remitaEmployerName,
  remitaNetIncome,
  setIsLoading,
}) => {
  const {
    currentEmploymentDetails,
    addEmploymentDetails,
    checkWorkEmailValidation,
    applicationLoading,
    loanApplicationIsLoading,
    userLoading,
  } = useEmploymentDetailsForm();

  useEffect(() => {
    setIsLoading(applicationLoading || loanApplicationIsLoading || userLoading);
  }, [applicationLoading, loanApplicationIsLoading, userLoading]);

  return (
    <Fragment>
      <Notifications />
      <Formik
        initialValues={{
          employmentStatus,
          employerName:
            remitaEmployerName || currentEmploymentDetails?.employerName,
          employerAddress: currentEmploymentDetails?.employerAddress,
          workStartDate: currentEmploymentDetails?.workStartDate,
          employerState: currentEmploymentDetails?.employerState,
          workEmail: currentEmploymentDetails?.workEmail,
          jobRole: currentEmploymentDetails?.jobRole,
          netIncome: remitaNetIncome || currentEmploymentDetails?.netIncome,
        }}
        validate={checkWorkEmailValidation}
        validationSchema={employedSchema}
        onSubmit={values => {
          addEmploymentDetails({
            ...values,
            netIncome: removeCommas(values?.netIncome),
          });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Input
              name="employerName"
              placeholder="Employer Name"
              disabled={!(remitaEmployerName == null)}
            />

            <Input name="employerAddress" placeholder="Office Address" />

            <Select name="employerState">
              <option value="">-- Select State --</option>
              {populateStates()}
            </Select>

            <DatePicker
              placeholderText="Work Start Date"
              name="workStartDate"
              selected={
                values.workStartDate ? new Date(values.workStartDate) : null
              }
              onChange={date =>
                setFieldValue('workStartDate', date ? date.toISOString() : '')
              }
              showYearDropdown
              dateFormat="MMMM/yyyy"
              showMonthDropdown
              dropdownMode="select"
              className="datepicker"
              autoComplete="off"
              maxDate={maxSelectableDate}
            />
            {errors.workStartDate && (
              <span className="datepicker_error">{errors.workStartDate}</span>
            )}

            <Input name="jobRole" placeholder="Job Title" />

            {/* // TODO: WRAPPED IF REQUIRES WORK EMAIL */}
            {/* <Input name="workEmail" placeholder="Work Email" /> */}

            <NumberInput
              name="netIncome"
              placeholder="Net Monthly Income (&#8358;)"
              amountField
              disabled={!(remitaNetIncome == null)}
            />

            <SubmitButton value="Next" />
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default EmployedForm;
