import React from 'react';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import './_VerifyBvnBanner.scss';
import { useHistory } from 'react-router';

const VerifyBvnBanner = () => {
  const history = useHistory();
  return (
    <section className="verify-bvn-banner">
      <Alert classes="error">
        <span>
          We require your BVN to fetch and verify your information. Please
          proceed to the settings page to verify your BVN.
        </span>
        <Button
          classes="right secondary"
          button_text="Click to proceed"
          onClick={() => history.push('settings')}
        />
      </Alert>
    </section>
  );
};
export default VerifyBvnBanner;
