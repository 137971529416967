import gql from 'graphql-tag';

export const UPDATE_CUSTOMER_INFO = gql`
  mutation ($input: UpdateUserInput!) {
    updateUserData(input: $input) {
      success
      recordIds
      records {
        id
        name
        value
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation (
    $email: String!
    $phone: String!
    $password: String!
    $clientId: ID!
  ) {
    createCustomer(
      input: {
        email: $email
        phone: $phone
        password: $password
        clientId: $clientId
      }
    ) {
      token
      account {
        id
      }
      user {
        id
        bvnStatus {
          isVerified
          dateOfBirth
          isMatchingPhone
          phone
        }
      }
    }
  }
`;
