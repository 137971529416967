import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import * as S from './styled';

const Select = ({ label, disabled, children, ...restProps }) => {
  const [field, meta] = useField(restProps);
  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <S.Select {...field} disabled={!!disabled} {...restProps}>
        {children}
      </S.Select>
      {meta?.touched && meta?.error && <S.Error>{meta?.error}</S.Error>}
    </S.Wrapper>
  );
};

Select.propTypes = {
  classes: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;
