import styled from 'styled-components';
import { Title as Tit } from '../styled';

export { Wrapper, Paragraph, PreviousButton, NextButton } from '../styled';

export const InitiateWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Title = styled(Tit)`
  margin-bottom: 20px;
`;
