import styled from 'styled-components';
import { Title as Tit } from '../styled';
export { Wrapper, NextButton } from '../styled';

export const Title = styled(Tit)`
  margin-bottom: 2rem;
`;

export const FailureIcon = styled.div`
  display: flex;
  justify-content: center;
`;
