import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LOAN_CATEGORIES } from '../containers/SignUp/queries';
import { handleGraphQLErrors } from '../lib/utils';

const useGetCategories = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [loanCategories, setLoanCategories] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const modifiableLoanCategories = [...loanCategories];

  const reorderLoanCategories = loanCategories => {
    if (!loanCategories?.length) return [];
    return loanCategories.sort(
      (a, b) => Number(a.minAmount) - Number(b.minAmount),
    );
  };

  const { loading } = useQuery(GET_LOAN_CATEGORIES, {
    onError(error) {
      setErrorMessage(handleGraphQLErrors(error));
    },
    onCompleted(data) {
      setLoanCategories(data?.getLoanCategories?.categories);
      setIsSuccess(true);
    },
  });

  return {
    loading,
    errorMessage,
    loanCategories: reorderLoanCategories(modifiableLoanCategories),
    isSuccess,
  };
};

export default useGetCategories;
