import styled from 'styled-components';
import placeholder from '../../static/icons/upload-icon.svg';

export const Wrapper = styled.div`
  p,
  h4 {
    text-align: center;
  }

  .popup__content {
    max-width: 60rem;
  }
`;

export const Btns = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  button {
    margin: 8px;
  }

  input {
    min-width: 200px;
  }
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  align-items: center;

  a {
    color: ${({ theme }) => theme.secondaryColor};
    padding: 8px;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const RemitaInstruction = styled.div`
  font-weight: 700;
  margin-top: 8px;
  text-justify: auto;

  a {
    color: ${({ theme }) => theme.secondaryColor};
    padding-left: 4px;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const FileUpload = styled.label`
  border: 1px dashed ${({ theme }) => theme.secondaryColor};
  border-radius: 5px;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 32px;
  padding: 2rem;
  width: 100%;

  input {
    display: none;
    height: 0;
    padding: 0;
    width: 0;
  }

  section {
    background: url(${placeholder}) center top no-repeat;
    font-size: 14px;
    font-weight: $font-semibold;
    line-height: 1.5em;
    padding-top: 50px;
    text-align: center;
  }
`;

export const UploadWrapper = styled.div`
  width: 30rem;
  margin: 0 auto;

  button {
    width: 100%;
    margin: 16px 0;
  }
`;

export const Paragraph = styled.p`
  text-align: center;

  button {
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: underline;
    background-color: ${({ theme }) => theme.colors.color_white};
    border: 0;
    cursor: pointer;
    outline: none;
  }
`;
