import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './_Button.scss';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledButton = styled.button`
  background: ${props => props.theme.secondaryColor};

  &:disabled,
  &.disabled {
    background-color: ${props =>
      transparentize(0.35, props.theme.secondaryColor)};
  }

  &.secondary {
    color: ${props => props.theme.primaryColor};
    border-color: ${props => props.theme.primaryColor};

    &:after {
      border-right-color: ${props => props.theme.primaryColor};
    }

    &:focus,
    &:hover,
    &:active {
      color: ${props => props.theme.primaryColor};
    }

    .icon {
      svg {
        fill: ${props => props.theme.primaryColor};
      }
    }

    &:disabled,
    &.disabled {
      color: ${props => transparentize(0.65, props.theme.primaryColor)};
      border-color: ${props =>
        transparentize(0.65, props.theme.primaryColor)} !important;

      .button-tooltip {
        color: ${props => props.theme.primaryColor};
      }
    }
  }

  &.previous {
    border-color: ${props => props.theme.secondaryColor};
    color: ${props => props.theme.secondaryColor};
  }

  &.next {
    border-color: ${props => props.theme.secondaryColor};
  }
`;

const Button = ({
  buttonId,
  classes,
  disabled,
  click_event,
  type,
  button_text,
  tooltip,
  children,
  ...restProps
}) => {
  return (
    <Fragment>
      <StyledButton
        id={buttonId}
        className={`button ${classes == null ? '' : classes} ${
          disabled === true ? 'disabled' : ''
        }`}
        disabled={disabled === true ? 'disabled' : ''}
        onClick={click_event}
        type={type ? type : 'button'}
        {...restProps}
      >
        <span className="button-text">{children || button_text}</span>
        {tooltip && <span className="button-tooltip">{tooltip}</span>}
      </StyledButton>
    </Fragment>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.string,
  buttonId: PropTypes.string,
  button_text: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  click_event: PropTypes.func,
};

export default Button;
