import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 3rem;
`;

export const Button = styled.input`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 0;
  cursor: pointer !important;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
`;
