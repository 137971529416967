import React from 'react';
import PropTypes from 'prop-types';
import './_Input.scss';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledLabel = styled.label`
  &.input__group {
    &.has-append {
      .append {
        background-color: ${props =>
          transparentize(0.9, props.theme.primaryColor)};
      }
    }
  }
`;

const Input = ({
  amountField,
  phoneField,
  numberField,
  label,
  classes,
  disabled,
  type,
  errorMessage,
  ...restProps
}) => {
  return (
    <StyledLabel
      className={`input__group ${
        amountField || phoneField ? 'has-append' : ''
      }`}
    >
      {label == null ? '' : <span className={'input__label'}>{label}</span>}

      <div className="input-holder">
        {amountField && <span className="append">&#8358;</span>}
        {phoneField && <span className="append">+234</span>}

        {amountField || numberField || phoneField ? (
          <NumberFormat
            thousandSeparator={amountField}
            allowLeadingZeros={numberField || phoneField}
            type="tel"
            allowNegative={false}
            className={`input ${classes == null ? '' : classes}`}
            disabled={disabled ? 'disabled' : ''}
            {...restProps}
          />
        ) : (
          <input
            className={`input ${classes == null ? '' : classes}`}
            disabled={disabled ? 'disabled' : ''}
            type={type == null ? 'text' : type}
            {...restProps}
          />
        )}
      </div>
      {errorMessage && <span className={'input__error'}>{errorMessage}</span>}
    </StyledLabel>
  );
};

Input.propTypes = {
  amountField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  numberField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  classes: PropTypes.string,
  phoneField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
