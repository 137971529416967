import React from 'react';

const VisaCard = props => (
  <svg width={70} height={24} viewBox="0 0 70 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12c0 6.627-5.372 12-12 12-6.627 0-12-5.373-12-12S5.373 0 12 0c6.628 0 12 5.373 12 12z"
      fill="#ED342B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17.14A173.93 173.93 0 017.348 5.372H3.233s2.505 7.295 7.157 16.418h3.22c4.653-9.123 7.157-16.418 7.157-16.418h-4.115S14.684 11.056 12 17.14z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.68 11.374c-2.326 0-2.505 2.505-2.505 2.505h5.01s-.18-2.505-2.505-2.505zm5.546 5.01h-8.05s.178 2.684 3.757 2.684c1.788 0 3.577-.537 3.577-.537l.359 2.862s-1.79.716-4.294.716c-3.579 0-6.799-1.79-6.799-6.799 0-3.936 2.505-6.44 6.083-6.44 5.367 0 5.725 5.367 5.367 7.514zM41.85 12.156l.554-2.954s-4.277-1.298-7.754 1.108v11.816h3.692v-9.6c1.477-1.108 3.508-.37 3.508-.37zm-15.39-.782c-2.326 0-2.505 2.505-2.505 2.505h5.009s-.179-2.505-2.505-2.505zm5.545 5.01h-8.05s.179 2.684 3.757 2.684c1.788 0 3.578-.537 3.578-.537l.358 2.862s-1.79.716-4.294.716c-3.578 0-6.8-1.79-6.8-6.799 0-3.936 2.506-6.44 6.084-6.44 5.367 0 5.725 5.367 5.367 7.514zm18.856 1.226c-1.875-4.5-2.812-8.738-2.812-8.738H44.3s1.875 7.238 5.062 13.235h2.998c3.188-5.997 5.063-13.234 5.063-13.234h-3.75s-.937 4.237-2.812 8.737z"
      fill="#03435F"
    />
  </svg>
);

export default VisaCard;
