import React from 'react';
import { DataCard } from '../../../components';
import * as S from './styled';

const LoaderPage = () => {
  return (
    <S.Wrapper>
      <DataCard>
        <S.Title>Preparing your application</S.Title>
      </DataCard>
    </S.Wrapper>
  );
};

export default LoaderPage;
