import React from 'react';
import './_Menu.scss';
import { NavLink } from 'react-router-dom';
import { HomeIcon, MyLoansIcon, SettingsIcon, NewLoanIcon } from '../Icon/';
import { ViewerProvider } from '../../containers/providers';
import styled from 'styled-components';
import { NEW_LOAN_ROUTES } from '../../containers/LoanApplication/routes';

const StyledNav = styled.section`
  background: ${props => props.theme.primaryColor};
`;

const Menu = () => {
  return (
    <ViewerProvider>
      {({ currentLoan, latestLoan }) => (
        <StyledNav className={'main-menu'}>
          <div className="container">
            <div className="main-menu__left">
              <ul>
                <li>
                  <NavLink to="/dashboard" data-testid="dashboard-menu">
                    <HomeIcon />
                    <span className={'nav-text'}>Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/loans" data-testid="myloans">
                    <MyLoansIcon />
                    <span className={'nav-text'}>My Loans</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/settings" data-testid="settings-menu">
                    <SettingsIcon />
                    <span className={'nav-text'}>Settings</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            {currentLoan.id ||
            (latestLoan && latestLoan.status.name === 'UNDER_REVIEW') ||
            (latestLoan && latestLoan.status.name === 'PENDING') ||
            (latestLoan && latestLoan.status.name === 'AWAITING_FEEDBACK') ? (
              ''
            ) : (
              <div className="main-menu__right">
                <NavLink
                  to={`/application/${NEW_LOAN_ROUTES?.newApplication}`}
                  className="button icon-left secondary"
                >
                  <span className="icon">
                    <NewLoanIcon />
                  </span>
                  <span className={'nav-text'}>New Loan</span>
                </NavLink>
              </div>
            )}
          </div>
        </StyledNav>
      )}
    </ViewerProvider>
  );
};

export default Menu;
