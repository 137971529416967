import { useEffect, useCallback, useState } from 'react';
import { logEvent } from '../../../lib/GAHelper';
import { useLoanApplicationContext } from '../../../hooks';
import { notify } from 'react-notify-toast/bin/notify';
import { handleGraphQLErrors } from '../../../lib/utils';
import Constants from '../../../lib/constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GENERATE_PAYSTACK_CARD_REFERENCE,
  GET_PAYSTACK_CARD_REFERENCE,
} from '../queries';
import { SKIP_CARD_COLLECTION } from '../mutations';

export const useAddCard = ({ customForm, handleNext, formIndex }) => {
  const {
    applicationLoading,
    createApplicationTrace,
    clientInfo: { clientId, paystackPubKey, addCardCharge, repaymentServices },
    goToPreviousPage,
    goToNextPage,
    account,
    application,
    showErrorAlert,
    showInfoAlert,
    showSuccessAlert,
    goToLoanDetailsPage,
    setCompletedStep,
    user,
    userLoading,
  } = useLoanApplicationContext();

  const amount = addCardCharge || '100';
  const loanDuration = application?.application?.loanDuration;
  const collectionMethod =
    repaymentServices && repaymentServices.length
      ? repaymentServices.filter(x => x.name !== 'CARD')
      : [];

  const [reference, setReference] = useState('');
  const [metadata, setMetadata] = useState({});
 
  const { accountNumber, id: accountId } = account || {};

  const { email } = user || {};

  useEffect(() => {
    if (account?.cards?.length) {
      handleNext({
        variables: {
          applicationId: application.application.id,
          data: { step: formIndex + 1, path: '' },
        },
      });
    }
  }, [account?.cards]);

  const initiateCardVerification = useCallback(() => {
    notify.hide();
    logEvent('Signup', 'Initiate Paystack Card verification');
    const payStackBtn = document.querySelector('.paystackbutton');
    if (payStackBtn) {
      payStackBtn.click();
    }
    createApplicationTrace(
      Constants.pages.addCard,
      'Triggered Paystack Widget',
    );
  }, []);

  const [getByPaystackReference, { loading: getReferenceLoading }] =
    useLazyQuery(GET_PAYSTACK_CARD_REFERENCE, {
      onError(error) {
        showErrorAlert(
          handleGraphQLErrors(error) ||
            'There was an error verifying you card. Kindly try again',
        );
        createApplicationTrace(
          Constants.pages.addCard,
          'There was an error verifying card',
          true,
          {
            errorGetingPaystackReference: {
              message: error.message,
              stack: error.stack,
              error,
            },
          },
        );
      },
      onCompleted({ getCardReferenceStatus }) {
        if (!getCardReferenceStatus?.status) {
          showErrorAlert(
            'There was an error verifying you card. Kindly try again',
          );
          return;
        }
        const payStackReferenceStatus = getCardReferenceStatus.status;
        const failedReason =
          getCardReferenceStatus.reason || 'Transaction could not be completed';

        switch (payStackReferenceStatus) {
          case 'SUCCESS':
            showSuccessAlert('Card added successfully');
            setCompletedStep('addCard');
            createApplicationTrace(
              Constants.pages.addCard,
              'Successfully Added Card',
            );
            goToNextPage();
            customForm &&
              handleNext({
                variables: {
                  applicationId: application?.application?.id,
                  data: { step: formIndex + 1, path: '' },
                },
              });
            break;
          case 'FAILED':
            generateAddCardRef();
            showErrorAlert(failedReason, Constants.duration.INDEFINITE);
            break;
          case 'INVALID':
            generateAddCardRef();
            showErrorAlert(
              `${
                getCardReferenceStatus.reason ||
                'There was an error verifying you card. Kindly try again'
              }`,
              Constants.duration.INDEFINITE,
            );
            break;
          case 'NOT_REUSABLE':
            showErrorAlert(failedReason, Constants.duration.INDEFINITE);
            break;
          case 'NOT_USED':
          case 'ABANDONED':
            showInfoAlert(
              `${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add card button`,
            );
            break;
          default:
            generateAddCardRef();
        }
      },
      fetchPolicy: 'network-only',
    });

  const [generatePaystackReference, { loading: generatingReferenceLoading }] =
    useLazyQuery(GENERATE_PAYSTACK_CARD_REFERENCE, {
      onError(error) {
        showErrorAlert(
          handleGraphQLErrors(error) ||
            'Looks like there is a technical issue. Kindly try again later.',
        );
        createApplicationTrace(
          Constants.pages.addCard,
          'There was an error generating paystack reference',
          true,
          {
            errorGeneratingPaystackReference: {
              message: error.message,
              stack: error.stack,
              error,
            },
          },
        );
        goToLoanDetailsPage();
      },
      onCompleted({ getAddCardReference }) {
        if (!getAddCardReference.reference) {
          showErrorAlert(
            'Looks like there is a technical issue. Kindly try again later.',
          );
          goToLoanDetailsPage();
        }
        setReference(getAddCardReference.reference);
        setMetadata({
          accountId: accountId,
          accountNumber: accountNumber,
          cardReference: reference,
          loanDuration,
          transactionType: Constants.transactType.transactionType,
        });
      },
      fetchPolicy: 'network-only',
    });

  const [runSkipCardCollection] = useMutation(SKIP_CARD_COLLECTION, {
    onError: error => {
      const errorMessage = handleGraphQLErrors(error);
      showErrorAlert(
        errorMessage || 'Error trying to skip add debit card step.',
      );
    },
    onCompleted: ({ skipCardCollection: { success } }) => {
      if (success) {
        showSuccessAlert('Successfully skipped add debit card');
        setCompletedStep('addCard');
        handleNext({
          variables: {
            applicationId: application?.application?.id,
            data: { step: formIndex + 1 },
          },
        });
      }
    },
  });

  const runGetCardReferenceStatus = useCallback(
    async ({ loanDuration: ld }) => {
      getByPaystackReference({
        variables: {
          reference,
          loanDuration: `${ld} days`,
        },
      });
    },
    [reference],
  );

  const handleSkipCardCollection = () => {
    runSkipCardCollection({
      variables: {
        applicationId: application?.application?.id,
      },
    });
  };

  const payStackCallback = () => {
    runGetCardReferenceStatus({ loanDuration });
  };

  const generateAddCardRef = useCallback(async () => {
    generatePaystackReference({ variables: { clientId } });
  }, [clientId]);

  useEffect(() => {
    if (clientId) {
      generateAddCardRef();
    }
    
    createApplicationTrace(
      Constants.pages.addCard,
      'Navigated to Add Card Screen',
    );
  }, [clientId]);

  return {
    applicationLoading,
    amount,
    reference,
    channel: ['card'],
    email,
    paystackPubKey,
    payStackCallback,
    initiateCardVerification,
    goToPreviousPage,
    generatingReferenceLoading,
    getReferenceLoading,
    metadata,
    generateAddCardRef,
    handleSkipCardCollection,
    collectionMethod,
    userLoading,
  };
};
