import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const SubmitButton = ({ value, disabled, ...props }) => {
  return (
    <S.Wrapper>
      <S.Button type="submit" value={value} disabled={disabled} {...props} />
    </S.Wrapper>
  );
};

SubmitButton.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SubmitButton;
