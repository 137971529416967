import React from 'react';
import PropTypes from 'prop-types';
import './_Loader.scss';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledLoader = styled.div`
  ${props =>
    props.theme.primaryColor &&
    `background: ${props => transparentize(0.75, props.theme.primaryColor)}`};
`;

const Loader = ({ classes, no_spin, content }) => {
  return (
    <StyledLoader className={`loader ${classes == null ? '' : classes}`}>
      {no_spin ? (
        ''
      ) : (
        <div className="loader__content">
          <span className="loader__content-text">{content}</span>
        </div>
      )}
    </StyledLoader>
  );
};

Loader.propTypes = {
  classes: PropTypes.string,
  content: PropTypes.string,
  no_spin: PropTypes.bool,
};

export default Loader;
