import { Form, Formik, useFormikContext } from 'formik';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import * as S from '../../../../containers/SignUp/CreateNewAccount/styled';
import { Alert, DataCard } from '../../../../components';
import { NumberInput } from '../../../../components/FormikElements';
import { getClientId } from '../../../../hooks';
import DatePicker from 'react-datepicker';
import { logEvent } from '../../../../lib/GAHelper';
import { dobMaxSelectableDate, trimGraphQLErrors } from '../../../../lib/utils';
import moment from 'moment';
import { useGetCustomerBvnStatus } from './useVfdBvnVerification';
import { useUpdateUserData } from '../../../../hooks/useUpdateUserData';
import { notify } from 'react-notify-toast';
import { duration, status } from '../../../../lib/constants';

export const VFDBvnVerification = ({
  setVerificationFormState,
  setUserHasVerifiedBvn,
  setVerifiedUserData,
  verificationFormState,
  updateBvnVerificationState,
  setUpdateBvnVerificationState,
}) => {
  const {
    loading: getCustomerBvnStatusIsLoading,
    getCustomerBvnStatusError,
    customerBvnStatus,
    getCustomerBvnStatus,
  } = useGetCustomerBvnStatus();
  const {
    updateUserDataResponse,
    updateUserData,
    updateUserDataIsLoading,
    updateUserDataError,
  } = useUpdateUserData();
  const [bvnVerificationSuccessMessage, setBvnVerificationSuccessMessage] =
    useState('');

  useEffect(() => {
    if (customerBvnStatus) {
      if (updateBvnVerificationState?.isUpdateBvnData) {
        setBvnVerificationSuccessMessage(
          'BVN Verification was successful, kindly proceed',
        );
      } else {
        setUserHasVerifiedBvn(true);
        setVerifiedUserData({
          ...customerBvnStatus,
          dateOfBirth: verificationFormState?.bvnDOB,
        });
      }
    }
  }, [customerBvnStatus]);

  useEffect(() => {
    if (updateUserDataResponse?.success) {
      setUpdateBvnVerificationState(prev => ({ ...prev, openPopUp: false }));
      notify.show('BVN updated successfully', status.SUCCESS, duration.SHORT);
    }
  }, [updateUserDataResponse]);

  const handleUpdateUserData = useCallback(() => {
    updateUserData({
      number: updateBvnVerificationState?.bvn,
      validateBvn: true,
    });
  }, []);

  return (
    <S.Wrapper>
      <DataCard
        loading={getCustomerBvnStatusIsLoading || updateUserDataIsLoading}
      >
        <S.Title>
          {updateBvnVerificationState?.isUpdateBvnData
            ? 'Verifying BVN'
            : 'Create Account'}
        </S.Title>

        {getCustomerBvnStatusError && (
          <Alert classes="error">
            {trimGraphQLErrors(
              getCustomerBvnStatusError ? getCustomerBvnStatusError : '',
            ) || 'There was an error processing your request'}
          </Alert>
        )}

        {bvnVerificationSuccessMessage && (
          <Alert classes="success" dismissible={true}>
            {bvnVerificationSuccessMessage
              ? bvnVerificationSuccessMessage
              : 'BVN verified successfully'}
          </Alert>
        )}

        {updateUserDataError && (
          <Alert classes="error">
            {trimGraphQLErrors(
              updateUserDataError ? updateUserDataError : '',
            ) || 'There was an error processing your request'}
          </Alert>
        )}

        <Formik
          initialValues={{
            bvn: '',
            bvnPhoneDigits: '',
            bvnDOB: '',
          }}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            setFieldTouched,
            errors,
            touched,
          }) => (
            <>
              <Form>
                <S.PDisclosure className="active">
                  <Fragment>
                    <NumberInput
                      onChange={e => {
                        setFieldValue('bvn', e.target.value);
                      }}
                      name="bvn"
                      value={values.bvn}
                      placeholder="Kindly enter your BVN"
                      maxLength="11"
                      decimalSeparator={false}
                      disabled={updateBvnVerificationState?.isUpdateBvnData}
                    />
                    <NumberInput
                      name="bvnPhoneDigits"
                      placeholder="Last 4 digits of BVN phone number"
                      maxLength="4"
                      decimalSeparator={false}
                    />
                    <DatePicker
                      placeholderText="BVN Date of Birth"
                      name="bvnDOB"
                      selected={values.bvnDOB ? new Date(values.bvnDOB) : null}
                      onChange={date => {
                        setFieldValue('bvnDOB', date ? date.toISOString() : '');
                      }}
                      onChangeRaw={e => {
                        setFieldTouched('bvnDOB', true, true);
                        e.preventDefault();
                      }}
                      showYearDropdown
                      dateFormat="dd/MMMM/yyyy"
                      showMonthDropdown
                      dropdownMode="select"
                      className="datepicker"
                      autoComplete="off"
                      maxDate={dobMaxSelectableDate}
                      onBlur={handleBlur}
                    />
                    {errors.bvnDOB && touched.bvnDOB ? (
                      <span className="datepicker_error">{errors.bvnDOB}</span>
                    ) : (
                      ''
                    )}

                    {updateBvnVerificationState?.isUpdateBvnData ? (
                      <S.ButtonsContainer>
                        <S.OutlineButton
                          button_text="Verify BVN"
                          type="submit"
                          value="Cancel"
                          onClick={() => {
                            setUpdateBvnVerificationState(prev => ({
                              ...prev,
                              openPopUp: !prev.openPopUp,
                            }));
                          }}
                        />
                        <S.Button
                          disabled={!bvnVerificationSuccessMessage}
                          onClick={handleUpdateUserData}
                          button_text="Proceed"
                          value="Proceed"
                        />
                      </S.ButtonsContainer>
                    ) : (
                      <S.ButtonsContainer>
                        <S.Button
                          disabled={
                            !values.bvn ||
                            !values.channel ||
                            !(values.bvnOTP.length === 6)
                          }
                          button_text="Verify BVN"
                          type="submit"
                          value="Verify BVN"
                        />
                      </S.ButtonsContainer>
                    )}
                  </Fragment>
                </S.PDisclosure>
              </Form>

              <VfdBvnValidation
                getCustomerBvnStatus={getCustomerBvnStatus}
                customerBvnStatus={customerBvnStatus}
                setVerificationFormState={setVerificationFormState}
                updateBvnVerificationState={updateBvnVerificationState}
              />
            </>
          )}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

const VfdBvnValidation = ({
  getCustomerBvnStatus,
  setVerificationFormState,
  updateBvnVerificationState,
}) => {
  const clientId = getClientId();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const { bvn, bvnPhoneDigits, bvnDOB } = values;
    const convertBvnDOB = moment(values.bvnDOB).format('DD-MMM-YYYY');

    updateBvnVerificationState?.bvn &&
      setFieldValue('bvn', updateBvnVerificationState?.bvn);

    if (bvn?.length === 11 && bvnPhoneDigits.length === 4 && bvnDOB) {
      logEvent('Signup', 'Check BVN Status');
      !updateBvnVerificationState?.isUpdateBvnData &&
        setVerificationFormState(prevState => ({
          ...prevState,
          bvn,
          bvnDOB,
        }));
      getCustomerBvnStatus({
        bvn,
        clientId,
        bvnPhoneDigits,
        bvnDOB: convertBvnDOB,
      });
    }
  }, [
    values.bvn,
    values.bvnPhoneDigits,
    values.bvnDOB,
    updateBvnVerificationState?.bvn,
  ]);

  return null;
};
