import { Form, Formik } from 'formik';
import React, { Fragment } from 'react';
import Notifications from 'react-notify-toast';
import { Alert, DataCard } from '../../../components';
import {
  Checkbox,
  Input,
  SubmitButton,
} from '../../../components/FormikElements';
import * as S from './styled';
import useSocialMediaForm from './useSocialMediaForm';

const SocialMedia = () => {
  const {
    validateMediaUrl,
    handleOnChange,
    handleProfileUrlSubmit,
    goToPreviousPage,
    loanApplicationIsLoading,
    clientInfo: { requiredSocialMediaAccounts },
    checked,
    checkboxError,
    inputErrors,
    socialMedia,
    setSocialMedia,
  } = useSocialMediaForm();

  return (
    <Fragment>
      <Notifications />
      <S.Wrapper>
        <DataCard loading={loanApplicationIsLoading}>
          <S.Title>Connect to Social Media</S.Title>
          <Formik
            initialValues={{}}
            onSubmit={
              socialMedia &&
              (values => {
                handleProfileUrlSubmit(values);
              })
            }
          >
            <Form>
              <S.Subtitle>
                Kindly select all the social media platforms listed and add
                their respective social media links.
              </S.Subtitle>
              {checkboxError ? (
                <Alert classes="warning"> Please select a checkbox</Alert>
              ) : inputErrors ? (
                <Alert classes="warning"> Please enter a valid URL</Alert>
              ) : (
                ''
              )}
              <S.CheckBoxGroup>
                {requiredSocialMediaAccounts &&
                  requiredSocialMediaAccounts.map((requiredUrl, index) => (
                    <Checkbox
                      noBorder
                      label={requiredUrl.name}
                      name={requiredUrl.name}
                      value={requiredUrl.name}
                      onChange={e => handleOnChange(e)}
                      defaultChecked={checked}
                      error={checkboxError ? checkboxError : ''}
                      key={index}
                    />
                  ))}
              </S.CheckBoxGroup>
              {socialMedia.length > 0 && (
                <S.Text>
                  Login to your social media platform on your internet browser
                  then copy and paste your profile link below
                </S.Text>
              )}
              {socialMedia.length !== 0 &&
                socialMedia.map((item, index) => (
                  <Input
                    key={index}
                    name={`${item.provider}`}
                    placeholder={`${item.provider.toUpperCase()} URL`}
                    classes="border-bottom"
                    onChange={e => {
                      item.profileUrl = e.target.value;
                      setSocialMedia([...socialMedia]);
                      validateMediaUrl(e);
                    }}
                  />
                ))}
              <SubmitButton value="Next" />
              <S.PreviousButton
                type="button"
                value="Previous"
                onClick={goToPreviousPage}
              />
            </Form>
          </Formik>
        </DataCard>
      </S.Wrapper>
    </Fragment>
  );
};

export default SocialMedia;
