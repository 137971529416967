import React from 'react';
import './_BankCard.scss';
import Ellipsis from '../Icon/Ellipsis';
import Radio from '../FormElements/Radio/Radio';
import * as S from './styled';

const BankCard = ({ isDefault, on_click, active, children }) => {
  return (
    <S.StyledBankCard className={'bank-card'} onClick={on_click}>
      <div className={'bank-card__top'}>
        {on_click ? (
          <S.CustomTop active={active}>
            <div className="circle" />
            {active && (
              <span className="left_label">THIS ACCOUNT IS ACTIVE.</span>
            )}
          </S.CustomTop>
        ) : (
          <div className={'card-status'}>
            <Radio
              defaultChecked={isDefault}
              name="defaultBank"
              label={isDefault && 'This is active'}
              disabled
            />
          </div>
        )}
        <div className={'icon'}>
          <Ellipsis />
        </div>
      </div>
      <div className={'bank-card__mid'}>{children}</div>
    </S.StyledBankCard>
  );
};

export default BankCard;
