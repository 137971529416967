import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useField } from 'formik';
import * as S from './styled';

export const Input = ({ label, type, ...restProps }) => {
  const [field, meta] = useField(restProps);

  return (
    <S.Wrapper type={type}>
      <S.Label>{label}</S.Label>
      <S.Input
        {...field}
        type={type || 'text'}
        className={'input'}
        {...restProps}
      />
      {meta.touched && meta.error ? (
        <S.Error className="error">{meta.error}</S.Error>
      ) : null}
    </S.Wrapper>
  );
};

export const NumberInput = ({
  label,
  amountField,
  phoneField,
  decimalSeparator,
  ...restProps
}) => {
  const [field, meta] = useField(restProps);
  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>

      <S.NumberFormatContainer>
        {amountField && <span>&#8358;</span>}
        {phoneField && <span>+234</span>}
        <NumberFormat
          {...field}
          thousandSeparator={amountField}
          allowLeadingZeros={!amountField}
          decimalSeparator={decimalSeparator}
          type="tel"
          allowNegative={false}
          className={'input'}
          {...restProps}
        />
      </S.NumberFormatContainer>
      {meta.touched && meta.error && <S.Error>{meta.error}</S.Error>}
    </S.Wrapper>
  );
};

Input.propTypes = {
  amountField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  phoneField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  decimalSeparator: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  type: PropTypes.string,
};

NumberFormat.propTypes = Input.propTypes;
