import gql from 'graphql-tag';

export const NOTIFY_OFFLINE_REPAYMENT = gql`
  mutation NotifyOfflineRepaymentMutation(
    $portfolioId: ID!
    $service: OfflineRepaymentService!
    $amount: Float!
    $bankId: ID!
    $bankBranch: String
    $tellerNo: String
    $chequeNo: String
    $accountName: String
    $supportingDocuments: [ID!]
  ) {
    notifyOfflineRepayment(
      input: {
        portfolioId: $portfolioId
        service: $service
        amount: $amount
        bankId: $bankId
        bankBranch: $bankBranch
        tellerNo: $tellerNo
        chequeNo: $chequeNo
        accountName: $accountName
        supportingDocuments: $supportingDocuments
      }
    ) {
      id
      amount
      status
      bank {
        name
      }
    }
  }
`;

export const MAKE_PAYSTACK_CARD_REPAYMENT = gql`
  mutation MakePaystackCardRepaymentMutation(
    $accountCardId: ID!
    $portfolioId: ID!
    $amount: Float!
  ) {
    makePaystackCardRepayment(
      input: {
        accountCardId: $accountCardId
        portfolioId: $portfolioId
        amount: $amount
      }
    ) {
      success
      transaction {
        amount
      }
      message
    }
  }
`;

export const MAKE_PAYSTACK_REFERENCE_REPAYMENT = gql`
  mutation MakePaystackReferenceRepaymentMutation($paystackReference: String!) {
    makePaystackReferenceRepayment(
      input: { paystackReference: $paystackReference }
    ) {
      success
      transaction {
        amount
      }
    }
  }
`;
export const GET_REPAYMENTBREAKDOWN_QUERY = gql`
  query GetRepaymentBreakdown(
    $policyId: ID!
    $principalAmount: Float!
    $duration: Int!
    $moratoriumPeriod: Int!
  ) {
    getRepaymentBreakdown(
      input: {
        policyId: $policyId
        principalAmount: $principalAmount
        duration: $duration
        moratoriumPeriod: $moratoriumPeriod
      }
    ) {
      principalAmount
      duration
      totalInterest
      rateInDecimal
      repaymentFrequency
      repaymentBreakdown {
        principalBalance
        expectedPayment
        interestPortion
        principalPortion
        endingBalance
        dueDate
      }
    }
  }
`;
