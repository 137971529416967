import styled from 'styled-components';
import { Title as Tit } from '../../styled';
export { Wrapper } from '../../styled';

export const Title = styled(Tit)`
  margin: 0 0 3.2rem 0;
`;

export const SaveContinueButton = styled.button`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  display: block;
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  cursor: pointer !important;
  padding: 4px 25px;

  &:disabled {
    opacity: 0.7;
  }
`;
