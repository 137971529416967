import ReactGA from 'react-ga';
import { getClientId } from '../hooks';

const GA_TAG = process.env.REACT_APP_GA_TAG || 'UA-138753058-1';
let initializedGa = false;

export const initializeGA = () => {
  const clientId = getClientId();

  if (GA_TAG != null && typeof GA_TAG !== 'undefined') {
    ReactGA.initialize(GA_TAG, {
      debug: false,
      gaOptions: { clientId },
    });

    initializedGa = true;
  }
};

export const logPageView = () => {
  if (initializedGa) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const logEvent = (stepCategory, action, nonInteraction = false) => {
  if (initializedGa) {
    ReactGA.event({
      category: stepCategory,
      action,
      nonInteraction,
    });

    ReactGA.timing({
      category: stepCategory,
      variable: 'load',
      value: 20,
    });
  }
};
