import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

export const PasswordField = styled.div`
  position: relative;
  width: 100%;
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 38px;
  right: 0;
  font-weight: 800;
  color: ${({ theme }) => theme.primaryColor};
  background: transparent;
  text-align: right;
  width: auto !important;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

export const Button = styled.input`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 0;
  cursor: pointer !important;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  text-align: center;
`;

export const OutlineButton = styled.input`
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  cursor: pointer !important;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  text-align: center;
  margin-right: 10px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
`;

export const PDisclosure = styled.section`
  transition: 0.3s;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;

  &.active {
    max-height: 1200px;
  }
`;

export {
  Wrapper,
  TwoColumn,
  AddressTwoColumn,
  Title,
  Aside,
} from '../Signup/styled';
