import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { SubmitButton } from '../../../../components/FormikElements';
import useEmploymentDetailsForm from '../useEmploymentDetailsForm';

const UnemployedForm = ({ employmentStatus, setIsLoading }) => {
  const {
    addEmploymentDetails,
    applicationLoading,
    loanApplicationIsLoading,
    userLoading,
  } = useEmploymentDetailsForm();

  useEffect(() => {
    setIsLoading(applicationLoading || loanApplicationIsLoading || userLoading);
  }, [applicationLoading, loanApplicationIsLoading, userLoading]);

  return (
    <Formik
      initialValues={{
        employmentStatus,
      }}
      onSubmit={values => {
        addEmploymentDetails(values);
      }}
    >
      <Form>
        <SubmitButton value="Next" />
      </Form>
    </Formik>
  );
};

export default UnemployedForm;
