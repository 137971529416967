import React from 'react';
import { Helmet } from 'react-helmet';
import { useClientInfoContext } from '../../hooks';

const Favicon = () => {
  const {
    clientInfo: { faviconUrl },
  } = useClientInfoContext();

  return (
    <Helmet>
      <link
        rel="icon"
        id="favicon"
        size="16x16"
        type="image/png"
        href={faviconUrl}
      />
    </Helmet>
  );
};

export default Favicon;
