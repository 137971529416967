import React, { useEffect } from 'react';
import useMbsForm from './useMbsForm';
import MbsInstruction from './components/MbsInstruction';
import MbsOtp from './components/MbsOtp';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { APPLICATION_STEPS } from '../../../lib/applicationSteps';

const Mbs = ({ customForm, handleNext, formIndex }) => {
  const { path } = useRouteMatch();
  const getPath = route => `${path}/${route}`;
  const {
    applicationLoading,
    application,
    redirectToExternal,
    redirectToOTPPage,
  } = useMbsForm({ customForm, handleNext, formIndex });
  const { requiredSteps, completedSteps } =
    (application && application?.application) || {};

  useEffect(() => {
    if (
      !applicationLoading &&
      requiredSteps &&
      requiredSteps?.includes(
        APPLICATION_STEPS.completeExternalBankStatementRequest.name,
      ) &&
      !completedSteps?.includes(
        APPLICATION_STEPS.completeExternalBankStatementRequest.name,
      )
    ) {
      redirectToExternal();
    } else {
      redirectToOTPPage();
    }
  }, [requiredSteps]);

  return (
    <>
      <Switch>
        <Route path={getPath('otp')}>
          <MbsOtp />
        </Route>
        <Route path={getPath('instruction')}>
          <MbsInstruction />
        </Route>
      </Switch>
    </>
  );
};

export default Mbs;
