import { Form, Formik } from 'formik';
import React from 'react';
import Notifications from 'react-notify-toast';
import { Alert, Button, DataCard, Popup } from '../../../components';
import {
  Input,
  NumberInput,
  SubmitButton,
} from '../../../components/FormikElements';
import {
  EditWorkEmailValidationSchema,
  EmailOTPValidationSchema,
} from '../../../lib/validationSchemas';
import * as S from './styled';
import useWorkEmailVerificationForm from './useWorkEmailVerificationForm';

const WorkEmailVerification = ({ customForm }) => {
  const {
    applicationLoading,
    otpResendSuccess,
    workEmailError,
    workEmail,
    editWorkEmailPopup,
    confirmWorkEmailLoading,
    workEmailConfirmationLoading,
    handleSubmit,
    checkWorkEmailValidation,
    handleWorkEmailEdit,
    openEditButtonPopup,
    resendEmailOTP,
    closeEditButtonPopup,
  } = useWorkEmailVerificationForm({
    customForm,
  });

  const popUp = () => (
    <Popup title="Edit your work e-mail" classes="edit-phone">
      <S.Subtitle>
        Please enter your work email so we can send your verification OTP
      </S.Subtitle>
      <Formik
        initialValues={{
          workEmail,
        }}
        validate={checkWorkEmailValidation}
        validationSchema={EditWorkEmailValidationSchema}
        onSubmit={values => {
          handleWorkEmailEdit(values);
        }}
      >
        <Form>
          <Input name="workEmail" placeholder="Work e-mail" type="email" />
          <SubmitButton value="Change e-mail" />
          <Button classes="secondary block" click_event={closeEditButtonPopup}>
            Cancel
          </Button>
        </Form>
      </Formik>
    </Popup>
  );

  const verifyWorkEmail = () => (
    <>
      <S.Title>Verify your work e-mail</S.Title>
      <S.Subtitle>{`Please enter verification code sent to  ${
        workEmail || ''
      }`}</S.Subtitle>
      {editWorkEmailPopup && popUp()}
      {workEmailError && <Alert classes="error">{workEmailError}</Alert>}
      {otpResendSuccess && <Alert classes="success">{otpResendSuccess}</Alert>}
      <Formik
        initialValues={{
          workEmailVerificationCode: '',
        }}
        validationSchema={EmailOTPValidationSchema}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        <Form>
          <NumberInput
            name="workEmailVerificationCode"
            maxLength="6"
            placeholder="Verification Code"
          />
          <S.Paragraph className="center-text">
            Didn't get OTP? <br />
            <button type="button" onClick={() => resendEmailOTP()}>
              Resend OTP
            </button>{' '}
            /{' '}
            <button type="button" onClick={openEditButtonPopup}>
              {' '}
              Edit Work Email
            </button>
          </S.Paragraph>
          <SubmitButton value="Next" />
        </Form>
      </Formik>
    </>
  );

  const addWorkEmail = () => (
    <>
      <S.Title>Add your work e-mail</S.Title>
      <S.Subtitle>Enter your work e-mail for verification</S.Subtitle>
      {workEmailError && <Alert classes="error">{workEmailError}</Alert>}
      <Formik
        initialValues={{
          workEmail: '',
        }}
        validate={checkWorkEmailValidation}
        validationSchema={EditWorkEmailValidationSchema}
        onSubmit={values => {
          handleWorkEmailEdit(values);
        }}
      >
        <Form>
          <Input name="workEmail" placeholder="Work e-mail" type="email" />
          <SubmitButton value="Next" />
        </Form>
      </Formik>
    </>
  );

  return (
    <S.Wrapper>
      <Notifications />
      <DataCard
        loading={
          applicationLoading ||
          workEmailConfirmationLoading ||
          confirmWorkEmailLoading
        }
      >
        {workEmail ? verifyWorkEmail() : addWorkEmail()}
      </DataCard>
    </S.Wrapper>
  );
};

export default WorkEmailVerification;
