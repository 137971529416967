import { useImageUploader, useLoanApplicationContext } from '../../../hooks';
import { useCallback, useEffect, useState } from 'react';
import { logEvent } from '../../../lib/GAHelper';
import { pages } from '../../../lib/constants';
import { useHistory } from 'react-router';
import { handleCustomFormKyc } from '../../../lib/customFormNavigation';

const useUploadSelfieForm = ({ customForm, handleNext }) => {
  const {
    applicationLoading,
    createApplicationTrace,
    goToNextPage,
    user,
    clientInfo: { requiresReferee, requiresGovernmentId, requireWorkEmail },
    applicationNumber,
    applicationId,
    setCompletedStep,
  } = useLoanApplicationContext();

  const {
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
  } = useImageUploader();
  const [passportDetails, setPassportDetails] = useState({});
  const history = useHistory();
  const type = 'PASSPORT';

  const handleSelfieUpload = useCallback(() => {
    logEvent('Signup', 'Upload Selfie');
    const fileName = `selfie_${user?.firstName}_${user?.lastName}_${user?.id}`;
    uploadImage(passportDetails.fileDetails, fileName, type);
  }, [passportDetails]);

  const handlePreview = e => {
    handleImagePreview(e, setPassportDetails);
  };

  const pageRenderConditions = {
    requiresReferee,
    requireWorkEmail,
    requiresGovernmentId,
  };

  useEffect(() => {
    if (data) {
      setCompletedStep('selfie');

      if (customForm) {
        handleCustomFormKyc({
          pageRenderConditions,
          applicationId,
          handleNext,
          history,
          applicationNumber,
          currentStepCompleted: data,
        });
      } else {
        goToNextPage();
      }
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.uploadSelfie,
      'Navigated to Selfie Upload Screen',
    );
  }, []);

  return {
    applicationLoading,
    createApplicationTrace,
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
    handleSelfieUpload,
    handlePreview,
    passportDetails,
  };
};

export default useUploadSelfieForm;
