import React from 'react';

const CheckIcon = props => (
  <svg width={92} height={92} fill="none" {...props}>
    <g filter="url(#prefix__filter0_d)">
      <circle cx={46} cy={42} r={42} fill="#9DD858" />
      <circle cx={46} cy={42} r={41} stroke="#fff" strokeWidth={2} />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.075 50.576a1 1 0 01-1.414 0l-7.87-7.87a1 1 0 00-1.414 0l-1.68 1.68a1 1 0 000 1.415L38.66 56.766a1 1 0 001.414 0l25.112-25.113a1 1 0 000-1.414l-1.68-1.68a1 1 0 00-1.414 0L40.075 50.576z"
      fill="#fff"
    />
    <defs>
      <filter
        id="prefix__filter0_d"
        x={0}
        y={0}
        width={92}
        height={92}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default CheckIcon;
