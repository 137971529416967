import React, { useEffect, useState } from 'react';
import {
  useConsentCallback,
  useRequestConsent,
} from '../../../containers/providers';
import { titleCase } from '../../../lib/utils';
import { Alert, Button, Input, Popup } from '../../index';
import { useFormState } from 'react-use-form-state';
import '../_OfferLeter.scss';

const SignWithOtp = ({ popupToggle, application, setLoading }) => {
  const [formState, { tel }] = useFormState();
  const [resendOtp, setResendOtp] = useState(false);
  const [OtpSuccess, setOtpSuccess] = useState(false);
  const [OtpError, setOtpError] = useState(false);

  const {
    success: requestConsentSuccess,
    loading: requestConsentLoading,
    errorMessage: requestConsentErrorMessage,
    requestConsent,
  } = useRequestConsent();

  const {
    loading: consentCallbackLoading,
    success: consentCallbackSuccess,
    errorMessage: consentCallbackErrorMessage,
    consentCallback,
  } = useConsentCallback();

  const signNow = e => {
    e.preventDefault();
    e.stopPropagation();

    setOtpSuccess(false);
    setOtpError(false);

    consentCallback({
      applicationNumber: application.applicationNumber,
      providerName: 'OTP',
      otp: formState.values.otp,
    });
  };

  const sendOtp = type => {
    setOtpSuccess(false);
    setOtpError(false);

    requestConsent({
      applicationNumber: application.applicationNumber,
      providerName: 'OTP',
    });

    if (type === 'resendOtp') {
      setResendOtp(true);
    }
  };

  useEffect(() => {
    sendOtp();
  }, []);

  useEffect(() => {
    if (!requestConsentLoading && requestConsentSuccess) {
      setOtpSuccess(true);
    }
  }, [requestConsentLoading, requestConsentSuccess]);

  useEffect(() => {
    if (!consentCallbackLoading && consentCallbackErrorMessage) {
      setOtpError(true);
    }
  }, [consentCallbackLoading, consentCallbackErrorMessage]);

  useEffect(() => {
    if (requestConsentLoading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [requestConsentLoading]);

  useEffect(() => {
    if (consentCallbackLoading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [consentCallbackLoading]);

  useEffect(() => {
    if (consentCallbackSuccess) {
      popupToggle('OfferLetterSigned');
    }
  }, [consentCallbackSuccess]);

  return (
    <Popup classes="offer-letter-popup">
      <h4 className={'data-card__title'}>Sign With OTP</h4>

      {!requestConsentLoading && requestConsentErrorMessage ? (
        <Alert classes="warning">{titleCase(requestConsentErrorMessage)}</Alert>
      ) : (
        ''
      )}

      {OtpSuccess && resendOtp ? (
        <Alert classes="success">We have resent your OTP.</Alert>
      ) : (
        ''
      )}

      {OtpError && consentCallbackErrorMessage ? (
        <Alert classes="warning">
          {titleCase(consentCallbackErrorMessage)}
        </Alert>
      ) : (
        ''
      )}

      <p className="center-text">
        We have sent a 6 digit verification code to your registered phone
        number. Please enter the code below.
      </p>

      <form className="otpsign-form" onSubmit={signNow}>
        <Input
          classes="border-bottom"
          placeholder="Enter OTP"
          name="otp"
          numberField
          minLength={6}
          maxLength={6}
          required
          {...tel('otp')}
        />
        <Button
          type="button"
          classes="small button-link resend-otp"
          click_event={() => sendOtp('resendOtp')}
        >
          Resend OTP
        </Button>
        <Button type="submit">Sign Offer Letter</Button>
      </form>

      <div className="otpsign-footer">
        <p>
          I acknowledge that I have read the content of this document, and I’m
          entering into a legally binding agreement by clicking the Sign Offer
          Letter button above. I give my consent to the use of electronic
          communication and records related to this agreement.{' '}
          <Button
            onClick={() => popupToggle('DeclineOfferLetter')}
            classes="button-link small"
          >
            Click here to decline
          </Button>
          .
        </p>
      </div>
    </Popup>
  );
};

export default SignWithOtp;
