import styled from 'styled-components';
import { Title as Tit } from '../styled';
import documentUploadIcon from '../../../static/icons/document-upload-icon.svg';
export { Wrapper, Title } from '../styled';

export const Subtitle = styled(Tit)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fonts.font_regular};
`;

export const DocumentUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.875rem;
`;

export const Label = styled.label`
  background: rgba(${({ theme }) => theme.secondaryColor}, 0.1);
  border: 1px dashed ${({ theme }) => theme.secondaryColor};
  box-sizing: border-box;
  border-radius: 5px;
  width: 13.875rem;
  height: 10.825rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px 6px;
  cursor: pointer;
`;

export const Section = styled.section`
  margin-top: -15px;
`;

export const DocumentUploadLabel = styled.div`
  height: 1.875rem;
  background: url(${documentUploadIcon}) center top no-repeat;
  background-size: contain;
  margin-top: 2.875rem;
`;

export const Input = styled.input`
  display: none;
`;

export const DocumentTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: ${({ theme }) => theme.secondaryColor};
    overflow: hidden;
    height: 6.725rem;
    line-height: 16px
    margin: 0.625rem;
`;
