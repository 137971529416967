import React, { useState } from 'react';
import './_RepaymentCard.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Popup } from '../index';
import { transparentize } from 'polished';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useClientInfoContext } from '../../hooks';
import { NairaAmount } from '../Icon';

const StyledRepaymentCard = styled.section`
  h6 {
    color: ${props => props.theme.primaryColor};
  }

  button,
  .button {
    &.secondary {
      border-color: ${props => props.theme.primaryColor};
    }
  }

  .repayment-progress {
    background: ${props => transparentize(0.85, props.theme.secondaryColor)};

    .progress {
      background: ${props => props.theme.secondaryColor};
    }
  }

  .overlay {
    &-status {
      border-color: ${props => props.theme.secondaryColor};
      color: ${props => props.theme.secondaryColor};
    }
  }
`;

const RepaymentCard = props => {
  const {
    clientInfo: { bankDetails, noCardImplementation },
  } = useClientInfoContext();
  const [showPopup, setShowPopup] = useState(false);
  const { application } = props;

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const progressWidth = {
    width: `${Math.abs(props.percent).toFixed(2)}%`,
  };

  const nextInstallment =
    (application &&
      application.repayments &&
      application.repayments.find(
        repayment => repayment.status.name === 'PENDING',
      )) ||
    {};

  return (
    <StyledRepaymentCard className={'repayment-card'}>
      <div className="left">
        <h6>Your Current Repayment</h6>
        <h1>
          <NairaAmount amount={props.amountPaid} /> <span>of</span>{' '}
          <NairaAmount amount={props.fullAmount} />
        </h1>
        <div className="repayment-progress">
          <span className="progress" style={progressWidth} />
        </div>
        <span className="text">
          {Math.abs(props.percent).toFixed(2)}% Complete
        </span>
      </div>
      <div className="right">
        <h6>Your Next Payment</h6>
        <h1>
          <NairaAmount
            amount={
              (nextInstallment && nextInstallment.outstandingPayment) ||
              props.repaymentAmount
            }
          />
        </h1>
        <span className="text">
          {props.repaymentDate === null
            ? 'N/A'
            : moment(props.repaymentDate).format('LL')}
        </span>
        {(props.status === 'DISBURSED' || props.status === 'OVERDUE') && (
          <div className="quick-repay">
            {noCardImplementation ? (
              ''
            ) : (
              <Button
                classes="small"
                click_event={() => props.history.push('/loans/repay-loan')}
                button_text="Make Repayment"
              />
            )}
          </div>
        )}
        {showPopup && (
          <Popup title="Bank Details" classes="loan-requirements">
            <p>You can make payment to the account below:</p>
            {bankDetails ? (
              <ul>
                <li>BANK NAME : {bankDetails.bank.name}</li>
                <li>ACCOUNT NUMBER :{bankDetails.accountNumber}</li>
                <li>ACCOUNT NAME : {bankDetails.accountName}</li>
              </ul>
            ) : (
              ''
            )}

            {props.portfolioNumber ? (
              <p>
                Use <span className="bold-text">{props.portfolioNumber}</span>{' '}
                as the narrative.
              </p>
            ) : (
              ''
            )}
            <div>
              <p className="bold-text">
                Note: After making the payment, please click continue repayment
                to upload your receipt for payment confirmation.
              </p>
            </div>
            <div className="button-list center-text">
              <Button
                classes="next mb-1"
                click_event={() => props.history.push('/loans/repay-offline')}
              >
                Continue Repayment
              </Button>
              <Button classes="secondary" click_event={handleShowPopup}>
                Cancel Repayment
              </Button>
            </div>
          </Popup>
        )}
      </div>
      {props.overlay && (
        <aside className="overlay">
          <div className="overlay-status">
            {props?.status === 'PENDING' &&
            props.completedSteps?.includes('COMPLETE_APPLICATION')
              ? 'PROCESSING'
              : props.status}
          </div>
        </aside>
      )}
    </StyledRepaymentCard>
  );
};

RepaymentCard.propTypes = {
  amountPaid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  repaymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  repaymentDate: PropTypes.any,
  status: PropTypes.any,
  overlay: PropTypes.bool,
  portfolioNumber: PropTypes.string,
};

export default withRouter(RepaymentCard);
