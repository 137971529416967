import React, { useEffect } from 'react';
import { NewCheck } from '../../Icon';
import { Popup } from '../../index';

const OfferLetterSigned = ({ refetch }) => {
  useEffect(() => {
    refetch();
  }, []);

  return (
    <Popup classes="offer-letter-popup">
      <div className="response-icon-holder">
        <NewCheck />
      </div>

      <h4 className={'data-card__title'}>Offer Letter Signed Successfully</h4>

      <p className="center-text">
        We have received your Signed offer letter. <br />
        Thank you.
      </p>
    </Popup>
  );
};

export default OfferLetterSigned;
