import React from 'react';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import { SINGLE_APPLICATION_QUERY } from './queries';
import { Loader } from '../../../components';
import { useRouterQuery } from '../../../hooks';

const ApplicationDetails = ({ children }) => {
  const query = useRouterQuery();
  const applicationNumber = query.get('applicationNumber');

  return (
    <Query
      query={SINGLE_APPLICATION_QUERY}
      variables={{ applicationNumber: applicationNumber }}
    >
      {({ loading, error, data, refetch }) => {
        const props = {
          error,
          loading,
          refetch,
          amount: '',
          status: {},
          policy: {},
          moratoriumData: {},
          createdAt: '',
          fullAmount: '',
          applicationNumber: '',
          dateOfRepayment: '',
          rejectReason: '',
          reviewFeedbacks: [],

          portfolio: {
            amountPaid: '',
            fullAmount: '',
            status: { name: '' },
            dateOfRepayment: '',
            createdAt: '',
            amountDisbursed: '',
            lateRepaymentFee: '',
          },
        };

        if (loading) {
          return <Loader />;
        }

        if (error) {
          return children(props);
        }

        if (!loading && !error && data) {
          const { application } = data;

          props.application = application;
          props.amount = application.amount;
          props.status = application.status;
          props.policy = application.policy;
          props.createdAt = application.createdAt;
          props.fullAmount = application.fullAmount;
          props.applicationNumber = application.applicationNumber;
          props.portfolio = application.portfolio;
          props.dateOfRepayment = application.dateOfRepayment;
          props.rejectReason = application.rejectReason;
          props.chargesAmount = application.chargesAmount;
          props.reviewFeedbacks = application.reviewFeedbacks;
          props.moratoriumData = application.moratoriumData;
        }

        return children(props);
      }}
    </Query>
  );
};

export default withRouter(ApplicationDetails);
