import { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { handleGraphQLErrors } from '../lib/utils';
import { useMutation } from '@apollo/client';

export const UPLOAD_BANK_STATEMENT = gql`
  mutation UploadBankStatement(
    $file: Upload!
    $password: String
    $bankId: String!
    $applicationId: ID!
  ) {
    uploadBankStatement(
      input: {
        file: $file
        password: $password
        bankId: $bankId
        applicationId: $applicationId
      }
    ) {
      filename
      mimetype
      encoding
    }
  }
`;

const useBankStatementUploader = () => {
  const [uploadBankStatementError, setUploadBankStatementError] =
    useState(null);
  const [mutate, { data, loading: uploadBankStatementLoading }] = useMutation(
    UPLOAD_BANK_STATEMENT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setUploadBankStatementError(
          errorMessage ||
            'Unable to upload file. Check file format and try again later.',
        );
      },
    },
  );

  const uploadBankStatement = useCallback(
    (bankStatement, bankId, documentPassword, applicationId) => {
      if (!bankStatement) {
        setUploadBankStatementError('Please select a pdf file for upload');
      } else {
        const { validity, file } = bankStatement;
        validity &&
          mutate({
            variables: {
              file,
              password: documentPassword,
              bankId,
              applicationId,
            },
          });
      }
    },
    [],
  );

  useEffect(() => {
    if (uploadBankStatementError) {
      setTimeout(() => setUploadBankStatementError(''), 4500);
    }
  }, [uploadBankStatementError]);

  return {
    data,
    uploadBankStatement,
    uploadBankStatementLoading,
    uploadBankStatementError,
    setUploadBankStatementError,
  };
};

export default useBankStatementUploader;
