import React from 'react';
import './_Layouts.scss';

const MerchantNotFound = () => (
  <section className="error-layouts">
    <section className="error-wrapper">
      <ErrorIcon />

      <section className="error-message">
        <h3>Service temporarily suspended.</h3>

        <p>
          We are currently unable to process your request. Feel free to reach
          out via email or social media.
        </p>
      </section>
    </section>
  </section>
);

export default MerchantNotFound;

export const Logo = () => (
  <svg width={155} height={32} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.923 31.845c8.793 0 15.922-7.129 15.922-15.922C31.845 7.129 24.716 0 15.923 0 7.129 0 0 7.129 0 15.923c0 8.793 7.129 15.922 15.923 15.922z"
      fill="#07F"
    />
    <path
      d="M53.447 26.55l-4.033-6.83c1.936-.458 3.926-2.152 3.926-5.324 0-3.335-2.286-5.782-6.023-5.782h-8.39V26.55h3.819v-6.372h2.796l3.523 6.372h4.383zm-6.695-9.735h-4.006v-4.84h4.006c1.533 0 2.69.915 2.69 2.42 0 1.506-1.157 2.42-2.69 2.42zM60.024 26.55V8.613h-3.818V26.55h3.818zM72.27 26.899c3.253 0 5.808-1.345 7.636-3.388v-6.884h-8.55v3.335h4.732v2.15c-.726.673-2.232 1.372-3.818 1.372-3.254 0-5.62-2.5-5.62-5.889 0-3.388 2.366-5.888 5.62-5.888 1.909 0 3.442 1.075 4.248 2.339l3.173-1.721c-1.344-2.124-3.684-4.006-7.421-4.006-5.244 0-9.546 3.603-9.546 9.276 0 5.647 4.302 9.304 9.546 9.304zM86.756 26.55V8.613h-3.819V26.55h3.819zM106.369 26.55V8.613h-3.818v11.267L94.242 8.614h-3.926V26.55h3.818V14.853l8.551 11.696h3.684zM126.63 26.55l-6.91-17.936h-4.786l-6.938 17.935h4.356l1.13-3.038h7.69l1.102 3.038h4.356zm-6.507-6.4h-5.593l2.797-7.717 2.796 7.717zM134.54 26.55V11.974h5.216v-3.36h-14.305v3.36h5.243V26.55h3.846zM154.895 26.55v-3.362h-8.873v-4.06h8.685v-3.361h-8.685v-3.791h8.873V8.614h-12.691V26.55h12.691z"
      fill="#06192F"
    />
    <path
      d="M16.234 27.31c1.323 0 2.436-1.112 2.436-2.435 0-1.324-1.113-2.436-2.436-2.436s-2.436 1.112-2.436 2.436c0 1.323 1.113 2.436 2.436 2.436z"
      fill="#fff"
    />
  </svg>
);

const ErrorIcon = props => (
  <svg viewBox="0 0 150 120" width={150} height={120} {...props}>
    <g clipPath="url(#a)">
      <path
        d="M112.347 23.89c0 12.548-9.979 22.925-22.392 23.649h-1.46c-4.138 0-8.276-1.207-11.684-3.138-7.302-4.102-12.17-11.824-12.17-20.752 0-6.033 2.191-11.583 6.085-15.686C75.109 3.137 81.436 0 88.495 0c9.25 0 17.282 5.309 21.176 13.03 1.704 3.38 2.677 6.999 2.677 10.86Z"
        fill="#C0C9CC"
      />
      <path
        opacity={0.1}
        d="M112.138 23.887c0 12.548-9.979 22.925-22.392 23.648-4.625-.723-9.006-1.93-13.144-2.895-7.302-4.103-12.17-11.825-12.17-20.753 0-6.033 2.191-11.583 6.085-15.686 8.033-.24 17.282.483 26.774 2.172 4.381.724 8.519 1.69 12.413 2.655 1.461 3.378 2.434 6.998 2.434 10.859Z"
        fill="#697275"
      />
      <path
        d="M141.241 39.862c1.837-10.235-20.813-22.79-50.59-28.044-29.776-5.253-55.404-1.216-57.241 9.019C31.573 31.07 54.223 43.627 84 48.88c29.776 5.253 55.404 1.216 57.241-9.018Z"
        fill="#DAE1E3"
      />
      <path
        d="M83.061 17.658c.135-1.728-2.58-3.346-6.064-3.614-3.484-.27-6.42.913-6.555 2.64-.135 1.728 2.58 3.346 6.065 3.615 3.484.268 6.419-.914 6.554-2.641Zm-30.627 4.438c3.35-.986 5.664-3.132 5.166-4.793-.497-1.66-3.616-2.208-6.967-1.221-3.35.986-5.663 3.132-5.165 4.792.497 1.661 3.616 2.208 6.966 1.222Zm55.242 1.913c.306-1.706-2.235-3.581-5.676-4.188-3.44-.607-6.478.283-6.785 1.989-.306 1.706 2.235 3.58 5.676 4.188 3.441.607 6.479-.284 6.785-1.99Zm18.477 12.618c.801-1.54-1.068-4.076-4.174-5.664-3.106-1.588-6.274-1.627-7.075-.087-.801 1.54 1.068 4.075 4.175 5.663 3.106 1.589 6.274 1.628 7.074.088Z"
        fill="#fff"
      />
      <path
        d="m84.29 32.53-22.879-.24-32.583 60.603 91.453 9.618-10.191-61.776-25.8-8.205Z"
        fill="url(#b)"
      />
      <path
        opacity={0.5}
        d="M132.236 118.636H15.162c3.164-2.836 7.789-5.672 13.143-7.799 2.921-1.063 6.329-2.481 9.736-3.19 10.223-2.836 22.636-4.608 35.78-4.608 15.82 0 30.424 2.481 41.62 6.381 3.895 1.417 7.302 2.835 10.223 4.608 2.677 1.418 4.868 2.835 6.572 4.608Z"
        fill="url(#c)"
      />
      <path
        opacity={0.3}
        d="M39.662 109.367c.153 1.439-2.356 3.163-5.26 3.472-2.904.309-5.452-.633-5.848-2.047 2.828-1.029 6.114-2.35 9.451-3.19 1.02.376 1.58 1.045 1.657 1.765Zm20.528-1.02c3.899 0 7.059-.756 7.059-1.689s-3.16-1.689-7.059-1.689c-3.898 0-7.058.756-7.058 1.689s3.16 1.689 7.058 1.689Zm38.047-.135c.08-1.197-3.008-2.375-6.898-2.63-3.89-.256-7.108.507-7.188 1.704-.08 1.197 3.008 2.374 6.898 2.63 3.89.255 7.108-.508 7.188-1.704Zm26.797 5.437c-.967-.112-2.128-.075-3.551-.482-3.841-.686-6.846-2.247-6.708-3.445.027-.24.027-.24.297-.452 3.758 1.405 7.174 2.764 9.962 4.379Z"
        fill="#98A5A9"
      />
      <path
        d="M109.945 41.357c.824-4.593-9.45-10.246-22.949-12.628-13.499-2.381-25.11-.59-25.934 4.003-.824 4.592 9.45 10.246 22.949 12.627 13.499 2.382 25.11.59 25.934-4.002Z"
        fill="url(#d)"
      />
      <path
        d="M22.542 63.602s-8.408 2.134-5.86 3.842c2.548 1.622 8.578 4.44 14.268 2.562 5.69-1.879 0-4.526.084-4.526.085 0-8.492-1.878-8.492-1.878Z"
        fill="#C0C9CC"
      />
      <path
        d="M12.266 62.916c3.057.94 12.484-.598 18.513-4.525 4.756 5.891 6.54 7.428 8.238 7.6-2.633 2.646-11.38 6.915-16.306 1.365-5.774 3.672-10.445-4.44-10.445-4.44Zm100.984 7.69c3.227.17 11.975-3.757 16.73-9.051 6.115 4.525 8.153 5.55 9.937 5.294-1.954 3.244-7.898 10.331-16.306 10.844-6.115-3.586-10.361-7.087-10.361-7.087Z"
        fill="#E1EAED"
      />
      <path
        d="M85.282 70.706c-.638-3.168-6.621-10.66-12.933-13.948 2.86-7.05 3.344-9.279 2.652-10.942 3.63 1.084 11.974 5.076 14.564 13.09-1.95 6.816-4.283 11.8-4.283 11.8Z"
        fill="#fff"
      />
      <path
        d="M148.152 118.641H2"
        stroke="#D5DAE8"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.026 99.254v4.826h-2.921v4.585h-5.598v-4.585h-12.17v-5.55l11.196-15.444h6.572v16.168h2.92ZM48.75 89.36l-7.058 9.894h7.058V89.36Zm25.8 19.786c-5.841 0-10.952-5.068-10.952-13.272 0-8.205 5.111-13.272 10.953-13.272 5.841 0 10.952 5.067 10.952 13.272.244 7.963-4.868 13.272-10.953 13.272Zm0-5.068c2.678 0 5.599-2.654 5.599-8.446 0-5.55-2.678-8.446-5.599-8.446-2.677 0-5.598 2.655-5.598 8.446.244 5.792 2.921 8.446 5.599 8.446Zm38.7-4.824v4.826h-2.921v4.585h-5.598v-4.585h-12.17v-5.55l11.197-15.444h6.328v16.168h3.164Zm-8.519-9.894-7.058 9.894h7.058V89.36Z"
        fill="#C0C9CC"
      />
      <path
        d="M54.835 99.254v4.826h-2.92v4.585h-5.599v-4.585h-12.17v-5.55l11.197-15.444h6.328v16.168h3.164ZM46.56 89.36l-7.06 9.894h7.059V89.36Z"
        fill="url(#e)"
      />
      <path
        d="M72.36 109.146c-5.841 0-10.953-5.068-10.953-13.272 0-8.205 5.112-13.272 10.953-13.272 5.842 0 10.953 5.067 10.953 13.272.243 7.963-4.868 13.272-10.953 13.272Zm0-5.068c2.677 0 5.598-2.654 5.598-8.446 0-5.55-2.677-8.446-5.598-8.446-2.677 0-5.598 2.655-5.598 8.446.243 5.792 2.92 8.446 5.598 8.446Z"
        fill="url(#f)"
      />
      <path
        d="M111.06 99.254v4.826h-2.921v4.585h-5.598v-4.585h-12.17v-5.55l11.196-15.444h6.329v16.168h3.164Zm-8.519-9.894-7.059 9.894h7.059V89.36Z"
        fill="url(#g)"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={74.561}
        y1={34.728}
        x2={74.561}
        y2={74.143}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C0C9CC" />
        <stop offset={1} stopColor="#E1EAED" stopOpacity={0.11} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={73.699}
        y1={103.039}
        x2={73.699}
        y2={118.636}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C0C9CC" />
        <stop offset={1} stopColor="#C0C9CC" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={85.504}
        y1={43.462}
        x2={89.106}
        y2={31.027}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={44.491}
        y1={83.086}
        x2={44.491}
        y2={108.665}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={72.364}
        y1={82.602}
        x2={72.364}
        y2={109.146}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={100.715}
        y1={83.086}
        x2={100.715}
        y2={108.665}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" transform="translate(1)" d="M0 0h149v120H0z" />
      </clipPath>
    </defs>
  </svg>
);
