import { useState } from 'react';

export const useFormInput = (initialValue = '', opts = {}) => {
  const { type, errorMessage: initialErrorMessage } = opts;
  const [value, setValue] = useState(initialValue);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  let input = {
    onChange: e => setValue(e.target.value),
    value,
    errorMessage,
  };

  if (type === 'checkbox') {
    input = {
      onChange: e => setValue(e.target.checked),
      checked: value,
      errorMessage,
    };
  }

  if (type === 'radio') {
    input = optionValue => ({
      value: optionValue,
      onChange: e => setValue(e.target.value),
      defaultChecked: opts.isDefaultChecked(optionValue, initialValue),
    });
  }

  return {
    input,
    value,
    setValue,
    initialValue,
    setErrorMessage,
    opts,
  };
};

export const resetInputs = (inputs = []) => {
  inputs.forEach(input => {
    input.setValue(input.initialValue || '');
    input.setErrorMessage(input.opts.errorMessage);
  });
};

export const useFlashMessage = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);

  const flashError = (newValue, duration = 3000) => {
    setValue(newValue);
    setTimeout(() => setValue(''), duration);
  };

  return [value, flashError, setValue];
};
