import React from 'react';
import { DataCard } from '../../../components';
import { FailureIcon } from '../../../components/Icon';
import * as S from './styled';

const FailurePage = ({ history, location }) => {
  const { state } = location;

  return (
    <S.Wrapper>
      <DataCard>
        <S.Title>
          {(state && state.message) ||
            'We are sorry, we don’t have a loan for you yet. Please try again later.'}
        </S.Title>
        <S.FailureIcon>
          <FailureIcon />
        </S.FailureIcon>
        <S.NextButton
          type="button"
          value="Go to your Dashboard"
          onClick={() => history.push('/dashboard')}
        />
      </DataCard>
    </S.Wrapper>
  );
};

export default FailurePage;
