import styled from 'styled-components';
import {
  NextButton as Next,
  Aside as Asi,
  PreviousButton as PreBtn,
} from '../styled';
export {
  Title,
  Wrapper,
  Subtitle,
  PreviousButton,
  TwoColumn,
  SkipButton,
} from '../styled';

export const NextButton = styled(Next)`
  margin-top: 0;
  cursor: pointer !important;
`;

export const Aside = styled(Asi)`
  color: rgb(33 150 83);
  text-align: center;
  margin-bottom: 2rem;
`;

export const PrevBtn = styled(PreBtn)`
  margin-top: 0;
`;
