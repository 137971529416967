import React, { useEffect } from 'react';
import './_Layouts.scss';
import { Header, Footer, Page } from '../index';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import {
  SignIn,
  ResetPassword,
  TriggerResetPassword,
  ResetPasswordConfirmation,
  PasswordResetComplete,
} from '../../containers';
import { Favicons } from '../Icon';
import config from '../../config/config';
import { useApolloClient } from '@apollo/client';
import JwtDecode from 'jwt-decode';
import { authGetter } from '../../lib/utils';
import styled from 'styled-components';
import { SignUp } from '../../v1';

const StyledLayout = styled.section`
  &.unauthenticated {
    background: ${props => props.theme.primaryColor};
  }

  a.button {
    background: ${props => props.theme.secondaryColor};

    &.secondary {
      background: #ffffff;
      color: ${props => props.theme.primaryColor};

      .icon {
        svg {
          fill: ${props => props.theme.primaryColor};
        }
      }
    }
  }

  .repayment {
    .repayment__title {
      color: ${props => props.theme.primaryColor};
    }
  }

  .sign-in {
    .data-card {
      .create-account {
        a {
          color: ${props => props.theme.primaryColor};
        }
      }
    }
  }

  *::-moz-selection {
    background-color: ${props => props.theme.primaryColor};
  }

  *::selection {
    background-color: ${props => props.theme.primaryColor};
  }

  .sign-up,
  .new-loan {
    .loan-confirmation {
      h6 {
        a {
          color: ${props => props.theme.primaryColor};
        }
      }
    }

    .repaymentBreakdown {
      border-right-color: ${props => props.theme.primaryColor};
      border-left-color: ${props => props.theme.primaryColor};

      &__header {
        border-top-color: ${props => props.theme.primaryColor};
        border-bottom-color: ${props => props.theme.primaryColor};
      }
    }
  }

  .image-upload {
    border-color: ${props => props.theme.secondaryColor};
  }

  .document-upload {
    border-color: ${props => props.theme.secondaryColor};
  }

  .resend-otp,
  .resend-verification {
    color: ${props => props.theme.secondaryColor};
  }

  .new-loan {
    &-accordion {
      .rc-collapse {
        &-item {
          .button {
            &.secondary {
              color: ${props => props.theme.secondaryColor};
            }
          }
        }

        &-header {
          &:before {
            border: solid ${props => props.theme.secondaryColor};
          }

          &.confirmed {
            &:after {
              border-color: ${props => props.theme.secondaryColor};
              color: ${props => props.theme.secondaryColor};
            }
          }

          &[aria-expanded='true'] {
            color: ${props => props.theme.secondaryColor};
          }
        }
      }
    }
  }
`;

const Layout = ({ match, location }) => {
  const { path } = match;
  const authentication = authGetter();

  const client = useApolloClient();

  const checkExpiry = async () => {
    if (
      config.web.node_env === 'staging' ||
      config.web.node_env === 'production'
    ) {
      if (window.location.protocol !== 'https:') {
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length,
        )}`;
      }
    }

    if (authentication.hasApiKey) {
      const expiryDate = JwtDecode(authentication.apiKey).exp;
      const currentTime = new Date().getTime() / 1000;

      if (currentTime > expiryDate) {
        window.location.href = '/';
        localStorage.clear();
        await client.resetStore();
      }
    }
  };

  useEffect(() => {
    checkExpiry();
  });

  return (
    <StyledLayout
      className={`layout ${
        path === '/v1/sign-up'
          ? 'unauthenticated'
          : authentication.authenticationType
      }`}
    >
      <div className="background-glow">
        <span className="glow-1" />
        <span className="glow-2" />
        <span className="glow-3" />
        <span className="glow-4" />
        <span className="glow-5" />
        <span className="glow-6" />
        <span className="glow-7" />
        <span className="glow-8" />
        <span className="glow-9" />
      </div>
      <Favicons />
      <Header authentication={authentication} />
      <main className="main">
        <Switch>
          <Route
            path="/v1/sign-up"
            render={props => (
              <SignUp {...props} locationState={location.state} />
            )}
          />

          <Redirect from={'/sign-up'} to={'/v1/sign-up'} />

          <Route
            path="/forgot-password"
            exact
            render={props => (
              <Page
                {...props}
                component={TriggerResetPassword}
                title="Forgot Password"
              />
            )}
          />

          <Route
            path="/reset-password-confirmation"
            exact
            render={props => (
              <Page
                {...props}
                component={ResetPasswordConfirmation}
                title="Reset Password Confirmation"
              />
            )}
          />

          <Route
            path="/reset-password/:resetToken"
            exact
            render={props => (
              <Page
                {...props}
                component={ResetPassword}
                title="Reset Password Confirmation"
              />
            )}
          />

          <Route
            path="/password-reset-complete"
            exact
            render={props => (
              <Page
                {...props}
                component={PasswordResetComplete}
                title="Reset Password Complete"
              />
            )}
          />

          <Route
            path="/sign-in"
            exact
            render={props => (
              <Page {...props} component={SignIn} title="Sign In" />
            )}
          />

          <Redirect
            to={{
              pathname: '/sign-in',
              state: location.state,
            }}
          />
        </Switch>
      </main>
      <Footer />
    </StyledLayout>
  );
};

export default withRouter(Layout);
