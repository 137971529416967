import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { UPDATE_PASSWORD_MUTATION } from './queries';
import { resetInputs, useFormInput, useFlashMessage } from '../../Form';
import { authTypes } from '../../../lib/constants';
import { validatePasswordStrength } from '../../../lib/utils';

const UpdatePasswordProvider = ({ children }) => {
  const inputs = {
    password: useFormInput(),
    newPassword: useFormInput(),
    confirmPassword: useFormInput(),
  };

  const [errorMessage, flashError, setErrorMessage] = useFlashMessage();
  const [successMessage, flashSuccess] = useFlashMessage();

  const changePassword = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');

    if (inputs.password.value && inputs.newPassword.value) {
      if (inputs.newPassword.value === inputs.confirmPassword.value) {
        if (validatePasswordStrength(inputs.newPassword.value)) {
          runMutation({
            variables: {
              input: {
                oldPassword: inputs.password.value,
                newPassword: inputs.newPassword.value,
              },
            },
          });
        } else {
          flashError(
            'Password must be at least 6 characters and must contain a digit, a lowercase character and an uppercase character',
          );
        }
      } else {
        flashError('Passwords do not match');
      }
    } else {
      flashError('You have not made any changes');
    }
  };

  return (
    <Mutation
      mutation={UPDATE_PASSWORD_MUTATION}
      onCompleted={({ changePassword: { token } }) => {
        if (token) {
          resetInputs(Object.values(inputs));
          flashSuccess('Your password has been updated');
          // update authtoken

          let authCreds = {
            apiKey: token,
            keyType: authTypes.FIXED,
          };

          localStorage.setItem('Auth', JSON.stringify(authCreds));
        }
      }}
      onError={error => {
        if (error.graphQLErrors.length === 0)
          flashError('Something Went Wrong: Try again later');

        error.graphQLErrors.forEach(error => {
          switch (error.message) {
            case 'cannot set new password to old one':
              flashError('Cannot reuse old password');
              break;
            case 'Invalid password':
              flashError('The password you supplied is incorrect');
              break;
            default:
              flashError('Something Went Wrong: Try Again Later');
          }
        });
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: e => changePassword(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs,
        })
      }
    </Mutation>
  );
};

export default UpdatePasswordProvider;
