import { ApolloProvider, ApolloClient, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import React from 'react';

const config = require('../config/config');

const httpLink = createUploadLink({
  uri: config.ignite.api_url,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const _headers = {
    ...headers,
    'client-name': 'OriginateCP [web]',
    'client-version': '1.0.0',
  };

  const authCreds = JSON.parse(localStorage.getItem('Auth'));
  if (authCreds != null && typeof authCreds !== 'undefined') {
    _headers['Authorization'] = `Bearer ${authCreds.apiKey}`;
  }

  return {
    headers: _headers,
  };
});

const link = split(({ query }) => {
  const { kind } = getMainDefinition(query);
  return kind === 'OperationDefinition';
}, authLink.concat(httpLink));

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const ApolloWrapper = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
