import React from 'react';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { DataCard } from '../../../components';
import {
  Input,
  Select,
  SubmitButton,
} from '../../../components/FormikElements';
import { AddressDetailsSchema } from '../../../lib/validationSchemas';
import useHomeAddressForm from './useHomeAddressForm';
import { getLocalGovernmentArea, populateStates } from '../../../lib/utils';

const HomeAddressScreen = () => {
  const {
    applicationLoading,
    loanApplicationIsLoading,
    currentAddress,
    addHomeAddress,
    userLoading,
  } = useHomeAddressForm();

  return (
    <S.Wrapper>
      <DataCard
        loading={applicationLoading || userLoading || loanApplicationIsLoading}
      >
        <S.Title>Where do you live?</S.Title>
        <Formik
          initialValues={{
            address: currentAddress?.address,
            state: currentAddress?.state,
            localGovernment: currentAddress?.localGovernment,
          }}
          validationSchema={AddressDetailsSchema}
          onSubmit={values => {
            addHomeAddress(values);
          }}
        >
          {({ values }) => (
            <Form>
              <Input name="address" placeholder="Home Address" />

              <S.TwoColumn>
                <Select name="state">
                  <option value="">--Select State--</option>
                  {populateStates()}
                </Select>

                <Select name="localGovernment" disabled={!values.state}>
                  <option value="">--Select Local Government--</option>
                  {values.state && getLocalGovernmentArea(values.state)}
                </Select>
              </S.TwoColumn>
              <SubmitButton value="Next" />
            </Form>
          )}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default HomeAddressScreen;
