import React from 'react';
import PropTypes from 'prop-types';
import './_Popup.scss';
import { DataCard } from '../index';

const Popup = ({
  classes,
  title,
  children,
  loading,
  closeButton,
  handleCloseButton,
}) => {
  return (
    <section className={`popup ${classes == null ? '' : classes}`}>
      <div className="popup__content">
        <DataCard
          loading={loading}
          title={title}
          closeButton={closeButton}
          handleCloseButton={handleCloseButton}
        >
          {children}
        </DataCard>
      </div>
    </section>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
  loading: PropTypes.bool,
  closeButton: PropTypes.bool,
  handleCloseButton: PropTypes.func,
};

export default Popup;
