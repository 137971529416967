import React from 'react';
import { BvnValidator } from '../../components/BvnValidator';
import PopupWithoutDataCard from '../../components/Popup/PopUpWithoutDataCard';

const UpdateBvnData = ({
  updateBvnVerificationState,
  setUpdateBvnVerificationState,
}) => {
  return (
    <>
      <PopupWithoutDataCard classes="close">
        <BvnValidator
          updateBvnVerificationState={updateBvnVerificationState}
          setUpdateBvnVerificationState={setUpdateBvnVerificationState}
        />
      </PopupWithoutDataCard>
    </>
  );
};

export default UpdateBvnData;
