import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { useFormState } from 'react-use-form-state';
import {
  Alert,
  Button,
  DataCard,
  FormGroup,
  Input,
  Loader,
  Notification,
  Select,
} from '../../components';
import { CheckIcon, NairaAmount, SadSmiley } from '../../components/Icon';
import { logEvent } from '../../lib/GAHelper';
import { bytesToSize, numberWithCommas, removeCommas } from '../../lib/utils';
import {
  GetBanksProvider,
  NotifyOfflineRepaymentProvider,
  UploadSupportingDocumentProvider,
  ViewerProvider,
} from '../providers';
import './_OfflineRepayment.scss';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { useClientInfoContext } from '../../hooks';

const StyledAccountDetail = styled.div`
  &.offline-repayment-account {
    background: ${props => transparentize(0.95, props.theme.secondaryColor)};
    border-color: ${props => props.theme.secondaryColor};
  }
`;

const OfflineRepayment = props => (
  <ViewerProvider>
    {({ currentLoan, user }) => (
      <UploadSupportingDocumentProvider>
        {({ uploadSupportingDocumentFile }) => (
          <NotifyOfflineRepaymentProvider>
            {({ notifyOfflineRepayment }) => {
              return (
                <OfflineRepaymentWrapper
                  {...props}
                  sendNotification={notifyOfflineRepayment}
                  currentLoan={currentLoan}
                  user={user}
                  uploadSupportingDocumentFile={uploadSupportingDocumentFile}
                />
              );
            }}
          </NotifyOfflineRepaymentProvider>
        )}
      </UploadSupportingDocumentProvider>
    )}
  </ViewerProvider>
);

const OfflineRepaymentWrapper = ({
  sendNotification,
  currentLoan,
  uploadSupportingDocumentFile,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [documentDetails, setDocumentDetails] = useState('');
  const [selectedDocument, setSelectedDocument] = useState('');
  const [formState, { text, select }] = useFormState({
    portfolioId: currentLoan.id,
  });
  const {
    clientInfo: { bankDetails },
  } = useClientInfoContext();

  const pendingOfflineRepayment =
    (currentLoan &&
      currentLoan.offlineRepayments &&
      currentLoan.offlineRepayments.find(
        repayment => repayment.status === 'PENDING',
      )) ||
    {};

  const nextInstallment =
    (currentLoan &&
      currentLoan.repayments &&
      currentLoan.repayments.find(
        repayment => repayment.status.name === 'PENDING',
      )) ||
    {};

  const handleDocumentUpload = e => {
    setLoading(true);
    const { files, validity } = e.target;
    const file = files[0];

    if (file) {
      setDocumentDetails({
        fileName: file.name,
        fileSize: bytesToSize(file.size),
      });
    } else {
      setDocumentDetails('');
    }

    setLoading(false);

    if (file) {
      setSelectedDocument({ file, validity: validity });
    }
  };

  const uploadSupportingDocument = async () => {
    const uploadError = 'Unable to upload supporting document';
    logEvent('Offline Repayment');
    setError('');

    setLoading(true);
    const response = await uploadSupportingDocumentFile(
      selectedDocument,
      `Offline Payment Document ${currentLoan.portfolioNumber}`,
      user.id,
    );
    setLoading(false);

    if (response == null || typeof response === 'undefined') {
      setError({ uploadError });
      return;
    }

    if (response && response.data.errors) {
      setError({ uploadError });
      return;
    }

    const { uploadSupportingDocument } = response.data;

    if (!uploadSupportingDocument) {
      setError({ uploadError });
      return;
    }

    return uploadSupportingDocument.id;
  };

  const handleOfflineRepayment = async e => {
    e.preventDefault();
    e.stopPropagation();
    const notifyError =
      'There was an error notifying offline repayment. Please, try again later.';
    const documentError = 'Please upload an evidence of payment.';

    const supportingDocument = [];

    if (selectedDocument) {
      const uploadedDocumentId = await uploadSupportingDocument();

      if (!uploadedDocumentId) {
        return;
      }
      supportingDocument.push(uploadedDocumentId);
    }

    if (supportingDocument.length === 0) {
      setError({ documentError });
      return;
    } else {
      formState.values.supportingDocuments = supportingDocument;
    }

    setLoading(true);
    const response = await sendNotification(formState.values);
    setLoading(false);

    if (response === null) {
      setError({ notifyError });
      return;
    }

    if (response.data.errors) {
      setError({ notifyError });
      return;
    }

    const { notifyOfflineRepayment } = response.data;

    if (!notifyOfflineRepayment) {
      setError({ notifyError });
      return;
    }

    setSuccess({ notifyError });
  };

  return (
    <Fragment>
      {currentLoan.portfolioNumber ? (
        pendingOfflineRepayment.status === 'PENDING' ? (
          <section className="container no-loan">
            <DataCard>
              <div className="smiley-holder">
                <SadSmiley />
              </div>
              <h3 className="center-text">
                Sorry, you can not notify offline repayment at this time because
                you have a pending offline repayment. You will be notified once
                the repayment is approved or declined.
              </h3>

              <Button
                click_event={() => {
                  window.location.href = '/dashboard';
                }}
              >
                Go to your Dashboard
              </Button>
            </DataCard>
          </section>
        ) : (
          <section className="container offline-repayment">
            {loading && <Loader />}
            {!success ? (
              <DataCard>
                <form onSubmit={handleOfflineRepayment}>
                  <h3 className="center-text">Make Offline Repayment</h3>

                  {error.notifyError && (
                    <Alert classes="error">
                      There was an error notifying offline repayment. Please,
                      try again later.
                    </Alert>
                  )}

                  <StyledAccountDetail className="offline-repayment-account">
                    <p>Please, ensure that payment was made to:</p>
                    {bankDetails ? (
                      <ul>
                        <li>
                          BANK NAME: <span>{bankDetails.bank.name}</span>
                        </li>
                        <li>
                          ACCOUNT NUMBER:{' '}
                          <span>{bankDetails.accountNumber}</span>
                        </li>
                        <li>
                          ACCOUNT NAME: <span>{bankDetails.accountName}</span>
                        </li>
                      </ul>
                    ) : (
                      ''
                    )}
                  </StyledAccountDetail>

                  <p className="lead-text bold-text">
                    Choose Offline Repayment Method
                  </p>

                  <FormGroup classes="margin">
                    <Select
                      classes="border-bottom"
                      {...select('service')}
                      required
                    >
                      <option disabled value="">
                        --Select Payment Method--
                      </option>
                      <option name="Transfer" value="Transfer">
                        Bank Transfer
                      </option>
                      <option name="Cheque" value="Cheque">
                        Bank Cheque
                      </option>
                      <option name="Deposit" value="Deposit">
                        Cash Deposit at Bank/Branch
                      </option>
                    </Select>
                  </FormGroup>

                  <p className="lead-text bold-text">
                    Enter Offline Repayment Details
                  </p>

                  <FormGroup>
                    <Input
                      type="tel"
                      amountField
                      placeholder="Amount Paid"
                      name="amountPaid"
                      classes={`border-bottom`}
                      {...text({
                        name: 'amount',
                        validate: value =>
                          nextInstallment
                            ? removeCommas(value) <=
                              nextInstallment.outstandingPayment
                            : removeCommas(value) <= currentLoan.fullAmount,
                        validateOnBlur: true,
                      })}
                      required
                      errorMessage={
                        formState.touched.amount && !formState.validity.amount
                          ? `Amount must not be more than <span>&#8358;</span> ${
                              nextInstallment
                                ? numberWithCommas(
                                    nextInstallment.outstandingPayment,
                                  )
                                : numberWithCommas(currentLoan.fullAmount)
                            }`
                          : ''
                      }
                    />
                  </FormGroup>

                  <GetBanksProvider>
                    {({ banks }) => (
                      <FormGroup>
                        <Select
                          classes="border-bottom"
                          {...select('bankId')}
                          required
                        >
                          <option disabled value="">
                            --Select Bank--
                          </option>
                          {banks &&
                            banks.map((bank, index) => (
                              <option value={bank.id} key={index}>
                                {bank.name}
                              </option>
                            ))}
                        </Select>
                      </FormGroup>
                    )}
                  </GetBanksProvider>

                  {formState.values.service === 'Cheque' && (
                    <FormGroup>
                      <Input
                        placeholder="Cheque Number"
                        classes={`border-bottom`}
                        {...text('chequeNo')}
                        required
                      />
                    </FormGroup>
                  )}

                  {formState.values.service === 'Deposit' && (
                    <Fragment>
                      <FormGroup>
                        <Input
                          placeholder="Bank Branch"
                          classes={`border-bottom`}
                          {...text('bankBranch')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Input
                          placeholder="Teller Number"
                          classes={`border-bottom`}
                          {...text('tellerNo')}
                          required
                        />
                      </FormGroup>
                    </Fragment>
                  )}

                  {formState.values.service === 'Transfer' && (
                    <FormGroup classes="">
                      <Input
                        placeholder="Account Name on Transfer"
                        classes="border-bottom"
                        type="text"
                        {...text('accountName')}
                        required
                      />
                    </FormGroup>
                  )}

                  <p className="lead-text bold-text">
                    Upload an evidence of payment
                  </p>

                  <label className="document-upload">
                    <Input
                      name="supportingDocument"
                      type="file"
                      classes={`border-bottom`}
                      onChange={handleDocumentUpload}
                      accept="application/pdf,image/jpeg,image/png, application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />

                    <section className="document-upload__label">
                      <div>
                        <span>
                          {documentDetails
                            ? `${documentDetails.fileName} (${documentDetails.fileSize})`
                            : 'Click here to upload file'}
                        </span>
                      </div>
                    </section>
                  </label>
                  {error.documentError && (
                    <Alert classes="error">{error.documentError}</Alert>
                  )}

                  <Button
                    type="submit"
                    classes={`center ${loading ? 'loading' : ''}`}
                    disabled={
                      formState.touched.amount && !formState.validity.amount
                    }
                  >
                    Notify Us
                  </Button>
                </form>
              </DataCard>
            ) : (
              <Notification
                icon={<CheckIcon />}
                title="Payment Notification Sent"
              >
                <p>
                  You have sent a payment notification of
                  <span className="bold-text">
                    <NairaAmount amount={formState.values.amount} />
                  </span>
                  . We will verify and notify you.
                </p>

                <Button
                  classes="block"
                  click_event={() => {
                    window.location.href = '/dashboard';
                  }}
                >
                  Go to your Dashboard
                </Button>
              </Notification>
            )}
          </section>
        )
      ) : (
        <section className="container no-loan">
          <DataCard>
            <div className="smiley-holder">
              <SadSmiley />
            </div>
            <h3 className="center-text">
              You currently do not have an active loan.
            </h3>

            <Button
              click_event={() => {
                window.location.href = '/dashboard';
              }}
            >
              Go to your Dashboard
            </Button>
          </DataCard>
        </section>
      )}
    </Fragment>
  );
};

export default withRouter(OfflineRepayment);
