const axios = require('axios');

const config = require('../config/config');

const apiBaseUrl = config.ignite.base_url;

const getHeaders = (addContentType = true, ContentType = '') => {
  const headers = {};

  if (addContentType) {
    headers['Content-Type'] = ContentType || 'application/json';
  }

  const auth = JSON.parse(localStorage.getItem('Auth'));

  if (auth != null && typeof auth !== 'undefined') {
    headers['Authorization'] = `Bearer ${auth.apiKey}`;
  }

  return headers;
};

const graphQlRequest = (options = {}) => {
  const headers = getHeaders();
  const opts = Object.assign(headers, options);

  return axios.create({
    baseURL: apiBaseUrl,
    timeout: 120000,
    headers: opts,
  });
};

module.exports = graphQlRequest;
