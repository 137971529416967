import React from 'react';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import SelfiePlaceholder from '../../../static/images/selfie-placeholder.jpg';
import { SubmitButton } from '../../../components/FormikElements';
import useUploadSelfieForm from './useUploadSelfieForm';

const UploadSelfie = ({ customForm, handleNext, formIndex }) => {
  const {
    applicationLoading,
    uploadImageError,
    uploadImageLoading,
    handleSelfieUpload,
    handlePreview,
    passportDetails,
  } = useUploadSelfieForm({
    customForm,
    handleNext,
    formIndex,
  });

  return (
    <S.Wrapper>
      <DataCard loading={uploadImageLoading || applicationLoading}>
        <S.Title>Take a selfie or upload a recent passport photograph</S.Title>
        {uploadImageError && <Alert classes="error">{uploadImageError}</Alert>}
        <Formik initialValues={{}} onSubmit={() => handleSelfieUpload()}>
          <Form>
            <S.ImageUpload>
              <input
                name="selfie"
                type="file"
                accept="image/png, image/jpeg,application/pdf"
                onChange={handlePreview}
              />
              <section>
                <img
                  src={passportDetails.url || SelfiePlaceholder}
                  alt="selfie"
                />
              </section>
            </S.ImageUpload>
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default UploadSelfie;
