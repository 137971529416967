import React from 'react';
import './_DataCard.scss';
import PropTypes from 'prop-types';
import { CloseIcon2 } from '../Icon';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledDataCard = styled.article`
  &.data-card {
    &.loading {
      :after {
        border-color: ${props =>
          transparentize(0.75, props?.theme?.primaryColor)};
        border-right-color: ${props => props?.theme?.primaryColor};
      }
    }
  }
  .close {
    display: flex;
    justify-content: flex-end;
    div:hover {
      cursor: pointer;
    }
  }
`;

const DataCard = ({
  noPad,
  loading,
  title,
  closeButton,
  handleCloseButton,
  children,
}) => {
  return (
    <StyledDataCard
      className={`data-card ${noPad === true ? 'no-pad' : ''} ${
        loading === true ? 'loading' : ''
      }`}
    >
      {closeButton && (
        <div className="close">
          <div onClick={() => handleCloseButton()}>
            <CloseIcon2 />
          </div>
        </div>
      )}
      {title == null ? '' : <h4 className={'data-card__title'}>{title}</h4>}
      <div className={'data-card__content'}>{children}</div>
    </StyledDataCard>
  );
};

DataCard.propTypes = {
  title: PropTypes.string,
  noPad: PropTypes.bool,
  closeButton: PropTypes.bool,
  handleCloseButton: PropTypes.func,
};

export default DataCard;
