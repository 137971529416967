import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CONSENT_PROVIDERS } from '../../../containers/providers/OfferLetter/queries';
import {
  handleGraphQLErrors,
  getDisplayInterestRate,
} from '../../../lib/utils';
import { CloseIcon, NairaAmount } from '../../Icon';
import { Alert, Button, DocumentBox, Popup } from '../../index';

const ButtonHolder = styled.div`
  margin: 30px 0 0;
  text-align: center;

  .button {
    display: inline-block;
    margin: 0 0 20px;
    max-width: 370px;
    width: 100%;
    padding: 1rem 2rem;
  }

  .decline_button {
    border-color: #ae4036;
    color: #ae4036;

    &:hover {
      border-color: #ae4036;
      color: #ae4036;
    }
  }
`;

const OfferLetterOptions = ({
  application,
  document,
  popupToggle,
  setLoading,
}) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [consentProviders, setConsentProviders] = useState([]);

  const { loading: consentProvidersLoading } = useQuery(CONSENT_PROVIDERS, {
    onError(error) {
      setErrorMessage(handleGraphQLErrors(error));
      return;
    },
    onCompleted(data) {
      setConsentProviders(data.consentProviders);
    },
  });

  const handleConsentProviders = (e, value) => {
    e.preventDefault();

    if (value === 'DocuSign') {
      signWithDocuSign();
      return;
    } else if (value === 'OTP') {
      signWithOtp();
    }
  };

  const signWithDocuSign = () => {
    popupToggle('SignWithDocusign');
  };

  const signWithOtp = () => {
    popupToggle('SignWithOtp');
  };

  useEffect(() => {
    if (consentProvidersLoading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [consentProvidersLoading]);

  return (
    <Fragment>
      <Popup
        title="Sign your offer letter"
        classes="offer-letter-popup center-title"
      >
        <Button classes="close-popup" click_event={() => popupToggle('')}>
          <CloseIcon />
        </Button>
        {errorMessage ? <Alert classes="error">{errorMessage}</Alert> : ''}

        <p className="lead-text center-text">
          We require that you sign your offer letter in order to accept your
          loan offer.{' '}
          <span className="bold-text">
            Please read the offer letter carefully before signing.
          </span>
        </p>

        <section className="offer-letter-summary">
          <div className="offer-letter-summary__item">
            <span>Requested Amount</span>
            <span>
              <NairaAmount amount={application.amount} />
            </span>
          </div>

          <div className="offer-letter-summary__item">
            <span>Repayment Amount</span>
            <span>
              <NairaAmount amount={application.fullAmount} />
            </span>
          </div>

          {application.policy ? (
            <div className="offer-letter-summary__item">
              <span>Interest Rate</span>
              <span>{getDisplayInterestRate(application)}%</span>
            </div>
          ) : (
            ''
          )}

          <div className="offer-letter-summary__item">
            <span>Repayment Date</span>
            <span>{moment(application.dateOfRepayment).format('ll')}</span>
          </div>
        </section>

        <DocumentBox document={document} />

        {consentProviders ? (
          <ButtonHolder>
            {consentProviders.map((item, index) => (
              <Button
                key={index}
                value={item.name}
                click_event={e => handleConsentProviders(e, item.name)}
              >
                Sign with {item.name}
              </Button>
            ))}
            <Button
              classes="decline_button secondary"
              click_event={() => popupToggle('DeclineOfferLetter')}
            >
              Decline Offer Letter
            </Button>
          </ButtonHolder>
        ) : (
          <Alert />
        )}
      </Popup>
    </Fragment>
  );
};

export default OfferLetterOptions;
