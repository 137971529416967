import gql from "graphql-tag";

export const CUSTOMER_BVN_STATUS = gql`
  query (
    $bvn: String!
    $clientId: ID!
    $bvnDOB: DateTime
    $bvnPhoneDigits: String
  ) {
    customerBvnStatus(
      input: {
        bvn: $bvn
        clientId: $clientId
        bvnDOB: $bvnDOB
        bvnPhoneDigits: $bvnPhoneDigits
      }
    ) {
      hasCustomerAccount
      firstName
      lastName
    }
  }
`;

export const RESOLVE_ACCOUNT_NUMBER = gql`
  query ($bankId: ID!, $accountNumber: String!, $accountType: BankAccountType) {
    resolveAccountNumber(
      bankId: $bankId
      accountNumber: $accountNumber
      accountType: $accountType
    ) {
      accountName
      accountNumber
    }
  }
`;

export const GET_BANKS = gql`
  query {
    getBanks {
      id
      name
      code
    }
  }
`;

export const GET_ENUM = gql`
  query getEnum($type: Enum!) {
    enumValues(type: $type) {
      name
    }
  }
`;

export const CUSTOMER_ELIGIBILITY_AMOUNTS = gql`
  query customerAmountEligibility($categoryId: ID) {
    customerAmountEligibility(input: { categoryId: $categoryId }) {
      amount
      paid
    }
  }
`;

export const APPLICABLE_TENORS = gql`
  query (
    $clientId: ID!
    $customerPhoneNumber: String!
    $loanAmount: Float!
    $categoryId: ID
  ) {
    applicableTenors(
      input: {
        clientId: $clientId
        customerPhoneNumber: $customerPhoneNumber
        loanAmount: $loanAmount
        categoryId: $categoryId
      }
    ) {
      duration
      durationType
    }
  }
`;

export const GET_REPAYMENTBREAKDOWN_QUERY = gql`
  query GetRepaymentBreakdown(
    $policyId: ID!
    $principalAmount: Float!
    $duration: Int!
  ) {
    getRepaymentBreakdown(
      input: {
        policyId: $policyId
        principalAmount: $principalAmount
        duration: $duration
      }
    ) {
      principalAmount
      duration
      totalInterest
      rateInDecimal
      repaymentFrequency
      repaymentBreakdown {
        principalBalance
        expectedPayment
        interestPortion
        principalPortion
        endingBalance
        dueDate
      }
    }
  }
`;

export const RESOLVE_OKRA_BANK = gql`
  query ($okraBankId: String!) {
    resolveOkraBank(okraBankId: $okraBankId) {
      id
      okraSlug
      name
    }
  }
`;

export const CUSTOMER_REMITA_STATUS = gql`
  query customerRemitaStatus($clientId: ID, $customerPhoneNumber: String) {
    customerRemitaStatus(
      input: { clientId: $clientId, customerPhoneNumber: $customerPhoneNumber }
    ) {
      bankId
      customerId
      companyName
      hasRemitaData
      accountNumber
      countSalaryCredits
      salaryPaymentDetails {
        amount
        bankCode
        paymentDate
        accountNumber
      }
      loanHistoryDetails {
        loanAmount
        loanProvider
        repaymentFreq
        outstandingAmount
        loanDisbursementDate
      }
    }
  }
`;

export const VIEWER_QUERY = gql`
  query ViewerQuery {
    viewer {
      me {
        id
        firstName
        lastName
        email
        phone
        isBvnVerified
        isPhoneConfirmed
        isEmailConfirmed
        isWorkEmailConfirmed
        kycInformation
        bvnStatus {
          isVerified
          isMatchingPhone
          dateOfBirth
          phone
          bvn
        }
        userMetadata {
          id
          name
          value
        }
        supportingDocument {
          id
          documentName
          uploadType
        }
        socialMediaInformation {
          id
          provider
          profileId
          profileUrl
        }
      }
      account {
        id
        applications {
          nodes {
            id
            applicationNumber
            status {
              name
            }
          }
        }
        bankAccounts {
          id
          bank {
            id
            name
            code
            okraSlug
          }
        }
        cards {
          id
          expiryDate
        }
        contextStates {
          id
          state
          page
          context
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ONEPIPE_ACCOUNT = gql`
  query {
    getCustomerOnePipeAccount {
      id
      accountBanks {
        id
        accountBankId
        bankCode
        bankName
        maskedAccountNumber
      }
      accountCards {
        id
        identifier
        maskedPan
        requireCvv
        requireExpiry
        requirePin
        bankName
        brand
      }
      createdAt
    }
  }
`;

export const GET_LOAN_CATEGORIES = gql`
  query {
    getLoanCategories {
      categories {
        id
        name
        description
        minAmount
        maxAmount
        status
        products {
          id
          name
          description
          applicationForm
        }
        loanCategoryAttributes {
          attribute {
            data
            category
            default
            id
            input
            isOverwritable
            label
            name
            options
            required
            type
          }
          data
          value
          id
        }
      }
    }
  }
`;

