import React from 'react';

const NewFailureIcon = props => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={50} cy={50} r={50} fill="#F40A0A" fillOpacity={0.03} />
    <path
      d="M66.5 33.5l-33 33M33.5 33.5l33 33"
      stroke="#EB001B"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NewFailureIcon;
