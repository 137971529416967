import React from 'react';
import './_Layouts.scss';
import { Header, Footer, Page, Menu, NotFound } from '../index';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import {
  Settings,
  Dashboard,
  MyLoans,
  Application,
  LoanDetails,
  OfflineRepayment,
  MakeRepayment,
  CustomApplication,
} from '../../containers';
import { Favicons } from '../Icon';
import { authGetter } from '../../lib/utils';
import Breadcrumbs from '../BreadCrumbs/BreadCrumbs';
import Notifications from 'react-notify-toast';
import styled from 'styled-components';
import {
  LoanInitiationScreen,
  ConfirmDetails,
} from '../../containers/LoanApplication';

const StyledLayout = styled.section`
  &.unauthenticated {
    background: ${props => props.theme.primaryColor};
  }

  a.button {
    background: ${props => props.theme.secondaryColor};

    &.secondary {
      background: #ffffff;
      color: ${props => props.theme.primaryColor};

      .icon {
        svg {
          fill: ${props => props.theme.primaryColor};
        }
      }
    }
  }

  .repayment {
    .repayment__title {
      color: ${props => props.theme.primaryColor};
    }
  }

  .sign-in {
    .data-card {
      .create-account {
        a {
          color: ${props => props.theme.primaryColor};
        }
      }
    }
  }

  *::-moz-selection {
    background-color: ${props => props.theme.primaryColor};
  }

  *::selection {
    background-color: ${props => props.theme.primaryColor};
  }

  .sign-up,
  .new-loan {
    .loan-confirmation {
      h6 {
        a {
          color: ${props => props.theme.primaryColor};
        }
      }
    }

    .repaymentBreakdown {
      border-right-color: ${props => props.theme.primaryColor};
      border-left-color: ${props => props.theme.primaryColor};

      &__header {
        border-top-color: ${props => props.theme.primaryColor};
        border-bottom-color: ${props => props.theme.primaryColor};
      }
    }
  }

  .image-upload {
    border-color: ${props => props.theme.secondaryColor};
  }

  .document-upload {
    border-color: ${props => props.theme.secondaryColor};
  }

  .resend-otp,
  .resend-verification {
    color: ${props => props.theme.secondaryColor};
  }

  .new-loan {
    &-accordion {
      .rc-collapse {
        &-item {
          .button {
            &.secondary {
              color: ${props => props.theme.secondaryColor};
            }
          }
        }

        &-header {
          &:before {
            border-color: ${props => props.theme.secondaryColor};
          }

          &.confirmed {
            &:after {
              border-color: ${props => props.theme.secondaryColor};
              color: ${props => props.theme.secondaryColor};
            }
          }

          &[aria-expanded='true'] {
            color: ${props => props.theme.secondaryColor};
          }
        }
      }
    }
  }
`;

const AuthenticatedLayout = () => {
  const authentication = authGetter();

  return (
    <StyledLayout className={`layout ${authentication.authenticationType}`}>
      <Favicons />
      <Header authentication={authentication} />
      <Menu />
      <Breadcrumbs />
      <Notifications />

      <main className="main">
        <Switch>
          <Route
            path="/dashboard"
            exact
            render={props => (
              <Page {...props} component={Dashboard} title="Dashboard" />
            )}
          />

          <Route
            path="/loans"
            exact
            render={props => (
              <Page {...props} component={MyLoans} title="Loans" />
            )}
          />

          <Route
            path="/loans/repay-loan"
            exact
            render={props => (
              <Page
                {...props}
                component={MakeRepayment}
                title="Make Card Repayment"
              />
            )}
          />

          <Route
            path="/loans/repay-offline"
            exact
            render={props => (
              <Page
                {...props}
                component={OfflineRepayment}
                title="Make Offline Repayment"
              />
            )}
          />

          <Route
            path="/loans/details"
            exact
            render={props => (
              <Page {...props} component={LoanDetails} title="Loan Details" />
            )}
          />

          <Route
            exact
            path="/application"
            render={() => <Redirect push to="/application/new" />}
          />

          <Route
            exact
            path="/application/confirm-details"
            render={props => (
              <Page {...props} component={ConfirmDetails} title="New Loan" />
            )}
          />

          <Route
            exact
            path="/application/new"
            render={props => (
              <Page
                {...props}
                component={LoanInitiationScreen}
                title="New Loan"
              />
            )}
          />

          <Route
            path="/application/:applicationNumber"
            render={props => (
              <Page {...props} component={Application} title="New Loan" />
            )}
          />

          <Route
            path="/application-custom/:applicationNumber"
            render={props => (
              <Page {...props} component={CustomApplication} title="New Loan" />
            )}
          />

          <Route
            path="/settings"
            exact
            render={props => (
              <Page {...props} component={Settings} title="Settings" />
            )}
          />

          <Route
            path="/settings/:subPage"
            exact
            render={props => (
              <Page {...props} component={Settings} title="Settings" />
            )}
          />

          <Route
            path="*"
            exact
            render={props => (
              <Page {...props} component={NotFound} title="Page Not Found" />
            )}
          />
        </Switch>
      </main>

      <Footer />
    </StyledLayout>
  );
};

export default withRouter(AuthenticatedLayout);
