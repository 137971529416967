import styled from 'styled-components';
import { Aside as As, NextButton as Next } from '../styled';
export { Wrapper, Title } from '../styled';

export const Aside = styled(As)`
  margin: 4rem 0;
`;

export const NextButton = styled(Next)`
  margin-top: 0;
  cursor: pointer !important;
`;
