import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import { logEvent } from '../lib/GAHelper';
import config from '../config/config';
import { handleGraphQLErrors, getErrorMessages } from '../lib/utils';
import Constants from '../lib/constants';
import {
  TRIGGER_WORK_EMAIL_CONFIRMATION,
  UPDATE_USER_DETAILS,
  TRIGGER_PERSONAL_EMAIL_CONFIRMATION,
  APPLICATION_CREDIT_SCORE,
  GENERATE_APPLICATION_OFFERS,
  CREATE_CONTEXT_STATE,
  UPDATE_CONTEXT_STATE,
} from '../containers/SignUp/mutations';
import { CUSTOMER_REMITA_STATUS } from '../containers/SignUp/queries';
import { useClientInfoContext, useUserViewer } from './index';
import naijaXbyState from 'naija-xbystate';
import { CardService, UserService } from '../services';
import getClientId from './getClientId';
import { useSignUp } from '../v1/SignUp/SignupContext';

const useSignUpContext = () => {
  const { setSignupState, signupState } = useSignUp();
  const {
    clientInfo,
    loanCategoryId,
    loading: clientInfoLoading,
  } = useClientInfoContext({ loanCategoryId: signupState?.loanProduct });
  const history = useHistory();
  const { status, duration, authTypes } = Constants;
  const [signupError, setSignupError] = useState(null);
  const [signupLoading, setSignupLoading] = useState(null);
  const { viewerData, loading, error: viewerError, getUserViewer} = useUserViewer();
  const clientId = getClientId();

  const {
    personalId,
    utilityBill,
    requiresReferee,
    verifyWorkEmail,
    requiredSupportingDocuments,
  } = clientInfo;

  const [createContext, { loading: createContextLoading }] = useMutation(
    CREATE_CONTEXT_STATE,
    {
      onCompleted({ createContextState: { id, page, state } }) {
        setSignupState(prevState => ({
          ...prevState,
          contextId: id,
          contextPage: page,
          currContextState: state,
        }));
      },
    },
  );
  const [updateContext, { loading: updateContextLoading }] = useMutation(
    UPDATE_CONTEXT_STATE,
    {
      onCompleted({ updateContextState: { page, state } }) {
        setSignupState(prevState => ({
          ...prevState,
          contextPage: page,
          currContextState: state,
        }));
      },
    },
  );

  const updateContextState = useCallback(
    (page, state) => {
      const { contextId, contextPage, currContextState } = signupState;
      if (!contextId && viewerData?.loaded?.viewer) {
        createContextState(page);
        return;
      }
      if (!contextId) return;
      if (!updateContextLoading) {
        const updateState = state
          ? { ...currContextState, ...state }
          : undefined;
        updateContext({
          variables: {
            id: contextId,
            page: page || contextPage,
            state: updateState,
          },
        });
      }
    },
    [signupState, updateContextLoading, viewerData],
  );

  const [confirmWorkEmail, { loading: workEmailLoading }] = useMutation(
    TRIGGER_WORK_EMAIL_CONFIRMATION,
    {
      onError() {
        setSignupError(
          'Unable to send the verification code to your work email.',
        );
      },
      onCompleted({ triggerWorkEmailConfirmation }) {
        const { ok } = triggerWorkEmailConfirmation;
        if (!ok) {
          updateContextState('employment');
          history.push('/v1/sign-up/employment');
        } else {
          notify.show(
            'Please, Enter the Verification Code sent to your work email.',
            status.INFO,
            duration.LONG,
          );
          updateContextState('verify-work-email');
          history.push('/v1/sign-up/verify-work-email');
        }
      },
    },
  );

  const [triggerEmailConfirmation, { loading: emailLoading }] = useMutation(
    TRIGGER_PERSONAL_EMAIL_CONFIRMATION,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setSignupError(errorMessage || 'Unable to send OTP');
      },
      onCompleted({ triggerPersonalEmailConfirmation }) {
        const { ok } = triggerPersonalEmailConfirmation;
        notify.show('OTP sent successfully.', status.SUCCESS, duration.SHORT);
        if (!ok) {
          setSignupError('Unable to send OTP');
        }
      },
    },
  );

  const [getApplicationCreditScore, { loading: scoreLoading }] = useMutation(
    APPLICATION_CREDIT_SCORE,
    {
      onError(error) {
        showErrorAlert(handleGraphQLErrors(error));
        history.push('/v1/sign-up/failure');
      },
      onCompleted({ getApplicantCreditScore }) {
        if (!getApplicantCreditScore || !getApplicantCreditScore.success) {
          showErrorAlert('Unable to get credit score. Please try again later.');
          history.push('/v1/sign-up/failure');
        }
        if (getApplicantCreditScore.success) {
          updateAuthType();
          repaymentBreakdown();
        }
      },
    },
  );

  const [generateApplicationOffers, { loading: offerLoading }] = useMutation(
    GENERATE_APPLICATION_OFFERS,
    {
      onError(error) {
        showErrorAlert(
          handleGraphQLErrors(error) ||
            'An error occurred during your application.',
        );
        history.push('/v1/sign-up/failure');
      },
      onCompleted({ generateApplicationOffers }) {
        if (!generateApplicationOffers) {
          showErrorAlert(
            'There was an error processing your loan. Please try again later.',
          );
          history.push('/v1/sign-up/failure');
        } else {
          const { application, suggestedOffer } = generateApplicationOffers;
          const { requiredSteps, completedSteps, id, amount, dateOfRepayment } =
            application;

          if (suggestedOffer) {
            const { data, policy } = suggestedOffer;

            setSignupState(prevState => ({
              ...prevState,
              applicationId: id,
              requiredSteps,
              completedSteps,
              policy,
              suggestedOffer: data,
              repaymentBreakdown: data?.repaymentBreakdown,
              repaymentDate: dateOfRepayment,
              processingFee: policy ? policy?.processingFee?.value : null,
              processingFeeCalcBy: policy ? policy?.processingFee?.calcBy : '',
              repaymentDetails: {
                ...signupState.repaymentDetails,
                approvedAmount: amount,
                repaymentAmount: data.fullAmount,
              },
            }));

            updateContextState({ suggestedOffer });
          }

          if (!suggestedOffer) {
            updateContextState('loan-pending');
            history.push('/v1/sign-up/loan-pending');
          }
        }
      },
    },
  );

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onError() {
        setSignupError(
          'An error occurred while updating your details. Please try again.',
        );
      },
    },
  );

  const [customerRemitaStatus, { loading: customerRemitaLoading }] =
    useLazyQuery(CUSTOMER_REMITA_STATUS, {
      onCompleted({ customerRemitaStatus }) {
        if (customerRemitaStatus) {
          const { bankId, companyName, accountNumber, salaryPaymentDetails } =
            customerRemitaStatus;
          const monthlySalary = salaryPaymentDetails[0].amount;

          setSignupState(prevState => ({
            ...prevState,
            remitaCustomer: true,
            remitaBankId: bankId,
            accountNumber: accountNumber,
            remitaNetIncome: monthlySalary,
            remitaEmployerName: companyName,
            remitaEmploymentStatus: 'employedfulltime',
          }));
        }
      },
    });

  const verifyAge = useCallback(() => {
    logEvent('Signup', 'User Age Verification');
    const {
      user: {
        bvnStatus: { dateOfBirth },
      },
    } = signupState;
    const today = new moment();
    const yearDifference = moment.duration(today.diff(dateOfBirth)).asYears();
    const age = parseInt(yearDifference);

    // Upper limit Age verification will happen via the api
    if (age < 18) {
      localStorage.clear();
      history.push('/v1/sign-up/failure', {
        message:
          'We are sorry, based on your age, we cannot offer you a loan at this time. Thank you',
      });
      return;
    }

    updateContextState('verify-email');
    history.push('/v1/sign-up/verify-email');
  }, [signupState]);

  const checkPersonalIdRequired = useCallback(() => {
    if (personalId) {
      updateContextState('upload-id');
      history.push('/v1/sign-up/upload-id');
    } else {
      updateContextState('home-address');
      history.push('/v1/sign-up/home-address');
    }
  }, [personalId, history]);

  const checkUtilityBill = useCallback(() => {
    if (utilityBill) {
      updateContextState('utility-bill');
      history.push('/v1/sign-up/utility-bill');
    } else {
      updateContextState('employment');
      history.push('/v1/sign-up/employment');
    }
  }, [utilityBill]);

  const checkReferee = useCallback(() => {
    if (requiresReferee) {
      updateContextState('referee');
      history.push('/v1/sign-up/referee');
    } else {
      if (requiredSupportingDocuments.length > 0) {
        updateContextState('supporting-documents');
        history.push('/v1/sign-up/supporting-documents');
      } else {
        updateContextState('loan-amount');
        history.push('/v1/sign-up/loan-amount');
      }
    }
  }, [requiresReferee, signupState, requiredSupportingDocuments]);

  const customerRemita = useCallback(async () => {
    logEvent('Signup', 'Customer Remita Status.', true);
    const userPhone = await UserService.viewer();
    const { phone } = userPhone.data.data.viewer.me || this.state.phone;
    customerRemitaStatus({
      variables: {
        clientId,
        customerPhoneNumber: phone,
      },
    });
  }, []);

  const updateUserDetails = useCallback((userDetails, callback) => {
    updateUser({
      variables: {
        ...userDetails,
      },
    })
      .then(() => {
        callback();
      })
      .catch(() => {});
  }, []);

  const populateStates = useMemo(() => {
    const nigeriaStates = naijaXbyState.all().map(item => item.state);
    return nigeriaStates.map((state, index) => (
      <option key={index}>{state}</option>
    ));
  }, []);

  const triggerWorkEmailConfirmation = useCallback(() => {
    const { workEmail } = signupState;

    if (!verifyWorkEmail) {
      checkReferee();
      return;
    } else {
      history.push('/v1/sign-up/verify-work-email');
    }
    confirmWorkEmail({
      variables: { clientId, workEmail },
    });
  }, [signupState, verifyWorkEmail]);

  const showErrorAlert = text => {
    notify.show(text, status.ERROR, duration.SHORT);
  };

  const updateAuthType = useCallback(() => {
    const authCreds = JSON.parse(localStorage.getItem('Auth'));
    authCreds.keyType = authTypes.FIXED;
    localStorage.setItem('Auth', JSON.stringify(authCreds));
  }, []);

  const getAddCardReference = useCallback(async () => {
    logEvent('Signup', 'Generate Card Reference');
    setSignupLoading(true);

    const { loanDuration } = signupState;

    const response = await CardService.getAddCardReference(clientId);
    setSignupLoading(false);

    if (!response || (response && response.data.errors)) {
      showErrorAlert(getErrorMessages(response && response.data.errors));
      return;
    }
    const { getAddCardReference } = response.data.data;

    if (!getAddCardReference) {
      showErrorAlert('Error generating Transaction Reference');
      return;
    }
    const cardRef = getAddCardReference.reference;

    setSignupState(prevState => ({
      ...prevState,
      payStackReference: cardRef,
    }));
    updateContextState('processing-info', {
      payStackReference: cardRef,
      loanDuration,
    });
  }, [config]);

  const getCardReferenceStatus = useCallback(
    async ({ loanDuration }) => {
      logEvent('Signup', 'Get Card Reference Status');

      setSignupLoading(true);
      const { payStackReference } = signupState;
      const response = await CardService.getCardReferenceStatus({
        reference: payStackReference,
        loanDuration,
      });
      setSignupLoading(false);

      if (!response) {
        showErrorAlert(
          'Error verifying your payment status. Please, try again.',
        );
        return;
      }
      if (response && response.data.errors) {
        const errorMessage =
          response.data.errors[0].message ||
          'Error verifying your payment status. Please, try again.';
        setSignupError(errorMessage);
        notify.show(errorMessage, status.ERROR, duration.LONG);
        return;
      }
      const { getCardReferenceStatus } = response.data.data;

      if (!getCardReferenceStatus.status) {
        setSignupError(
          'Error verifying your payment status. Please, try again.',
        );
        return;
      }
      const payStackReferenceStatus = getCardReferenceStatus.status;
      const paystackcardBankName = getCardReferenceStatus.bank;
      const cardBank = getCardReferenceStatus.bank;
      const paystackCard = getCardReferenceStatus.card;
      const failedReason =
        getCardReferenceStatus.reason ||
        'The card already exists with another user';

      switch (payStackReferenceStatus) {
        case 'SUCCESS':
          setSignupState(prevState => ({
            ...prevState,
            closePayStack: true,
            payStackSuccess: true,
            paystackcardBankName,
            paystackCard,
            willInitiatePaystack: false,
            cardBank,
          }));
          break;
        case 'FAILED':
          await getAddCardReference();
          setSignupState(prevState => ({
            ...prevState,
            payStackSuccess: false,
          }));
          setSignupError(failedReason);
          notify.show(failedReason, status.ERROR, -1);
          break;
        case 'INVALID':
          await getAddCardReference();
          setSignupState(prevState => ({
            ...prevState,
            payStackSuccess: false,
          }));
          notify.show(`${getCardReferenceStatus.reason}`, 'error', 8000);
          break;
        case 'NOT_USED':
          notify.show(
            `${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add card button`,
            'info',
            8000,
          );
          break;
        case 'NOT_REUSABLE':
          await getAddCardReference();
          setSignupState(prevState => ({
            ...prevState,
            payStackSuccess: false,
          }));
          setSignupError(failedReason);
          notify.show(failedReason, status.ERROR, duration.long);
          break;
        case 'ABANDONED':
          notify.show(
            `${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add card button`,
            'info',
            8000,
          );
          break;
        default:
          getCardReferenceStatus({});
      }
    },
    [signupState?.payStackReference],
  );

  const repaymentBreakdown = () => {
    const { applicationId } = signupState;
    generateApplicationOffers({
      variables: {
        applicationId,
      },
    });

    history.push('/v1/sign-up/breakdown');
  };

  const createContextState = useCallback(
    (page, state) => {
      if (!createContextLoading) {
        createContext({
          variables: {
            state,
            page: page || 'verify-phone',
            context: 'SIGN_UP',
          },
        });
      }
    },
    [signupState, createContextLoading],
  );

  useEffect(() => {
    if (signupError) {
      setTimeout(() => {
        setSignupError(null);
      }, 5000);
    }
  }, [signupError]);

  useEffect(() => {
    setSignupLoading(
      emailLoading ||
        scoreLoading ||
        offerLoading ||
        updateUserLoading ||
        customerRemitaLoading ||
        loading ||
        workEmailLoading,
    );
  }, [
    emailLoading,
    scoreLoading,
    offerLoading,
    workEmailLoading,
    updateUserLoading,
    customerRemitaLoading,
    loading,
  ]);

  useEffect(() => {
    if (viewerData && viewerData?.loaded?.viewer) {
      const { viewer } = viewerData?.loaded;
      const user = viewer && viewer.me;

      setSignupState(prevState => ({
        ...prevState,
        user,
        phoneNumber: `0${Number(user?.bvnStatus?.phone || user.phone)}`,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        accountId: viewer?.account?.id,
      }));
    }
  }, [viewerData]);

  return {
    signupState,
    populateStates,
    signupError,
    signupLoading,
    setSignupState,
    setSignupError,
    setSignupLoading,
    triggerEmailConfirmation,
    getApplicationCreditScore,
    verifyAge,
    checkPersonalIdRequired,
    checkUtilityBill,
    checkReferee,
    triggerWorkEmailConfirmation,
    showErrorAlert,
    updateAuthType,
    getAddCardReference,
    getCardReferenceStatus,
    repaymentBreakdown,
    customerRemita,
    createContextState,
    updateContextState,
    updateUserDetails,
    clientInfo,
    loanCategoryId,
    clientInfoLoading,
    viewerLoading:loading,
    viewerData,
    viewerError,
    getUserViewer,
  };
};

export default useSignUpContext;
