import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styled';
import { debounce } from '../../../../../lib/utils';

const LoanProductSearch = ({ setSearch, loanCategories }) => {
   const [showReset, setShowReset] = useState(false)
   const activeCategories = useMemo(
    () => loanCategories?.filter((category) => category?.status === "ACTIVE"),
    [loanCategories]
   );
 
   const handleSubmit = useCallback(
    debounce((value) => {
      if (value) {
        const filtered = activeCategories?.filter((category) =>
          category?.name.toLowerCase().includes(value.toLowerCase())
        );
        setSearch(filtered);
        setShowReset(true);
      } else {
        setSearch(loanCategories);
        setShowReset(false);
      }
    }, 300),
    [loanCategories, setSearch, activeCategories]
  );
  
  
  

  return (
    <div>
      <Formik
        initialValues={{
          search: '',
        }}
      >
        {() => 
            <Form>
                <S.SearchWrapper>
                <input
                    name="search"
                    type="text"
                    placeholder="Search for a loan product"
                    onChange={(e) => {
                        handleSubmit(e.target.value);
                    }}
                    style={{border: "1px solid #a4a59a"}}
                />
                </S.SearchWrapper>
            </Form>
        }
      </Formik>

    {showReset && (
        <S.ResetButton
            onClick={() => {
            setSearch(loanCategories);
            setShowReset(false);
            const inputElement = document.querySelector('input[name="search"]');
            if (inputElement) {
                inputElement.value = '';
            }
            }}
        >
            Reset
        </S.ResetButton>
    )}
    </div>
  );
};

export default LoanProductSearch;