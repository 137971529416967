import React from 'react';
import PropTypes from 'prop-types';
import './_RadioGroup.scss';

const RadioGroup = ({ classes, children }) => {
  return (
    <div className={`radio-group ${classes == null ? '' : classes}`}>
      {children}
    </div>
  );
};

RadioGroup.propTypes = {
  classes: PropTypes.string,
};

export default RadioGroup;
