import styled from 'styled-components';
import icon from '../../../static/icons/checklist.svg';
export {
  Wrapper,
  Title,
  Subtitle,
  PreviousButton,
  SkipButton,
  ImageUpload,
  Paragraph,
} from '../styled';

export { Button } from '../../../components/FormikElements/SubmitButton/styled.js';

export const FooterText = styled.h5`
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  padding: 2rem 3rem 0 3rem;
`;

export const List = styled.ul`
  margin: 0 0 to-rem(50px);
  padding: 0;

  li{
    background: url(${icon}) top 3px left no-repeat;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.font_semibold};
    line-height: 2.5rem;
    list-style: none;
    margin: 0 0 1.5rem
    padding-left:  3.5rem
  }
`;

export const Div = styled.div`
  text-align: center;
`;
