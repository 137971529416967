import { useMutation } from '@apollo/client';
import { UPLOAD_SUPPORTING_DOCUMENT } from '../Uploads/queries';

const UploadSupportingDocumentProvider = () => {
  const [mutate] = useMutation(UPLOAD_SUPPORTING_DOCUMENT, {
    onError(...error) {
      return error;
    },
  });

  const uploadSupportingDocumentFile = ({
    selectedFile,
    documentName,
    userId,
  }) => {
    const { validity, files } = selectedFile;
    const file = files[0];

    return (
      validity.valid &&
      mutate({
        variables: { file, documentName, userId },
      })
    );
  };

  return {
    uploadSupportingDocumentFile: data => uploadSupportingDocumentFile(data),
  };
};

export default UploadSupportingDocumentProvider;
