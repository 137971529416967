import React, { useState, createContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VIEWER_QUERY } from '../containers/SignUp/queries';

export const UserViewerContext = createContext({});

export const UserViewerProvider = ({ children }) => {
  const [viewerData, setViewerData] = useState({ loaded: false });

  const [getUserViewer, { loading, refetch, error }] = useLazyQuery(
    VIEWER_QUERY,
    {
      onCompleted: (data) => {
        setViewerData(prevState => ({ ...prevState, loaded: data
        }));
      },
      
      fetchPolicy: 'network-only',
    },
    
  );


  useEffect(() => {
    if (!viewerData?.loaded?.viewer) {
      getUserViewer();
    }
  }, [viewerData?.loaded?.viewer]);

  const values = {
    loading,
    viewerData,
    refetch,
    getUserViewer,
    setViewerData,
    error
  };

  

  return (
    <UserViewerContext.Provider value={values}>
      {children}
    </UserViewerContext.Provider>
  );
};
