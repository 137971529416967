import React from 'react';
import PropTypes from 'prop-types';
import './_FormGroup.scss';

const FormGroup = ({ classes, children }) => {
  return (
    <div className={`form-group ${classes == null ? '' : classes}`}>
      {children}
    </div>
  );
};

FormGroup.propTypes = {
  classes: PropTypes.string,
};

export default FormGroup;
