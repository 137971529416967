import moment from 'moment';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useClientInfoContext } from '../../hooks';
import { formatPhone } from '../../lib/utils';
import './_Footer.scss';

const StyledFooter = styled.footer`
  background: ${props => props.theme.secondaryColor};
`;

const Footer = () => {
  const {
    clientInfo: { clientName, clientContactInfo },
  } = useClientInfoContext();

  return (
    <Fragment>
      <StyledFooter className="footer">
        <section className="container">
          <section>
            {clientContactInfo.phone || clientContactInfo.email ? (
              <ul className="footer__helpline">
                <span>SUPPORT:</span>
                <li>
                  <a href={`tel:${clientContactInfo.phone}`}>
                    {formatPhone(clientContactInfo.phone)}
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${clientContactInfo.email}?Subject=SUPPORT: ${clientName} Loan Portal`}
                    target="_top"
                  >
                    {clientContactInfo.email}
                  </a>
                </li>
              </ul>
            ) : (
              ''
            )}
          </section>
          <section>
            &copy; {moment().format('Y')} {clientName}
          </section>
        </section>
      </StyledFooter>
    </Fragment>
  );
};

export default Footer;
