import React from 'react';
import PropTypes from 'prop-types';
import './_Alert.scss';
import { CloseIcon } from '../Icon';

const Alert = ({ classes, children, dismissible }) => {
  return (
    <div className={`alert ${classes == null ? '' : classes}`}>
      {children}
      {dismissible && (
        <button className={'dismiss'}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  classes: PropTypes.string,
};

export default Alert;
