import React from 'react';
import { Query } from '@apollo/client/react/components';
import { useQuery } from '@apollo/client';
import subMonths from 'date-fns/sub_months';
import subYears from 'date-fns/sub_years';

import { CURRENT_TIME_QUERY } from './queries';

const getData = queryResult => {
  const { data, loading, error } = queryResult;

  let date;
  if (loading || error || !data) {
    date = new Date();
  } else {
    date = new Date(data.currentTime);
  }
  return {
    currentTime: date.toISOString(),
    error,
    loading,
    oneMonthAgo: subMonths(date, 1).toISOString(),
    sixMonthsAgo: subMonths(date, 6).toISOString(),
    oneYearAgo: subYears(date, 1).toISOString(),
    fourYearsAgo: subYears(date, 4).toISOString(),
    sevenYearsAgo: subYears(date, 7).toISOString(),
    tenYearsAgo: subYears(date, 10).toISOString(),
  };
};

export const useCurrentTime = () => {
  const queryResult = useQuery(CURRENT_TIME_QUERY);

  return getData(queryResult);
};

const CurrentTimeProvider = ({ children }) => (
  <Query query={CURRENT_TIME_QUERY}>
    {queryResult => children(getData(queryResult))}
  </Query>
);

export default CurrentTimeProvider;
