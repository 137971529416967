import { createContext, useContext } from 'react';

export const MerchantContext = createContext({});

export const MerchantProvider = MerchantContext.Provider;
export const MerchantConsumer = MerchantContext.Consumer;

export const useMerchant = () => {
  return useContext(MerchantContext);
};
