/* eslint-disable prettier/prettier */
import React from 'react';
import { Alert, DataCard } from '../../../components';
import Notifications from 'react-notify-toast';
import * as S from './styled';
import { Form, Formik } from 'formik';
import { maskPhoneNumber } from '../../../lib/utils';
import { OTPValidationSchema } from '../../../lib/validationSchemas';
import { NumberInput, SubmitButton } from '../../../components/FormikElements';
import usePhoneVerification from './usePhoneVerification';

const PhoneVerification = ({ customForm, handleNext, formIndex }) => {
  const {
    alertSuccess,
    phoneError,
    phoneNumber,
    confirmPhoneLoading,
    phoneConfirmationLoading,
    handleSubmit,
    resendOTP,
    phone,
    viewerLoading
  } = usePhoneVerification({ customForm, handleNext, formIndex });

  return (
    <S.Wrapper>
      <Notifications />
      <DataCard loading={confirmPhoneLoading || phoneConfirmationLoading || viewerLoading}>
        <S.Title>Phone Number Verification</S.Title>

        <S.Subtitle>
          We have sent a verification code to the phone number (
          <b>{maskPhoneNumber(`0${phoneNumber || phone}`)}</b>) linked to your
          bvn via <b>SMS</b>. Please enter the 6-digit OTP below.
        </S.Subtitle>
        {phoneError && <Alert classes="error">{phoneError}</Alert>}
        {alertSuccess && <Alert classes="success">{alertSuccess}</Alert>}
        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={OTPValidationSchema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          <Form>
            <NumberInput
              name="otp"
              maxLength="6"
              placeholder="Verification Code"
            />

            <S.Paragraph className="center-text">
              Didn't get OTP? <br />
              <button type="button" onClick={resendOTP}>
                Resend OTP
              </button>{' '}
            </S.Paragraph>
            <S.Paragraph className="center-text bold-text">
              NOTE: The OTP may not deliver if DND is active on your number.
              Send ALLOW to 2442 to remove DND, then click
              <button type="button" onClick={resendOTP}>
                Resend OTP
              </button>
            </S.Paragraph>
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default PhoneVerification;
