import { createSlice } from '@reduxjs/toolkit';
export const contextStateRedux = createSlice({
  name: 'contextState',
  initialState: {
    contextState: [],
    nextPage: 'loader',
  },
  reducers: {
    setFullContextState: (state, action) => {
      const index = state.contextState.findIndex(
        a => a.applicationNumber === action.payload.applicationNumber,
      );
      state.contextState[index < 0 ? 0 : index] = {
        applicationNumber: action.payload.applicationNumber,
        steps: action.payload.steps,
      };
    },
    updateSingleContextState: (state, action) => {
      const index = state.contextState.findIndex(
        a => a.applicationNumber === action.payload.applicationNumber,
      );
      if (index >= 0)
        state.contextState[index].steps[action.payload.step] = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFullContextState, updateSingleContextState } =
  contextStateRedux.actions;

export const contextState = state => {
  return state.contextState.contextState;
};

export default contextStateRedux.reducer;
