import gql from 'graphql-tag';

export const REQUEST_MANDATE_OTP = gql`
  mutation RequestOtpForMandateActivation(
    $debitMandateId: ID!
    $clientId: ID!
  ) {
    requestOtpForMandateActivation(
      input: { clientId: $clientId, debitMandateId: $debitMandateId }
    ) {
      status
      authParams
    }
  }
`;

export const VALIDATE_MANDATE_OTP = gql`
  mutation ValidateOtpForMandateActivation(
    $clientId: ID!
    $debitMandateId: ID!
    $authParams: JSON!
  ) {
    validateOtpForMandateActivation(
      input: {
        clientId: $clientId
        debitMandateId: $debitMandateId
        authParams: $authParams
      }
    ) {
      success
    }
  }
`;
