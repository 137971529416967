import React from 'react';
import PropTypes from 'prop-types';
import './_CheckBox.scss';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledCheckBox = styled.label`
  input {
    :checked + .checkbox__label {
      border-color: ${props => props.theme.secondaryColor};
      color: ${props => props.theme.secondaryColor};

      :before {
        background-color: ${props => props.theme.secondaryColor};
        border-color: ${props => props.theme.secondaryColor};
      }
    }
  }

  .checkbox__label {
    :before {
      border-color: ${props => transparentize(0.5, props.theme.secondaryColor)};
    }
  }
`;

const CheckBox = ({ classes, label, ...restProps }) => {
  return (
    <StyledCheckBox className={'checkbox'}>
      <input
        className={`${classes == null ? '' : classes}`}
        type="checkbox"
        {...restProps}
      />

      <span className="checkbox__label">{label}</span>
    </StyledCheckBox>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
};

export default CheckBox;
