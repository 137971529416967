import React, { Fragment } from 'react';
import './_Card.scss';
import PropTypes from 'prop-types';

const Card = ({ title, subtitle, children }) => {
  return (
    <div className={'card'}>
      <div className="card-desc">
        {title == null ? (
          ''
        ) : (
          <Fragment>
            <h4 className={'card-title'}>{title}</h4>
            <h5 className={'card-subtitle'}>{subtitle}</h5>
          </Fragment>
        )}
      </div>
      <div className={'card-content'}>{children}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Card;
