import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

const PreLoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Fragment>
        <Component {...props} />
      </Fragment>
    )}
  />
);

export default PreLoginRoute;
