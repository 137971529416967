import React, { useEffect } from 'react';
import { useRequestConsent } from '../../../containers/providers';
import { titleCase } from '../../../lib/utils';
import { CloseIcon } from '../../Icon';
import { Alert, Button, Popup } from '../../index';

const SignWithDocusign = ({ application, popupToggle, setLoading }) => {
  const { loading, success, errorMessage, requestConsent } =
    useRequestConsent();

  const signNow = () => {
    requestConsent({
      applicationNumber: application.applicationNumber,
      providerName: 'DocuSign',
    });
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [loading]);

  useEffect(() => {
    if (success) {
      popupToggle('DocusignSent');
    }
  }, [success]);

  return (
    <Popup classes="offer-letter-popup">
      <Button classes="close-popup" click_event={() => popupToggle('')}>
        <CloseIcon />
      </Button>

      <h4 className={'data-card__title'}>Sign with DocuSign</h4>

      {errorMessage ? (
        <Alert classes="warning">{titleCase(errorMessage)}</Alert>
      ) : (
        ''
      )}

      <p className="center-text">
        An email will be sent to you by DocuSign with your offer letter. Would
        you like to continue signing the offer letter with DocuSign?
      </p>

      <div className="decision_buttons">
        <Button click_event={signNow}>YES</Button>
        <Button
          click_event={() => popupToggle('OfferLetterOptions')}
          classes="secondary"
        >
          NO
        </Button>
      </div>
    </Popup>
  );
};

export default SignWithDocusign;
