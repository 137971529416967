import React, { useEffect } from 'react';
import { CloseIcon, NewCheck } from '../../Icon';
import { Button, Popup } from '../../index';

const OfferLetterDeclined = ({ popupToggle, refetch }) => {
  useEffect(() => {
    refetch();
  }, []);

  return (
    <Popup classes="offer-letter-popup">
      <Button classes="close-popup" click_event={() => popupToggle('')}>
        <CloseIcon />
      </Button>

      <div className="response-icon-holder">
        <NewCheck />
      </div>

      <h4 className={'data-card__title'}>Offer letter declined</h4>

      <p className="center-text">
        You have declined to sign the offer letter. Your loan has been
        cancelled.
      </p>
    </Popup>
  );
};

export default OfferLetterDeclined;
