import React, { Fragment, useState } from 'react';
import { DocumentIcon } from '../../components/Icon';
import { removeUnderscore, toTitleCase } from '../../lib/utils';
import { CdnFileProvider, ViewerProvider } from '../providers';
import './_Settings.scss';

export const SupportingDocuments = () => {
  const [filter, setFilter] = useState('ALL');

  const handleChange = e => {
    e.preventDefault();
    const { value } = e.target;

    setFilter(value);
  };

  return (
    <ViewerProvider supportingDocumentFilter={filter}>
      {({ user, supportingDocumentEnum, applications }) => {
        const application = applications[0];
        const reviewComplete = application?.reviewFeedbacks.find(
          feedback => feedback.status === 'COMPLETED',
        );
        const hasDocuments =
          reviewComplete &&
          reviewComplete.documentRequests &&
          reviewComplete.documentRequests.length !== 0 &&
          reviewComplete.documentRequests.find(
            document => document.status === 'COMPLETED',
          );
        return (
          <Fragment>
            <div className="doc-filter">
              <h5>
                Showing <span>{toTitleCase(removeUnderscore(filter))}</span>{' '}
                Documents
              </h5>
              <select onChange={handleChange} defaultValue={filter}>
                {supportingDocumentEnum.enumValues.map((enumData, idx) => (
                  <option key={idx} value={enumData.name}>
                    {toTitleCase(removeUnderscore(enumData.name))}
                  </option>
                ))}
              </select>
            </div>

            <div className="supporting-document">
              {user &&
                user.supportingDocument.map((document, index) => (
                  <CdnFileProvider file={document.file} key={index}>
                    {({ openFile }) => (
                      <article
                        className="doc-box"
                        title={document.documentName}
                      >
                        <DocumentIcon className="icon" />
                        <span className="document-name">
                          {document.documentName}
                        </span>
                        <button className="view-button" onClick={openFile}>
                          View Document
                        </button>
                      </article>
                    )}
                  </CdnFileProvider>
                ))}

              {hasDocuments &&
                reviewComplete &&
                (filter === 'OFFER_LETTER' || filter === 'ALL' ? (
                  <div className="supporting-document">
                    {application &&
                      application.reviewFeedbacks.map((review, index) =>
                        review.documentRequests.map(
                          doc =>
                            doc.document && (
                              <Fragment key={index}>
                                <CdnFileProvider
                                  file={doc.document.file}
                                  key={index}
                                >
                                  {({ openFile }) => (
                                    <article
                                      className="doc-box"
                                      title={doc.document.documentName}
                                    >
                                      <DocumentIcon className="icon" />
                                      <span className="document-name">
                                        {doc.document.documentName}
                                      </span>
                                      <button
                                        className="view-button"
                                        onClick={openFile}
                                      >
                                        View Document
                                      </button>
                                    </article>
                                  )}
                                </CdnFileProvider>
                              </Fragment>
                            ),
                        ),
                      )}
                  </div>
                ) : (
                  ''
                ))}
            </div>
          </Fragment>
        );
      }}
    </ViewerProvider>
  );
};

export default SupportingDocuments;
