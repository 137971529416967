/* eslint-disable prettier/prettier */
import { useDocumentUploader, useLoanApplicationContext } from "../../../hooks";
import { logEvent } from "../../../lib/GAHelper";
import { useCallback, useEffect, useState } from "react";
import { pages } from "../../../lib/constants";

const useBillsUploadForm = () => {
  const {
    user,
    setLoanApplicationState,
    createApplicationTrace,
    goToNextPage,
    goToPreviousPage,
    setCompletedStep,
  } = useLoanApplicationContext();
  const {
    data,
    uploadDocument,
    uploadDocumentLoading,
    handleDocumentPreview,
    uploadDocumentError,
  } = useDocumentUploader();
  const [utilityBillsDetails, setUtilityBillDetails] = useState({});

  const handleUpload = useCallback(() => {
    logEvent("Signup", "Upload Utility bill");

    const { firstName, lastName, id } = user || {};
    const fileName = `utilityBill_${firstName}_${lastName}_${id}`;
    uploadDocument(utilityBillsDetails.fileDetails, fileName, id);
  }, [utilityBillsDetails, user]);

  const handlePreview = (e) => {
    handleDocumentPreview(e, setUtilityBillDetails);
  };

  useEffect(() => {
    if (data) {
      const {
        uploadSupportingDocument: { fileUrl },
      } = data;
      setLoanApplicationState((prevState) => ({
        ...prevState,
        utilityBillUrl: fileUrl,
      }));
      setCompletedStep("utilityBill");
      goToNextPage();
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.utilityBill,
      "Navigated to Utility Bill Upload Screen"
    );
  }, []);
  return {
    uploadDocumentLoading,
    uploadDocumentError,
    utilityBillsDetails,
    handleUpload,
    handlePreview,
    goToPreviousPage,
  };
};

export default useBillsUploadForm;
