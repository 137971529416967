import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '../Input/Input';

const PasswordField = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
`;

const ToggleButton = styled.button`
  position: absolute;
  bottom: 2.8rem;
  right: 0;
  font-weight: 800;
  color: ${({ theme }) => theme.primaryColor};
  background: transparent;
  text-align: right;
  width: auto !important;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

const PasswordInput = props => {
  const { value, allowToggle: toggleSetting } = props;

  const allowToggle =
    typeof toggleSetting === 'undefined' ? true : toggleSetting;

  const [inputType, setInputType] = useState('password');

  const toggleInputType = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <PasswordField>
      <Input type={inputType} {...props} />
      {(typeof value === 'undefined' || value) && allowToggle && (
        <ToggleButton
          type="button"
          className="password-field_toggle"
          onClick={toggleInputType}
        >
          {inputType === 'password' ? 'show' : 'hide'}
        </ToggleButton>
      )}
    </PasswordField>
  );
};

PasswordInput.propTypes = {
  allowToggle: PropTypes.bool,
  marginTop: PropTypes.number,
};

export default PasswordInput;
