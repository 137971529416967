import { useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { handleGraphQLErrors } from '../../../lib/utils';
import { CONSENT_CALLBACK } from './queries';

const useConsentCallback = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');

  const [consentCallbackMutation, { loading, data }] = useMutation(
    CONSENT_CALLBACK,
    {
      onError(error) {
        setErrorMessage(handleGraphQLErrors(error));
        return;
      },
    },
  );

  useEffect(() => {
    if (!loading && data && data.consentCallback) {
      if (data.consentCallback.success) {
        setSuccess(true);
      } else {
        setErrorMessage(data.consentCallback.message);
      }
    }
  }, [data, loading]);

  const consentCallback = ({ applicationNumber, otp, providerName }) => {
    consentCallbackMutation({
      variables: {
        applicationNumber,
        otp,
        providerName,
      },
    });
  };

  const props = {
    loading,
    success,
    errorMessage,
    consentCallback,
  };

  return props;
};

export default useConsentCallback;
