import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DECLINE_OFFER_LETTER } from '../../../containers/providers/OfferLetter/queries';
import { handleGraphQLErrors, titleCase } from '../../../lib/utils';
import Alert from '../../Alert/Alert';
import { Button, Popup } from '../../index';

const ButtonHolder = styled.div`
  margin: 30px 0 0;

  .decline-offer {
    .decline_button {
      border-color: #ae4036;
      color: #ae4036;
      min-width: 185px;

      &:hover {
        border-color: #ae4036;
        color: #ae4036;
      }
    }

    .button.cancel_button.secondary {
      border: none;

      &:hover {
        border: none;
      }
    }
  }
`;

const DeclineOfferLetter = ({ application, popupToggle, setLoading }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const [decline, { loading }] = useMutation(DECLINE_OFFER_LETTER, {
    onError(error) {
      setErrorMessage(handleGraphQLErrors(error));
      return;
    },
    onCompleted() {
      popupToggle('OfferLetterDeclined');
      return;
    },
  });

  const declineOffer = () => {
    decline({
      variables: { applicationNumber: application.applicationNumber },
    });
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [loading]);

  return (
    <Popup title="Decline Offer Letter" classes="offer-letter-popup">
      {errorMessage ? (
        <Alert classes="warning">{titleCase(errorMessage)}</Alert>
      ) : (
        ''
      )}

      <p className="center-text">
        Declining to sign this offer letter will automatically decline your
        loan. Are you sure you want to decline to sign the offer letter?
      </p>

      <ButtonHolder>
        <div className="decline-offer">
          <Button click_event={declineOffer} classes="decline_button secondary">
            Decline
          </Button>

          <Button
            click_event={() => popupToggle('OfferLetterOptions')}
            classes="cancel_button secondary"
          >
            Cancel
          </Button>
        </div>
      </ButtonHolder>
    </Popup>
  );
};

export default DeclineOfferLetter;
