import { useLoanApplicationContext } from '../../../hooks';
import { useEffect, useState } from 'react';
import { pages } from '../../../lib/constants';
import { useHistory } from 'react-router';
import { handleCustomFormKyc } from '../../../lib/customFormNavigation';

const useRefereeDetailsForm = ({ customForm, handleNext }) => {
  const {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    applicationId,
    createApplicationTrace,
    indexSlug,
    clientInfo: { requiresGovernmentId, requireWorkEmail },
    goToPreviousPage,
    goToNextPage,
    user,
    userLoading,
    applicationNumber,
    setCompletedStep,
    loanApplicationIsLoading,
  } = useLoanApplicationContext();

  const [currentReferee, setCurrentReferee] = useState({});
  const history = useHistory();

  const pageRenderConditions = {
    requireWorkEmail,
    requiresGovernmentId,
  };

  const addReferee = values => {
    setLoanApplicationState(prevState => ({
      ...prevState,
      ...values,
    }));
    updateUserDetails({ ...values }, () => {
      setCompletedStep('requireReferee');

      if (customForm) {
        handleCustomFormKyc({
          pageRenderConditions,
          applicationId,
          handleNext,
          history,
          applicationNumber,
          currentStepCompleted: customForm,
        });
      } else {
        goToNextPage();
      }
    });
  };

  useEffect(() => {
    createApplicationTrace(
      pages.referee,
      'Navigated to Referee Details Screen',
    );
  }, []);

  useEffect(() => {
    if (!userLoading) {
      const { userMetadata } = user || {};
      setCurrentReferee({
        refereeName: userMetadata?.find(meta => meta.name === 'refereeName')
          ?.value,
        refereePhone: userMetadata?.find(meta => meta.name === 'refereePhone')
          ?.value,
        refereeWorkPlace: userMetadata?.find(
          meta => meta.name === 'refereeWorkPlace',
        )?.value,
        refereeEmail: userMetadata?.find(meta => meta.name === 'refereeEmail')
          ?.value,
      });
    }
  }, [user, userLoading]);

  return {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    indexSlug,
    currentReferee,
    addReferee,
    loanApplicationIsLoading,
    goToPreviousPage,
    userLoading,
  };
};

export default useRefereeDetailsForm;
