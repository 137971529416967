import React from 'react';

const Ellipsis = props => (
  <svg width={15} height={3} fill="none" {...props}>
    <circle cx={12.643} cy={1.5} r={1.5} fill="#fff" />
    <circle cx={7.071} cy={1.5} r={1.5} fill="#fff" />
    <circle cx={1.5} cy={1.5} r={1.5} fill="#fff" />
  </svg>
);

export default Ellipsis;
