import { useQuery } from '@apollo/client';
import React, { createContext } from 'react';
import { CLIENT_INFO_QUERY, GET_ENUM } from './queries';
import { web } from '../../../config/config';
import { setGlobalClientId } from '../../../lib/utils';

const contactDetails = {
  phone: '',
  email: '',
};

const getVariables = ({ loanCategoryId } = {}) => {
  let slug;
  let clientUrl;
  const { app_env } = web;
  if (['staging', 'development', 'demo'].includes(app_env)) {
    slug = window.location.host.split('.')[0];
  } else {
    clientUrl = window.location.origin.toLowerCase();
  }

  return {
    slug,
    clientUrl,
    loanCategoryId: loanCategoryId || undefined,
  };
};

const getClientInfo = ({ clientInfo, maritalStatus, gender }) => {
  let data = {
    clientContactInfo: contactDetails,
    customerMaritalStatus: maritalStatus?.enumValues || [],
    customerGender: gender?.enumValues || [],
  };

  if (clientInfo) {
    let governmentId,
      utilityBill,
      employmentLetter,
      requiredSocialMediaAccounts,
      okraDirectDebitIsEnabled;

    setGlobalClientId(clientInfo.clientId);
    const requiredSupportingDocuments = clientInfo.requiredSupportingDocuments;

    let utilityBills = obj =>
      obj?.documentName?.toLowerCase() === 'utility bill';
    let userEmploymentLetter = obj =>
      obj.documentName?.toLowerCase() === 'employment letter';

    governmentId = clientInfo.requiresGovernmentId;
    utilityBill = requiredSupportingDocuments
      ? requiredSupportingDocuments.some(utilityBills)
      : '';
    employmentLetter = requiredSupportingDocuments
      ? requiredSupportingDocuments.some(userEmploymentLetter)
      : '';
    requiredSocialMediaAccounts = clientInfo.requiredSocialMediaAccounts;
    okraDirectDebitIsEnabled = clientInfo.okraDirectDebitIsEnabled;

    data = {
      ...data,
      ...clientInfo,
      governmentId,
      utilityBill,
      employmentLetter,
      requiredSocialMediaAccounts,
      okraDirectDebitIsEnabled,
      clientName: clientInfo?.name,
      clientContactInfo: clientInfo?.contactDetails || contactDetails,
      facebookAppId: clientInfo?.facebookAppKeys?.appId || '',
    };
  }
  return data;
};

export const ClientInfoContext = createContext({});

export const useClientInfo = ({ loanCategoryId } = {}) => {
  const variables = getVariables({ loanCategoryId });

  const { data, loading, error, refetch } = useQuery(CLIENT_INFO_QUERY, {
    variables,
  });

  const { data: maritalStatus } = useQuery(GET_ENUM, {
    variables: { type: 'MaritalStatus' },
  });

  const { data: gender } = useQuery(GET_ENUM, {
    variables: { type: 'Gender' },
  });

  return {
    clientInfo: getClientInfo({
      clientInfo: data?.clientInfo,
      gender,
      maritalStatus,
    }),
    refetch,
    loading,
    error,
    loanCategoryId,
  };
};

export const ClientInfoProvider = ({ children }) => {
  const value = useClientInfo();

  return (
    <ClientInfoContext.Provider value={value}>
      {children}
    </ClientInfoContext.Provider>
  );
};
