import React from 'react';
import { DataCard } from '../../components';
import { Link } from 'react-router-dom';
import { CheckIcon } from '../../components/Icon';

const PasswordResetComplete = () => {
  return (
    <section className="container sign-in">
      <DataCard title="Congratulations!">
        <div className="center-text">
          <CheckIcon />
        </div>
        <h5 className={'center-text'}>Password Reset Complete</h5>
        <div className="create-account">
          <Link to="/sign-in">Return To Sign In</Link>
        </div>
      </DataCard>
    </section>
  );
};

export default PasswordResetComplete;
