import { useMutation } from '@apollo/client';
import { removeCommas } from '../../../lib/utils';
import { MAKE_PAYSTACK_CARD_REPAYMENT } from './queries';

const MakePaystackCardRepaymentProvider = ({ children }) => {
  const [mutate] = useMutation(MAKE_PAYSTACK_CARD_REPAYMENT, {
    onError(...error) {
      return error;
    },
  });

  const makePaystackCardRepayment = data => {
    const { portfolioId, amount, accountCardId } = data;

    let amountPaid = parseFloat(removeCommas(amount));

    const payload = {
      portfolioId,
      amount: amountPaid,
      accountCardId,
    };

    return mutate({
      variables: payload,
    });
  };

  const props = {
    makePaystackCardRepayment,
  };

  return children(props);
};

export default MakePaystackCardRepaymentProvider;
