import React from 'react';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import { Formik, Form } from 'formik';
import { SubmitButton } from '../../../components/FormikElements';
import WorkIdPlaceholder from '../../../static/images/workid-placeholder.jpg';
import useBillsUploadForm from './useBillsUploadForm';

const UtilityBillsUpload = () => {
  const {
    uploadDocumentLoading,
    uploadDocumentError,
    utilityBillsDetails,
    handleUpload,
    handlePreview,
    goToPreviousPage,
  } = useBillsUploadForm();

  return (
    <S.Wrapper>
      <DataCard loading={uploadDocumentLoading}>
        <S.Title>
          Upload a photo or copy of your utility bill e.g Electricity bill,
          Water bill or Waste Bill
        </S.Title>
        {uploadDocumentError && (
          <Alert classes="error">{uploadDocumentError}</Alert>
        )}
        <Formik initialValues={{}} onSubmit={() => handleUpload()}>
          <Form>
            <S.ImageUpload>
              <input
                name="utilityBill"
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={handlePreview}
              />
              <section>
                <img
                  src={utilityBillsDetails.url || WorkIdPlaceholder}
                  alt="utilitybill"
                />
              </section>
            </S.ImageUpload>
            <SubmitButton value="Next" />
            <S.PreviousButton
              type="button"
              onClick={goToPreviousPage}
              value="Previous"
            />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default UtilityBillsUpload;
