import gql from 'graphql-tag';

export const APPROVE_UPDATED_LOAN_AMOUNT_MUTATION = gql`
  mutation ApproveUpdatedLoanAmountMutation(
    $applicationId: ID!
    $requestId: ID!
    $approve: Boolean!
  ) {
    approveUpdatedLoanAmount(
      input: {
        applicationId: $applicationId
        requestId: $requestId
        approve: $approve
      }
    ) {
      mbsFeedback {
        status
        message
        feedback
        requestId
      }
      application {
        fullAmount
        dateOfRepayment
        reviewDetails {
          rejectReason
        }
      }
      success
    }
  }
`;
