import { useMutation } from '@apollo/client';
import { UPLOAD_REQUESTED_DOCUMENT } from './queries';

const UploadRequestedDocumentProvider = ({ children }) => {
  const [mutate] = useMutation(UPLOAD_REQUESTED_DOCUMENT, {
    onError(...error) {
      return error;
    },
  });

  const uploadRequestedSupportingDocument = data => {
    const { requestId, requestedDocuments } = data;

    const payload = {
      requestId,
      requestedDocuments,
    };

    return mutate({
      variables: payload,
    });
  };

  const props = {
    uploadRequestedSupportingDocument,
  };

  return children(props);
};

export default UploadRequestedDocumentProvider;
