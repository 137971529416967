import { useState } from 'react';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';
import { CardService } from '../../../services';
import { usePaymentInformation } from '../../providers/Payment/PaymentInformationProvider';
import Constants from '../../../lib/constants';
import { useCurrentTime } from '../../providers';
import { useClientInfo } from '../../providers/Client/clientInfoContext';
import { NEW_LOAN_ROUTES } from '../routes';
const { status, duration } = Constants;

export const useConfirmDetails = () => {
  const history = useHistory();

  const {
    clientInfo: { termsAndConditionsUrl },
  } = useClientInfo();

  const [inputError] = useState('');
  const [errorWMailMessage] = useState('');
  const [defaultCardLoading, setDefaultCardLoading] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState('');
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const {
    data: {
      accountId,
      cards,
      defaultBankAccount,
      bankAccounts,
      loading: bankAccountsLoading,
    },
  } = usePaymentInformation();

  const timeProps = useCurrentTime();

  const handleSelectedBankAccountChange = e =>
    setSelectedBankAccount(e.target.value);

  const handleTAndCAcceptedChange = () =>
    setTermsAndConditionsAccepted(!termsAndConditionsAccepted);

  const handleDefaultCardChange = card => {
    return () => changeDefaultCard(card.id);
  };

  const changeDefaultCard = async id => {
    setDefaultCardLoading(true);
    const response = await CardService.setDefaultAccountCard(id, accountId);

    if (response?.status === 200 && response?.data.data != null) {
      const { setDefaultAccountCard } = response?.data.data;

      if (setDefaultAccountCard && setDefaultAccountCard.status === 'active') {
        notify.show(
          'Your primary card has been updated.',
          status.SUCCESS,
          duration.LONG,
        );
        setDefaultCardLoading(false);

        return;
      }
    }

    setDefaultCardLoading(false);
    notify.show(
      'There was an error updating your Primary card. Please, try again later.',
      status.ERROR,
      duration.LONG,
    );
  };

  const handleOnSubmit = () => {
    history.push(`${NEW_LOAN_ROUTES.newApplication}`);
  };

  return {
    termsAndConditionsUrl,
    inputError,
    errorWMailMessage,
    defaultCardLoading,
    selectedBankAccount,
    activeKey,
    setActiveKey,
    cards,
    timeProps,
    handleSelectedBankAccountChange,
    termsAndConditionsAccepted,
    handleTAndCAcceptedChange,
    handleDefaultCardChange,
    handleOnSubmit,
    defaultBankAccount,
    bankAccountsLoading,
    bankAccounts,
  };
};
