import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styled';
import { Eye, EyeOff } from 'lucide-react';
import Constants from '../../../lib/constants';
import { CREATE_CUSTOMER } from '../mutations';
import {
  isAbsolutePath,
  titleCase,
  trimGraphQLErrors,
} from '../../../lib/utils';
import { logEvent } from '../../../lib/GAHelper';
import config from '../../../config/config';
import { signUpValidationSchema } from '../../../lib/validationSchemas';
import {
  Checkbox,
  Input,
  NumberInput,
  SubmitButton,
} from '../../../components/FormikElements';
import { getClientId, useSignupContext } from '../../../hooks';
import { Alert, DataCard } from '../../../components';

const CreateNewAccount = ({ history }) => {
  const {
    signupState,
    setSignupState,
    createContextState,
    clientInfo: {
      slug: clientSlug,
      termsAndConditionsUrl,
      privacyPolicyUrl,
      howToApplyVideoLink,
      name: clientName,
    },
  } = useSignupContext();
  const { useGeoFence, checkedRadius, withinRadius } = signupState;
  const [createAccountError, setCreateAccountError] = useState(null);
  const [fieldType, setFieldType] = useState({
    password: 'password',
    confirmPassword: 'password',
  });
  const currentUrl = window.location.href;
  const clientId = getClientId();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [createAccountError]);

  const [createCustomerMutation, { loading: createCustomerLoading }] =
    useMutation(CREATE_CUSTOMER, {
      onError: error => {
        const errorMessage = trimGraphQLErrors(error);
        setCreateAccountError(
          errorMessage ||
            'Error creating your account. Please, try again later',
        );
      },
      onCompleted: data => {
        handleReturedData(data);
      },
    });

  const createCustomer = useCallback(
    async values => {
      logEvent('Signup', 'Create Customer.');

      createCustomerMutation({
        variables: {
          ...values,
          phone: values.phoneNumber.substring(1),
          clientId,
        },
      });

      setSignupState(prevState => ({
        ...prevState,
        phoneNumber: values.phoneNumber,
        email: values.email,
      }));
    },
    [signupState],
  );

  const handleReturedData = useCallback(
    ({ createCustomer }) => {
      const { token, user, account } = createCustomer;

      setSignupState(prevState => ({
        ...prevState,
        user,
        account,
      }));

      const authCreds = {
        apiKey: token,
        keyType: Constants.authTypes.TEMPORARY,
      };
      localStorage.setItem('Auth', JSON.stringify(authCreds));
      createContextState('minimum-requirements');
      history.push('/v1/sign-up/minimum-requirements');
    },
    [signupState],
  );

  const toggleInputType = field => {
    setFieldType(prevState => ({
      ...prevState,
      [field]: prevState[field] === 'password' ? 'text' : 'password',
    }));
  };

  if (
    config.web.node_env === 'production' &&
    useGeoFence &&
    currentUrl !== `https://${clientSlug}.indicina.net/` &&
    checkedRadius &&
    !withinRadius
  ) {
    return (
      <DataCard>
        <h3 className="center-text">Error</h3>
        <Alert classes="error">
          Sorry we can not offer you a loan because you're not within our
          service radius.
        </Alert>
      </DataCard>
    );
  }

  return (
    <S.Wrapper>
      <DataCard loading={createCustomerLoading}>
        <S.Title>{`Welcome to ${clientName}`}</S.Title>
        <S.Subtitle>
          {`New to ${clientName}? Create account to start applying for a loan.`}
        </S.Subtitle>

        {createAccountError && (
          <Alert classes="error">{createAccountError}</Alert>
        )}

        <Formik
          initialValues={{
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            termsandagreement: false,
            privacyPolicy: privacyPolicyUrl ? false : true,
          }}
          validationSchema={signUpValidationSchema}
          onSubmit={values => {
            createCustomer(values);
          }}
        >
          {({ values }) => (
            <Form>
              <>
                <Input name="email" type="email" placeholder="Email Address" />
                <NumberInput
                  phoneField
                  name="phoneNumber"
                  placeholder="07030000000"
                  maxLength="11"
                />

                <S.PasswordField>
                  <Input
                    name="password"
                    type={fieldType.password}
                    placeholder="Password"
                  />
                  <S.ToggleButton
                    type="button"
                    onClick={() => toggleInputType('password')}
                  >
                    {fieldType.password === 'password' ? (
                      <EyeOff color="gray" />
                    ) : (
                      <Eye color="gray" />
                    )}
                  </S.ToggleButton>
                </S.PasswordField>
                <S.PasswordField>
                  <Input
                    name="confirmPassword"
                    type={fieldType.confirmPassword}
                    placeholder="Confirm Password"
                  />
                  <S.ToggleButton
                    type="button"
                    onClick={() => toggleInputType('confirmPassword')}
                  >
                    {fieldType.confirmPassword === 'password' ? (
                      <EyeOff color="gray" />
                    ) : (
                      <Eye color="gray" />
                    )}
                  </S.ToggleButton>
                </S.PasswordField>

                <Checkbox name="termsandagreement">
                  I agree to the{' '}
                  {termsAndConditionsUrl ? (
                    <S.Link
                      href={isAbsolutePath(termsAndConditionsUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' Terms and Conditions '}
                    </S.Link>
                  ) : (
                    ' Terms and Conditions '
                  )}{' '}
                  of this loan
                </Checkbox>
                {privacyPolicyUrl && (
                  <Checkbox name="privacyPolicy">
                    We are NDPR Compliant. By using this form, you agree to the
                    storage and usage of your data by {titleCase(clientName)} in
                    accordance with our{' '}
                    <S.Link
                      href={isAbsolutePath(privacyPolicyUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy.
                    </S.Link>
                  </Checkbox>
                )}
              </>

              <SubmitButton
                disabled={
                  !values.phoneNumber ||
                  !values.email ||
                  !values.password ||
                  !values.confirmPassword ||
                  !values.termsandagreement ||
                  !values.privacyPolicy
                }
                type="submit"
                value="Start Application"
              />

              {howToApplyVideoLink && (
                <S.Aside>
                  Want to know how to apply for loans? Click here to{' '}
                  <S.LearnLink
                    href={isAbsolutePath(howToApplyVideoLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    learn.
                  </S.LearnLink>
                </S.Aside>
              )}
            </Form>
          )}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default CreateNewAccount;
