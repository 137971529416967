import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  Input,
  Select,
  NumberInput,
  SubmitButton,
} from '../../../../components/FormikElements';
import { selfEmployedSchema } from '../../../../lib/validationSchemas';
import { populateStates } from '../../../../lib/utils';
import useEmploymentDetailsForm from '../useEmploymentDetailsForm';

const SelfEmployedForm = ({ employmentStatus, setIsLoading }) => {
  const {
    currentEmploymentDetails,
    addEmploymentDetails,
    applicationLoading,
    loanApplicationIsLoading,
    userLoading,
  } = useEmploymentDetailsForm();

  useEffect(() => {
    setIsLoading(applicationLoading || loanApplicationIsLoading || userLoading);
  }, [applicationLoading, loanApplicationIsLoading, userLoading]);

  return (
    <Formik
      initialValues={{
        employmentStatus,
        employerName: currentEmploymentDetails?.employerName,
        employerAddress: currentEmploymentDetails?.employerAddress,
        employerState: currentEmploymentDetails?.employerState,
        netIncome: currentEmploymentDetails?.netIncome,
      }}
      validationSchema={selfEmployedSchema}
      onSubmit={values => {
        addEmploymentDetails(values);
      }}
    >
      <Form>
        <Input name="employerName" placeholder="Business Name" />

        <Input name="employerAddress" placeholder="Business Address" />

        <Select name="employerState">
          <option value="">-- Select State --</option>
          {populateStates()}
        </Select>

        <NumberInput
          name="netIncome"
          placeholder="How much is your monthly revenue ?"
          amountField
        />

        <SubmitButton value="Next" />
      </Form>
    </Formik>
  );
};

export default SelfEmployedForm;
