import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLazyQuery } from '@apollo/client';
import * as S from './styled';
import {
  formatNumber,
  numberWithCommas,
  removeCommas,
} from '../../../../../lib/utils';
import { useClientInfoContext } from '../../../../../hooks';
import { NumberInput } from '../../../../../components/FormikElements/Plain';
import { Select } from '../../../../../components/FormikElements';
import { CUSTOMER_ELIGIBILITY_AMOUNTS } from '../../../queries';
import { NairaAmount } from '../../../../../components/Icon';

const LoanAmountScreen = ({
  setIsLoading,
  applicationState,
  setApplicationState,
  setShowTenorField,
  setClientInfo,
  showTenorField,
}) => {
  const [eligibleAmounts, setEligibleAmounts] = useState([]);
  const { loanCategoryId, loanAmount } = applicationState;

  const { clientInfo, loading: clientInfoLoading } = useClientInfoContext({
    loanCategoryId,
  });

  const { minLoanAmount, maxLoanAmount } = clientInfo;

  const [getEligibleAmounts, { loading }] = useLazyQuery(
    CUSTOMER_ELIGIBILITY_AMOUNTS,
    {
      onCompleted({ customerAmountEligibility }) {
        if (customerAmountEligibility) {
          setEligibleAmounts(customerAmountEligibility);
        }
      },
      fetchPolicy: 'network-only',
    },
  );

  const validate = loanAmount => {
    if (!loanAmount || loanAmount === '.') {
      return 'Loan amount is required';
    }
    if (removeCommas(loanAmount) < minLoanAmount) {
      return `Minimum loan amount is NGN${formatNumber(minLoanAmount)}`;
    }
    if (removeCommas(loanAmount) > maxLoanAmount) {
      return `Maximum loan amount is NGN${formatNumber(maxLoanAmount)}`;
    }
  };

  const customerEligibleAmount = useMemo(() => {
    return eligibleAmounts.map(({ amount }, index) => (
      <option key={index} value={amount}>
        &#8358; {numberWithCommas(amount)}
      </option>
    ));
  }, [eligibleAmounts]);

  useEffect(() => {
    getEligibleAmounts({
      ...(loanCategoryId && { variables: { categoryId: loanCategoryId } }),
    });
  }, [loanCategoryId]);

  useEffect(() => {
    !clientInfoLoading && clientInfo && setClientInfo(clientInfo);
  }, [clientInfoLoading]);

  useEffect(() => {
    setIsLoading(loading || clientInfoLoading);
  }, [loading, clientInfoLoading]);

  const handleSubmit = () => {};

  const handleAmountUpdate = value => {
    if (!value) return;

    setApplicationState(state => ({
      ...state,
      loanAmount: parseFloat(removeCommas(value)),
      ...(parseFloat(removeCommas(value)) !== loanAmount && {
        loanDuration: null,
      }),
    }));

    setShowTenorField(true);
  };

  const handleAmountChange = () => {
    setApplicationState(state => ({
      ...state,
      loanDuration: null,
    }));

    setShowTenorField(false);
  };

  const handleAmountFocus = () => {
    setShowTenorField(false);
  };

  return (
    <section>
      <S.Title>How much do you want to borrow?</S.Title>
      {!loading && (
        <Formik
          initialValues={{
            loanAmount: '',
          }}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          <Form>
            {eligibleAmounts.length ? (
              <Field name="loanAmount">
                {({ field, meta }) => (
                  <Select
                    {...field}
                    {...meta}
                    value={loanAmount || ''}
                    onChange={e => {
                      field.onChange(e);
                      handleAmountUpdate(e?.target?.value);
                    }}
                  >
                    <option key="" value="">
                      -- Select Loan Amount --
                    </option>
                    {customerEligibleAmount}
                  </Select>
                )}
              </Field>
            ) : (
              <Field name="loanAmount" validate={validate} value={loanAmount}>
                {({ field, form, meta }) => (
                  <>
                    <NumberInput
                      placeholder="How much do you want to borrow"
                      amountField
                      field={field}
                      info={
                        <>
                          Loan range{' '}
                          {minLoanAmount && (
                            <>
                              from <NairaAmount amount={minLoanAmount} />
                            </>
                          )}{' '}
                          {minLoanAmount && (
                            <>
                              up to <NairaAmount amount={maxLoanAmount} />
                            </>
                          )}
                        </>
                      }
                      meta={meta}
                      onChange={e => {
                        field.onChange(e);
                        form.isValid && handleAmountChange(e?.target?.value);
                      }}
                      onFocus={() => {
                        handleAmountFocus();
                      }}
                    />

                    {!showTenorField && (
                      <S.SaveContinueButton
                        type="button"
                        disabled={meta?.error}
                        onClick={() => {
                          form.validateForm();
                          handleAmountUpdate(form?.values?.loanAmount);
                        }}
                      >
                        Continue
                      </S.SaveContinueButton>
                    )}
                  </>
                )}
              </Field>
            )}
          </Form>
        </Formik>
      )}
    </section>
  );
};

export default LoanAmountScreen;
