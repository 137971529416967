import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { handleGraphQLErrors } from '../../../lib/utils';
import { UPLOAD_SUPPORTING_DOCUMENT } from './queries';

const UploadSupportingDocumentProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(false);

  const [mutate] = useMutation(UPLOAD_SUPPORTING_DOCUMENT, {
    onError(error) {
      setErrorMessage(handleGraphQLErrors(error));
    },
  });

  const uploadSupportingDocumentFile = (selectedFile, documentName, userId) => {
    const { validity, file } = selectedFile;
    return (
      validity.valid &&
      mutate({
        variables: { file, documentName, userId },
      })
    );
  };

  const props = {
    uploadSupportingDocumentFile,
    errorMessage,
    setErrorMessage,
  };

  return children(props);
};

export default UploadSupportingDocumentProvider;
