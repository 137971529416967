import { useMutation } from '@apollo/client';
import { APPROVE_UPDATED_LOAN_AMOUNT_MUTATION } from './queries';

const LoanAdjustmentProvider = ({ children }) => {
  const [mutate] = useMutation(APPROVE_UPDATED_LOAN_AMOUNT_MUTATION, {
    onError(...error) {
      return error;
    },
  });

  const approveUpdatedLoanAmount = data => {
    const { applicationId, requestId } = data;

    const payload = {
      applicationId,
      requestId,
      approve: true,
    };

    return mutate({
      variables: payload,
    });
  };

  const declineUpdatedLoanAmount = data => {
    const { applicationId, requestId } = data;

    const payload = {
      applicationId,
      requestId,
      approve: false,
    };

    return mutate({
      variables: payload,
    });
  };

  const props = {
    approveUpdatedLoanAmount,
    declineUpdatedLoanAmount,
  };

  return children(props);
};

export default LoanAdjustmentProvider;
