import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  margin-top: ${({ type }) => (type === 'file' ? '0' : '3rem')};
  .input {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0 auto;
    outline: none;
    padding: ${({ theme }) => theme.to_rem('16px')} 0;
    transition: 0.3s;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border_color};
  }
`;

export const Input = styled.input`
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.label``;

export const NumberFormatContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_color};

  span {
    background-color: ${({ theme }) => theme.primaryColor.concat('1a')};
    padding: ${({ theme }) => theme.to_rem('6px')}
      ${({ theme }) => theme.to_rem('12px')};
    margin-right: ${({ theme }) => theme.to_rem('8px')};
    border-radius: 0.4rem;
    font-weight: 600;
  }
  .input {
    border-bottom: 0;
  }
`;

export const Error = styled.section`
  color: red;
  font-size: 1.1rem;
  margin: 0;
`;

export const Info = styled.p`
  margin: 5px 0 20px;
`;
