import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './_Radio.scss';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledRadio = styled.label`
  input {
    :checked + span {
      border-color: ${props => props.theme.secondaryColor};
      color: ${props => props.theme.secondaryColor} !important;

      :before {
        background-color: ${props => props.theme.secondaryColor};
        border-color: ${props => props.theme.secondaryColor};
        box-shadow: 0 3px 3px
          ${props => transparentize(0.5, props.theme.secondaryColor)};
      }
    }
  }
  span {
    :before {
      border: 1px solid ${props => props.theme.secondaryColor};
    }
  }
`;

const Radio = forwardRef(
  ({ classes, label, disabled, noBorder, ...restProps }, ref) => {
    return (
      <StyledRadio
        className={`radio ${
          disabled
            ? 'radio--disabled'
            : noBorder
            ? 'radio--noBorder'
            : classes == null
            ? ''
            : classes
        }`}
        {...restProps}
      >
        <input
          className={`${classes == null ? '' : classes}`}
          type="radio"
          ref={ref}
          {...restProps}
        />
        <span className="radio__label">{label}</span>
      </StyledRadio>
    );
  },
);

Radio.displayName = 'Radio';

Radio.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Radio;
