import { useState, useCallback, useEffect } from 'react';
import { bytesToSize, handleGraphQLErrors } from '../../../lib/utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { UPLOAD_BANK_STATEMENT } from '../mutations';
import { pages } from '../../../lib/constants';
import { GET_DECIDE_JOB_STATUS } from '../queries';
import { useBanks } from '../BankAccount/useBankAccount';

const useBankStatementUploaderForm = ({
  customForm,
  handleNext,
  formIndex,
}) => {
  const [uploadBankStatementError, setUploadBankStatementError] =
    useState(null);

  const {
    loanApplicationState,
    setLoanApplicationState,
    updateContextState,
    createApplicationTrace,
    application,
    applicationLoading,
    setCompletedStep,
    account,
    userLoading,
    getUserViewer,
    clientInfo: { allowBankSelectionOnPdfUpload }
  } = useLoanApplicationContext();

  const { banks, loading: banksLoading } = useBanks({ filtered: false })

  const [pollingPage, setPollingPage] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const applicationBank = application?.application?.bankAccount || account?.bankAccounts[0];

  const [mutate, { data, loading: uploadBankStatementLoading }] = useMutation(
    UPLOAD_BANK_STATEMENT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setUploadBankStatementError(
          errorMessage ||
            'Unable to upload file. Check file format and try again later.',
        );
      }
    },
  );

  const [ getDecideJobStatus, { loading: decideJobStatusLoading, data: decideJobStatusData, startPolling, stopPolling } ] = useLazyQuery(GET_DECIDE_JOB_STATUS, {
    variables: { input: { applicationId: application?.application?.id } },
    skip: !application?.application?.id,
    fetchPolicy: 'no-cache',
  });

  const getUploadingStatus = useCallback(async () => {
      const { data } = await getDecideJobStatus();
      
    const { bankStatementId, message, status } = data.getDecidePdfStatus;

    if(status === "IN_PROGRESS") {
      setPollingPage(true)
    }

    if(status === "FAILED") {
      setPollingPage(false)
      setUploadBankStatementError(message);
    }

    return {
      bankStatementId,
      message,
      status
    }
  }, [application]);

  const uploadBankStatement = useCallback(
    (bankStatement, bankId, documentPassword, applicationId) => {
      if (!bankStatement) {
        setUploadBankStatementError('Please select a pdf file for upload');
        return;
      }

      const { validity, file } = bankStatement;
      if (validity) {
        mutate({
          variables: {
            file,
            password: documentPassword,
            bankId,
            applicationId,
          },
          onCompleted: () => {
            setPollingPage(true);
            setIsPolling(true);
          },
        });
      }
    },
    [setPollingPage, setIsPolling],
  );

  const [bankStatementDetails, setBankStatementDetails] = useState({});

  const handleDocumentPreview = useCallback(e => {
    const { validity, files } = e.target;
    const file = files[0];

    if (!file || file.size > 10000000 || file.type !== 'application/pdf') {
      setUploadBankStatementError(
        'Only .pdf files are allowed with size lower than 10MB. Please check the file format and try again.',
      );
      return;
    }

    setUploadBankStatementError('');
    setBankStatementDetails({
      fileName: file.name,
      fileSize: bytesToSize(file.size),
      bankStatement: { file, validity },
    });
  }, []);

  const handleUpload = useCallback(
    ({ statementPassword, bankId = applicationBank?.bank?.id  }) => {
      logEvent('Signup', 'Upload Bank Statement');
        const selectedBankId = bankId?.length > 0 ? bankId : applicationBank?.bank?.id;
        uploadBankStatement(
        bankStatementDetails.bankStatement,
        selectedBankId,
        statementPassword,
        application.application?.id,
      );
    },
    [bankStatementDetails, account, application],
  );

  useEffect(() => {
    if (uploadBankStatementError) {
      setTimeout(() => setUploadBankStatementError(''), 180000);
    }
  }, [uploadBankStatementError]);

  useEffect(() => {
    if (data) {
      const {
        uploadBankStatement: { filename },
      } = data;
      setLoanApplicationState(prevState => ({
        ...prevState,
        bankStatementFilename: filename,
      }));
    }
  }, [data]);

  useEffect(() => {
    getUserViewer()
    const { isFromOkraPage } = loanApplicationState;

    updateContextState('upload-bank-statement', {
      applicationId: application?.application?.id,
      bankId: applicationBank?.bank?.id,
      isFromOkraPage,
    });
  }, []);

  useEffect(() => {
    if(application?.application?.id) {
      createApplicationTrace(
      pages.bankStatementUpload,
      'Navigated to Bank Statement Upload Screen',
    );
    }
  }, [application]);

  useEffect(() => {
    const runStatus = async () => {
      const pdfJob = await getUploadingStatus();
      if(pdfJob?.status === "IN_PROGRESS") {
        setPollingPage(true);
        setIsPolling(true);
      }
    }
    if(application?.application?.id) {
      runStatus();
    }
  }, [application])
  
  const stopPdfJobPolling = () => {
    stopPolling();
    setCompletedStep('bankStatementUpload');
    if (customForm) {
      handleNext({
        variables: {
          applicationId: application.application?.id,
          data: { step: formIndex + 1, path: '', subStep: null },
        },
      });
    }
  }

  useEffect(() => {
    let isMounted = true;
  
    const startPdfJobPolling = async () => {
      if (pollingPage) {
        startPolling(15000);
        await new Promise(resolve => setTimeout(resolve, 90000));
  
        if (isMounted) {
          setIsPolling(false);
        }
      }
    };
  
    startPdfJobPolling();
  
    return () => {
      isMounted = false;
    };
  }, [pollingPage]);
  

  useEffect(() => {
    if(isPolling && decideJobStatusData) {
      switch (decideJobStatusData.getDecidePdfStatus?.status) {
        case 'DONE':
          stopPdfJobPolling()
          break;
        case 'SKIPPED':
          stopPdfJobPolling()
          break;
        case 'FAILED':
          setUploadBankStatementError(decideJobStatusData?.getDecidePdfStatus?.message || 'Please re-upload')
          stopPolling()
          setPollingPage(false)
          break;
        default:
          break;
      }
    }
    if (!isPolling && decideJobStatusData?.getDecidePdfStatus?.status === 'IN_PROGRESS') {
      stopPdfJobPolling()
    }
  }, [isPolling, decideJobStatusData])

  return {
    applicationLoading,
    uploadBankStatementLoading,
    uploadBankStatementError,
    handleUpload,
    handleDocumentPreview,
    bankStatementDetails,
    userLoading,
    getUploadingStatus,
    decideJobStatusLoading,
    pollingPage,
    applicationBank,
    banks,
    banksLoading,
    allowBankSelectionOnPdfUpload,
  };
};

export default useBankStatementUploaderForm;
