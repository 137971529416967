import React from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  DataCard,
  Input,
  PasswordInput,
} from '../../components';
import * as S from './styled';
import { LoginProvider } from '../providers';
import './_SignIn.scss';
import { useClientInfoContext } from '../../hooks';
import { isAbsolutePath } from '../../lib/utils';

const SignIn = () => {
  const { clientInfo } = useClientInfoContext();

  return (
    <LoginProvider>
      {({ onSubmit, loading, errorMessage, successMessage, form }) => (
        <section className="container sign-in">
          <DataCard title={`Welcome to ${clientInfo?.clientName}`}>
            <div className="form-wrapper">
              <div className="signup-container">
                <p className="title">Create Account</p>
                New to {clientInfo?.clientName}? Create account to start
                applying for a loan.
                <Link to="/sign-up" className="button">
                  Sign up
                </Link>
                {clientInfo?.howToApplyVideoLink && (
                  <div>
                    Want to know how to apply for loans? Click here to{' '}
                    <S.Link
                      href={isAbsolutePath(clientInfo?.howToApplyVideoLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      learn
                    </S.Link>
                    .
                  </div>
                )}
              </div>

              <div className="divider" />

              <div className="form-container">
                <p className="title">Login</p>
                Already have an account? Login below.
                <form onSubmit={onSubmit}>
                  {errorMessage && (
                    <Alert classes="error">{errorMessage}</Alert>
                  )}
                  {successMessage && (
                    <Alert classes="success">{successMessage}</Alert>
                  )}
                  <Input
                    type="email"
                    data-testid="email-field"
                    placeholder="Email Address"
                    name="email"
                    autoFocus
                    classes={`border-bottom ${
                      form.email.input.errorMessage ? 'error' : ''
                    }`}
                    {...form.email.input}
                  />
                  <PasswordInput
                    data-testid="password-field"
                    placeholder="Password"
                    name="password"
                    classes={`border-bottom ${
                      form.password.input.errorMessage ? 'error' : ''
                    }`}
                    {...form.password.input}
                  />
                  <Button
                    data-testid="signin-button"
                    type="submit"
                    classes={`center ${loading ? 'loading' : ''}`}
                  >
                    Sign In
                  </Button>

                  <div className="create-account">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                </form>
              </div>
            </div>
          </DataCard>
        </section>
      )}
    </LoginProvider>
  );
};

export default SignIn;
