import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { initializeGA, logPageView } from '../../lib/GAHelper';
import { useClientInfoContext } from '../../hooks';
import { distanceInKm } from '../../lib/utils';

export const SignupContext = createContext({});

export const SignupProvider = ({ children }) => {
  const [signupState, setSignupState] = useState({});
  const {
    clientInfo: { allowedGeoLocationBoundaries },
  } = useClientInfoContext();

  const checkGeoLocationSupport = useCallback(() => {
    if (!('geolocation' in navigator)) {
      setSignupState(prevState => ({ ...prevState, locationError: true }));
      return false;
    }
    return true;
  }, []);

  const handlePosition = useCallback(
    position => {
      const { latitude, longitude } = position.coords;
      const useGeoFence = allowedGeoLocationBoundaries?.length;
      setSignupState(prevState => ({
        ...prevState,
        latitude,
        longitude,
        useGeoFence,
      }));
    },
    [allowedGeoLocationBoundaries],
  );

  useEffect(() => {
    if (!allowedGeoLocationBoundaries?.length) return;
    if (checkGeoLocationSupport()) {
      navigator.geolocation.getCurrentPosition(handlePosition, () => {
        setSignupState(prevState => ({ ...prevState, locationError: true }));
      });
    }
  }, [allowedGeoLocationBoundaries, checkGeoLocationSupport, handlePosition]);

  useEffect(() => {
    const { latitude, longitude, useGeoFence } = signupState;
    if (!latitude || !longitude) return;

    const checkWithinRadius = () => {
      const pointLocations = allowedGeoLocationBoundaries;
      for (let i = 0; i < pointLocations.length; i++) {
        const dist = distanceInKm(
          latitude,
          longitude,
          pointLocations[i].lat,
          pointLocations[i].lon,
        );
        if (useGeoFence && Math.ceil(dist) <= pointLocations[i].radius) {
          return true;
        }
      }
      return false;
    };

    const withinRadius = checkWithinRadius();
    setSignupState(prevState => ({
      ...prevState,
      checkedRadius: true,
      withinRadius,
    }));
  }, [
    signupState.latitude,
    signupState.longitude,
    allowedGeoLocationBoundaries,
  ]);

  useEffect(() => {
    setSignupState(prevState => ({
      ...prevState,
      closePayStack: false,
    }));
    initializeGA();
    logPageView();
  }, []);

  return (
    <SignupContext.Provider value={{ signupState, setSignupState }}>
      {children}
    </SignupContext.Provider>
  );
};

export const useSignUp = () => {
  const { signupState, setSignupState } = useContext(SignupContext);
  return {
    signupState,
    setSignupState,
  };
};
