const handleCustomNext = ({ path, applicationId, handleNext, history }) => {
  handleNext({
    variables: {
      applicationId: applicationId,
      data: { path },
    },
  });
  history.push(path);
};

const getPath = ({ applicationNumber, path }) =>
  `/application-custom/${applicationNumber}/${path}`;

export const handleCustomFormKyc = ({
  pageRenderConditions,
  applicationId,
  handleNext,
  history,
  applicationNumber,
  currentStepCompleted,
}) => {
  const steps = [
    {
      condition: pageRenderConditions?.requiresPersonalEmailVerify,
      path: 'verify-email',
    },
    {
      condition: pageRenderConditions?.requiresPhoneVerification,
      path: 'verify-phone',
    },

    {
      condition: pageRenderConditions?.requiresWorkIDUpload,
      path: 'upload-work-id',
    },
    {
      condition: pageRenderConditions?.requiresSelfieUpload,
      path: 'upload-selfie',
    },
    { condition: pageRenderConditions?.requiresReferee, path: 'referee' },
    {
      condition: pageRenderConditions?.requiresGovernmentId,
      path: 'upload-id',
    },
    {
      condition: pageRenderConditions?.requireWorkEmail,
      path: 'verify-work-email',
    },
  ];

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    if (step.condition && !currentStepCompleted) {
      return;
    } else if (step.condition && currentStepCompleted) {
      handleCustomNext({
        path: getPath({ applicationNumber, path: step.path }),
        applicationId,
        handleNext,
        history,
      });
      return;
    }
  }

  handleCustomNext({
    path: getPath({ applicationNumber, path: '' }),
    applicationId,
    handleNext,
    history,
  });
};
