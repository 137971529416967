import React from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from '../../../components';
import { FETCH_FROM_S3_QUERY } from './queries';

const CdnFileProvider = ({ children, file }) => {
  const { bucket, key } = file;

  const { loading, error, data } = useQuery(FETCH_FROM_S3_QUERY, {
    variables: { bucket, key },
  });

  const MEDIA_FORMATS = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf',
    doc: 'application/msword',
    xls: 'application/vnd.ms-excel',
    csv: 'text/csv',
    html: 'text/html',
  };

  const extentions = key.split('.')[1];

  const mimeType = MEDIA_FORMATS[extentions];

  if (loading) {
    return <Loader />;
  }

  const props = {
    loading,
    error,
    fetchFromS3: {},
    fileUrl: '',
    openFile: () => '',
  };

  const base64toBlob = (base64Str, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Str);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  if (data) {
    const { fetchFromS3 } = data;
    props.fetchFromS3 = fetchFromS3;
    props.fileUrl = `${mimeType}${fetchFromS3.dataUrl}`;
    props.openFile = () => {
      const blob = base64toBlob(fetchFromS3.dataUrl, mimeType);
      const url = window.URL.createObjectURL(blob);
      const newTab = window.open();
      newTab.location.href = url;
    };
  }
  return children(props);
};

export default CdnFileProvider;
