import React, { useCallback, useMemo, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLazyQuery } from '@apollo/client';
import * as S from './styled';
import { APPLICABLE_TENORS } from '../../../queries';
import { Select } from '../../../../../components/FormikElements';
import {
  formatLoanDuration,
  validateCardAndDuration,
} from '../../../../../lib/utils';

const LoanTenor = ({
  setIsLoading,
  clientInfo,
  viewerData,
  applicationState,
  setApplicationState,
}) => {
  const { loanMonthlyDurations, clientId } = clientInfo;
  const { loanAmount, loanCategoryId, loanDuration } = applicationState;
  const [getLoanTenors, { loading, data }] = useLazyQuery(APPLICABLE_TENORS);

  const getLoanOptions = useCallback(tenors => {
    return tenors.map((loanItem, index) => (
      <option
        key={index}
        value={`${loanItem.duration} ${loanItem.durationType}`}
        data-durationtype={loanItem.durationType}
      >
        {formatLoanDuration(`${loanItem.duration} ${loanItem.durationType}`)}
      </option>
    ));
  }, []);

  const tenors = useMemo(() => {
    let tenors = [];
    if (data) {
      const { applicableTenors } = data;
      if (!applicableTenors || applicableTenors.length === 0) {
        tenors = loanMonthlyDurations && getLoanOptions(loanMonthlyDurations);
      } else if (applicableTenors && applicableTenors.length !== 0) {
        tenors = getLoanOptions(applicableTenors);
      }
      return tenors;
    }
  }, [data, loanMonthlyDurations]);

  useEffect(() => {
    getLoanTenors({
      variables: {
        clientId,
        customerPhoneNumber: viewerData?.loaded?.viewer?.me?.phone,
        loanAmount,
        ...(loanCategoryId && { categoryId: loanCategoryId }),
      },
    });
  }, [clientId, loanAmount, loanCategoryId]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleSubmit = () => {};

  const validate = value => {
    if (applicationState.cards?.length) {
      const validateCard = validateCardAndDuration(
        value,
        applicationState.cards,
      );
      if (!validateCard.valid) {
        setApplicationState(state => ({
          ...state,
          loanDuration: null,
        }));
        return validateCard.message;
      }
    }
  };

  const handleTenorChange = value => {
    setApplicationState(state => ({
      ...state,
      loanDuration: value,
    }));
  };

  return (
    <section>
      <S.Title>What tenor would you prefer?</S.Title>
      <Formik
        initialValues={{
          loanDuration: '',
        }}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        <Form>
          <Field name="loanDuration" validate={validate}>
            {({ field, meta }) => (
              <Select
                {...field}
                {...meta}
                value={loanDuration || ''}
                onChange={e => {
                  field.onChange(e);
                  handleTenorChange(e?.target?.value);
                }}
              >
                <option value="">-- Select Tenor --</option>
                {tenors}
              </Select>
            )}
          </Field>
        </Form>
      </Formik>
    </section>
  );
};

export default LoanTenor;
