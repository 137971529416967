import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';
import { VIEWER_QUERY } from '../containers/providers/User/queries';

export const UPDATE_USER_DATA = gql`
  mutation ($number: String!, $validateBvn: Boolean) {
    updateUserData(
      input: { bvn: { number: $number, validateBvn: $validateBvn } }
    ) {
      success
    }
  }
`;

export const useUpdateUserData = () => {
  const [updateUserDataResponse, setUpdateUserDataResponse] = useState(null);

  const [updateUserDataMutation, { loading, error }] = useMutation(
    UPDATE_USER_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setUpdateUserDataResponse(data?.updateUserData);
      },
    },
  );

  const updateUserData = ({ number, validateBvn }) => {
    updateUserDataMutation({
      variables: {
        number,
        validateBvn,
      },
      refetchQueries: () => [
        {
          query: VIEWER_QUERY,
        },
      ],
    });
  };

  return {
    updateUserDataResponse,
    updateUserData,
    updateUserDataIsLoading: loading,
    updateUserDataError: error,
  };
};
