import React from 'react';
import './_SignUpStep.scss';
import PropTypes from 'prop-types';
import { Step } from 'react-albus/lib';
import styled from 'styled-components';

const StyledDiv = styled.section`
  .checkbox-group {
    .checkbox {
      border-color: ${props => props.theme.secondaryColor};
    }
  }
`;

const StyledAside = styled.aside`
  a {
    color: ${props => props.theme.secondaryColor};
  }
`;
const SignUpStep = ({
  title,
  smallTitle,
  subTitle,
  message,
  note,
  footer,
  children,
}) => {
  return (
    <Step>
      <StyledDiv className="sign-up-step">
        {!title ? (
          ''
        ) : (
          <h4
            className={`sign-up-step__title ${smallTitle ? 'small-title' : ''}`}
          >
            {title}
          </h4>
        )}
        {subTitle && <p className="sign-up-step__sub-title">{subTitle}</p>}
        <div className={'sign-up-step__content'}>{children}</div>
        {message ? <aside className="message">{message}</aside> : ''}
        {note ? <aside className="note">*NOTE: {note}</aside> : ''}
      </StyledDiv>

      <div>
        {footer ? (
          <StyledAside className="sign-up-step__footer">{footer}</StyledAside>
        ) : (
          ''
        )}
      </div>
    </Step>
  );
};

SignUpStep.propTypes = {
  note: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classes: PropTypes.string,
  smallTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default SignUpStep;
