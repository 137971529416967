import React from 'react';
import * as S from './styled';
import { DataCard, Alert, Popup } from '../../../components';
import useOkraPageForm from './useOkraPageForm';

const OkraPage = () => {
  const {
    okraError,
    bankStatementError,
    loanApplicationIsLoading,
    clientInfo: { okraDirectDebitIsEnabled },
    initiateOkra,
    loading,
    setBankStatementError,
    handleRedirect,
    handleSkip,
  } = useOkraPageForm();

  return (
    <S.Wrapper>
      <DataCard loading={loanApplicationIsLoading || loading}>
        <S.Title>
          {okraDirectDebitIsEnabled
            ? 'Bank Statement and Direct Debit Authorization'
            : 'Bank Statement'}
        </S.Title>
        {okraError && <Alert classes="error">{okraError}</Alert>}
        {bankStatementError && (
          <Popup title="Bank Statement Error" classes="loan-requirements">
            <S.Subtitle>
              There was an error fetching your bank statement. Please try again
              or click on upload Bank Statement to continue with your
              application
            </S.Subtitle>
            <S.TwoColumn>
              <S.NextButton
                type="button"
                value="Try Again"
                onClick={() => {
                  setBankStatementError(false);
                }}
              />
              <S.PrevBtn
                type="button"
                value="Upload Bank Statement"
                onClick={() => {
                  handleRedirect();
                }}
              />
            </S.TwoColumn>
          </Popup>
        )}
        <S.Subtitle>
          As part of your loan application, we connect to Okra to fetch your
          bank statement{' '}
          {okraDirectDebitIsEnabled &&
            `and authorize Okra for direct debits on your recurring payments`}
          . This improves your chances of getting a loan. We value security
          therefore transfer of your information is encrypted and your login
          credentials will never be made accessible to us by Okra.
        </S.Subtitle>
        {okraDirectDebitIsEnabled && (
          <S.Aside>
            Please Note: Once you authorize direct debit, your monthly
            repayments will be deducted from the bank account you have
            authorized
          </S.Aside>
        )}
        <S.Subtitle>
          Linking your bank account does not give us access to transfer money
          from your bank account without your permission. To proceed with Okra
          you will need your Internet or Mobile App login credentials
        </S.Subtitle>
        <S.Aside>
          Having trouble starting Okra? Please reload this page if the Okra
          widget takes time to process your account.
        </S.Aside>
        <S.NextButton
          type="button"
          value="Connect with Okra"
          onClick={() => {
            initiateOkra();
          }}
        />
        <S.SkipButton
          type="button"
          onClick={handleSkip}
          value="Skip to Upload Bank Statement"
        />
      </DataCard>
    </S.Wrapper>
  );
};

export default OkraPage;
