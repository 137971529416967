import React, { Fragment, useEffect, useState } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import {
  Alert,
  Button,
  CheckBox,
  DataCard,
  FormGroup,
  Loader,
  Input,
  Radio,
  RadioGroup,
} from '../../../components';
import { ViewerProvider, UpdateUserDetailsProvider } from '../../providers';
import * as S from './styled';
import { useConfirmDetails } from './useConfirmDetails';
import '../_NewLoan.scss';
import { useUserViewer } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { NEW_LOAN_ROUTES } from '../routes';

const ConfirmDetails = ({ page }) => {
  const {
    termsAndConditionsUrl,
    inputError,
    errorWMailMessage,
    defaultCardLoading,
    activeKey,
    setActiveKey,
    cards,
    timeProps,
    termsAndConditionsAccepted,
    handleTAndCAcceptedChange,
    handleDefaultCardChange,
    handleOnSubmit,
  } = useConfirmDetails({ page });
  const { viewerData, getUserViewer } = useUserViewer();
  const history = useHistory();
  const [existingUser, setExistingUser] = useState(false);

  const goToApplicationPage = applicationNumber => {
    return `/application/${applicationNumber}`;
  };

  useEffect(() => {
    if (viewerData?.loaded && !viewerData?.loaded?.viewer) {
      return getUserViewer();
    }

    if (viewerData?.loaded?.viewer) {
      const applications = viewerData?.loaded?.viewer?.account?.applications?.nodes;

      const pending = applications?.find(
        application => application?.status?.name === 'PENDING',
      );

      if (applications?.length === 0) {
        history.push(`/application/${NEW_LOAN_ROUTES.newApplication}`);
        return;
      }

      if (pending) {
        history.push(goToApplicationPage(pending?.applicationNumber));
        return;
      }

      setExistingUser(true);
    }
  }, [viewerData]);

  if (viewerData?.loaded || !existingUser) return <></>;

  return (
    <S.Wrapper>
      <DataCard>
        {inputError && <Alert classes="error">{inputError}</Alert>}
        <h2 className="center-text">New Loan Application</h2>
        <h5 className="center-text">
          Please confirm if your details are up to date
        </h5>

        <Collapse
          accordion={true}
          activeKey={activeKey}
          className="new-loan-accordion"
          onChange={setActiveKey}
        >
          <Panel header="Employment" showArrow={false}>
            <ViewerProvider>
              {({ user }) => (
                <UpdateUserDetailsProvider user={user} timeProps={timeProps}>
                  {({
                    form,
                    onSubmit,
                    loading,
                    errorMessage,
                    successMessage,
                  }) => (
                    <section>
                      <h6>Employment Status</h6>

                      <RadioGroup classes="two-grid-column">
                        <Radio
                          label="Employed Full Time"
                          name="employmentStatus"
                          {...form.employmentStatus.input('Employed Full Time')}
                        />
                        <Radio
                          label="Employed Part Time"
                          name="employmentStatus"
                          {...form.employmentStatus.input('Employed Part Time')}
                        />
                        <Radio
                          label="Self Employed"
                          name="employmentStatus"
                          {...form.employmentStatus.input('Self Employed')}
                        />
                        <Radio
                          label="Unemployed"
                          name="employmentStatus"
                          {...form.employmentStatus.input('Unemployed')}
                        />
                      </RadioGroup>

                      <FormGroup classes="two-grid">
                        <Input
                          label="Company Name"
                          classes="border-bottom margin-bottom"
                          placeholder=""
                          name="companyName"
                          {...form.employerName.input}
                        />

                        <Input
                          label="Current Position"
                          classes="border-bottom margin-bottom"
                          placeholder=""
                          name="companyName"
                          {...form.jobRole.input}
                        />
                      </FormGroup>

                      <h6>Employment duration</h6>
                      <RadioGroup classes="three-grid-column">
                        <Radio
                          label="< 1 Year"
                          name="employmentDuration"
                          {...form.workStartDate.input(timeProps.oneMonthAgo)}
                        />
                        <Radio
                          label="1 - 3 Years"
                          name="employmentDuration"
                          {...form.workStartDate.input(timeProps.oneYearAgo)}
                        />
                        <Radio
                          label="4 - 6 Years"
                          name="employmentDuration"
                          {...form.workStartDate.input(timeProps.fourYearsAgo)}
                        />
                        <Radio
                          label="7 - 9 Years"
                          name="employmentDuration"
                          {...form.workStartDate.input(timeProps.sevenYearsAgo)}
                        />
                        <Radio
                          label="> 10 Years"
                          name="employmentDuration"
                          {...form.workStartDate.input(timeProps.tenYearsAgo)}
                        />
                      </RadioGroup>

                      <Input
                        label="company address"
                        classes="border-bottom margin-bottom"
                        placeholder=""
                        name="companyAddress"
                        {...form.workAddress.input}
                      />

                      <Input
                        label="Work Email"
                        classes="border-bottom margin-bottom"
                        placeholder=""
                        name="workEmail"
                        {...form.workEmail.input}
                      />

                      <Input
                        label="Net monthly income"
                        classes="border-bottom margin-bottom"
                        placeholder=""
                        amountField
                        name="netMonthlyIncome"
                        {...form.netIncome.input}
                      />

                      {errorWMailMessage && (
                        <Alert classes="error">{errorWMailMessage}</Alert>
                      )}
                      {errorMessage && (
                        <Alert classes="error">{errorMessage}</Alert>
                      )}
                      {successMessage && (
                        <Alert classes="success">{successMessage}</Alert>
                      )}
                      {loading ? (
                        <Loader />
                      ) : (
                        <Button
                          click_event={onSubmit}
                          classes="secondary center"
                        >
                          Update Employment
                        </Button>
                      )}
                    </section>
                  )}
                </UpdateUserDetailsProvider>
              )}
            </ViewerProvider>
          </Panel>

          <Panel header="Repayment" showArrow={false}>
            {cards.length > 0 ? (
              <Fragment>
                <h6>Select card to pay Repayments from</h6>
                <RadioGroup classes="card-repayment">
                  {cards.map((card, index) => (
                    <Radio
                      name="defaultCard"
                      classes={card.type}
                      label={`${card.type} - ${card.maskedPan.slice(-4)}`}
                      defaultChecked={card.isDefault}
                      key={index}
                      onChange={handleDefaultCardChange(card)}
                    />
                  ))}
                </RadioGroup>
                {defaultCardLoading && <Loader />}
              </Fragment>
            ) : (
              <Alert classes="error">
                You have not added any card yet. Please, add one to continue
              </Alert>
            )}
          </Panel>
        </Collapse>

        <label className="newLoan-Tandc">
          <CheckBox
            type="checkbox"
            defaultChecked={termsAndConditionsAccepted}
            onChange={handleTAndCAcceptedChange}
            name="termsAgreed"
            classes="checkbox"
          />
          I agree to the{' '}
          <a
            href={termsAndConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>{' '}
          of this loan
        </label>
        <Button classes="block next" click_event={handleOnSubmit}>
          Next
        </Button>
      </DataCard>
    </S.Wrapper>
  );
};

export default ConfirmDetails;
