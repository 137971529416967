import { useLoanApplicationContext } from '../../../hooks';
import { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import { validateSocialMediaUrl } from '../../../lib/utils';
import { pages } from '../../../lib/constants';

const useSocialMediaForm = () => {
  const {
    createApplicationTrace,
    updateUserDetails,
    loanApplicationIsLoading,
    clientInfo: { requiredSocialMediaAccounts },
    goToPreviousPage,
    goToNextPage,
    setCompletedStep,
  } = useLoanApplicationContext();

  const [socialMedia, setSocialMedia] = useState([]);
  const [checked] = useState(false);
  const [checkboxError] = useState(false);
  const [inputErrors] = useState(false);
  const [valuesUpdated, setValuesUpdated] = useState([]);
  let myColor = { background: '#0E1717', text: '#FFFFFF' };

  const handleProfileUrlSubmit = async () => {
    if (valuesUpdated.length === requiredSocialMediaAccounts.length) {
      updateUserDetails(
        {
          socialMediaInformation: [...valuesUpdated],
        },
        () => {
          setCompletedStep('socialMedia');
          goToNextPage();
        },
      );
    } else {
      if (!socialMedia.length) {
        notify.show(
          'Please select a social media platform',
          'error',
          4000,
          myColor,
        );
      } else {
        notify.show(
          'Please select all the available checkboxes and provide a valid profile URL for the selected social media account',
          'error',
          4000,
          myColor,
        );
      }
    }
  };

  const handleOnChange = event => {
    const { value } = event.target;
    if (
      socialMedia.length &&
      socialMedia.some(item => item.provider === value)
    ) {
      let filteredArray = socialMedia.filter(item => item.provider !== value);
      setSocialMedia(filteredArray);
    } else {
      setSocialMedia(prev => [
        ...prev,
        { provider: value, profileUrl: '', profileId: value },
      ]);
    }
    return socialMedia;
  };

  const checkSocialMediaUrl = (provider, url) => {
    if (socialMedia.length && socialMedia.length > 0) {
      let filteredArray = socialMedia.find(
        item => item.profileUrl === url && item.provider === provider,
      );

      setValuesUpdated(prev => {
        let prevState = prev.filter(item => item.provider !== provider);
        return [...prevState, filteredArray];
      });
    }
  };

  const validateMediaUrl = e => {
    const { name, value } = e.target;

    if (validateSocialMediaUrl(name, value)) {
      checkSocialMediaUrl(name, value);
    } else {
      notify.show(`Please enter a valid ${name} URL`, 'error', 4000, myColor);
    }
  };

  useEffect(() => {
    createApplicationTrace(
      pages.socialMedia,
      'Navigated to Social Media Details Screen',
    );
  }, []);

  return {
    validateMediaUrl,
    handleOnChange,
    handleProfileUrlSubmit,
    goToPreviousPage,
    loanApplicationIsLoading,
    clientInfo: { requiredSocialMediaAccounts },
    checked,
    checkboxError,
    inputErrors,
    socialMedia,
    setSocialMedia,
  };
};

export default useSocialMediaForm;
