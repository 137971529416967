import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const NewCheck = ({ ...restProps }) => {
  const theme = useContext(ThemeContext);

  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx={50} cy={50} r={50} fill={theme.secondaryColor} />
      <path
        d="M28 50.691L43.207 66 74 35"
        stroke={`#ffffff`}
        fillOpacity={0.1}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewCheck;
