import React, { useMemo, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import { GET_REPAYMENTBREAKDOWN_QUERY } from './queries';
import { Loader } from '../../../components';
import * as S from '../../SignUp/ConfirmRequest/styled';
import moment from 'moment';
import { NairaAmount } from '../../../components/Icon';

const RepaymentBreakdown = ({
  children,
  policyId,
  principalAmount,
  duration,
  moratoriumPeriod,
}) => {
  const [repaymentBreakdown, setRepaymentBreakdown] = useState();

  const repaymentTable = useMemo(() => {
    let totalInterest = 0;
    let totalPrincipal = 0;
    let totalPayment = 0;

    const tableRows = (repaymentBreakdown || []).map((repayment, index) => {
      totalPrincipal += repayment.principalPortion;
      totalInterest += repayment.interestPortion;
      totalPayment += repayment.expectedPayment;
      return (
        <S.TableRow key={`repayment_${index}`}>
          <S.TableData>{moment(repayment.dueDate).format('ll')}</S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.principalPortion} />
          </S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.interestPortion} />
          </S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.expectedPayment} />
          </S.TableData>
        </S.TableRow>
      );
    });
    tableRows.push(
      <S.TableRow key={`repayment_total`}>
        <S.TableData>
          <b>Total</b>
          <span>Amount</span>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalPrincipal} />
          </b>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalInterest} />
          </b>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalPayment} />
          </b>
        </S.TableData>
      </S.TableRow>,
    );
    return (
      <S.Table>
        <S.TableHeader>
          <S.TableRow>
            <S.TableHeading span="col">Repayment Date</S.TableHeading>
            <S.TableHeading span="col">Principal</S.TableHeading>
            <S.TableHeading span="col">Interest</S.TableHeading>
            <S.TableHeading span="col">Total Payment</S.TableHeading>
          </S.TableRow>
        </S.TableHeader>
        <S.TableBody>{tableRows}</S.TableBody>
      </S.Table>
    );
  }, [repaymentBreakdown]);

  return (
    <Query
      query={GET_REPAYMENTBREAKDOWN_QUERY}
      variables={{ policyId, principalAmount, duration, moratoriumPeriod }}
    >
      {({ loading, error, data }) => {
        const props = {
          error,
          loading,
          principalAmount: '',
          duration: '',
          totalInterest: '',
          rateInDecimal: '',
          repaymentFrequency: '',
          repaymentBreakdown: {},
        };

        if (loading) {
          return <Loader />;
        }

        if (error) {
          return children(props);
        }

        if (!loading && !error && data) {
          const { getRepaymentBreakdown } = data;

          setRepaymentBreakdown(getRepaymentBreakdown.repaymentBreakdown);

          props.principalAmount = getRepaymentBreakdown.principalAmount;
          props.duration = getRepaymentBreakdown.duration;
          props.totalInterest = getRepaymentBreakdown.totalInterest;
          props.rateInDecimal = getRepaymentBreakdown.rateInDecimal;
          props.repaymentFrequency = getRepaymentBreakdown.repaymentFrequency;
          props.repaymentBreakdown = getRepaymentBreakdown.repaymentBreakdown;
          props.repaymentTable = repaymentTable;
        }
        return children(props);
      }}
    </Query>
  );
};

export default withRouter(RepaymentBreakdown);
