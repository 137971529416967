import styled from 'styled-components';

export const PasswordField = styled.div`
  position: relative;
  width: 100%;
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 8px;
  right: 4px;
  font-weight: 800;
  color: ${({ theme }) => theme.primaryColor};
  background: transparent;
  text-align: right;
  width: auto !important;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: 700;
`;

export const LearnLink = styled.a`
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: 900;
  font-size: 15px;
`;

export { Title, Aside, Wrapper, Subtitle } from '../Signup/styled';
