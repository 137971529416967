import PropTypes from 'prop-types';
import React from 'react';
import './_Notification.scss';

const Notification = ({ icon, title, children }) => {
  return (
    <div className="notification">
      <div className="notification_holder">
        <div className="notification_icon-holder">{icon}</div>
        <h3 className={'notification_text center-text'}>{title}</h3>
        <div className={'center-text'}>{children}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
};

export default Notification;
