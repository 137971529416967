import React, { useEffect } from 'react';
import packageJson from '../package.json';

const buildDateGreaterThan = (latestDate, currentDate) => {
  if (latestDate > currentDate) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    useEffect(() => {
      const checkForNewVersion = async () => {
        const response = await fetch(`/meta.json?v=${new Date().getTime()}`);
        const meta = await response.json();

        const latestVersionDate = meta.buildDateTime;
        const currentVersionDate = packageJson.buildDateTime;

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );

        const hasReloadedInfo = JSON.parse(localStorage.getItem('hasReloadedInfo'));
        const fiveMinutes = 5 * 60 * 1000;
        const now = new Date().getTime();

        const needsReload =
          !hasReloadedInfo || 
          hasReloadedInfo.version !== latestVersionDate || 
          now - hasReloadedInfo.timestamp > fiveMinutes;

        if (shouldForceRefresh && needsReload) {
          refreshCacheAndReload(latestVersionDate);
        }
      };

      checkForNewVersion();
    }, []);

    const refreshCacheAndReload = (latestVersionDate) => {
      if ('caches' in window && caches) {
        caches.keys().then((names) => {
          names.forEach((name) => caches.delete(name));
        });
      }

      localStorage.setItem(
        'hasReloadedInfo',
        JSON.stringify({
          version: latestVersionDate,
          timestamp: new Date().getTime(),
        })
      );

      window.location.reload(true);
    };

    return (
      <React.Fragment>
        <Component {...props}/>
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
