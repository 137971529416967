import * as React from 'react';

const UploadIcon = props => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.667 26.667L20 20l-6.667 6.667M20 20v15"
      stroke="#1A2546"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.983 30.65A8.334 8.334 0 0030 15h-2.1A13.333 13.333 0 105 27.167"
      stroke="#1A2546"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.667 26.667L20 20l-6.667 6.667"
      stroke="#1A2546"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadIcon;
