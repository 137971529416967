import { useDocumentUploader, useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { useCallback, useEffect, useState } from 'react';
import { pages } from '../../../lib/constants';

const useEmploymentLetterUpload = () => {
  const {
    setLoanApplicationState,
    createApplicationTrace,
    goToPreviousPage,
    goToNextPage,
    user,
    setCompletedStep,
  } = useLoanApplicationContext();
  const {
    data,
    uploadDocument,
    uploadDocumentLoading,
    handleDocumentPreview,
    uploadDocumentError,
  } = useDocumentUploader();
  const [letterDetail, setLetterDetails] = useState({});

  const handleUpload = useCallback(() => {
    logEvent('Signup', 'Upload employment letter');

    const { firstName, lastName, id } = user;
    const fileName = `employmentLetter_${firstName}_${lastName}_${id}`;
    uploadDocument(letterDetail.fileDetails, fileName, user.id);
  }, [letterDetail]);

  const handlePreview = e => {
    handleDocumentPreview(e, setLetterDetails);
  };

  useEffect(() => {
    if (data) {
      const {
        uploadSupportingDocument: { fileUrl },
      } = data;
      setLoanApplicationState(prevState => ({
        ...prevState,
        employmentLetterUrl: fileUrl,
      }));
      setCompletedStep('employmentLetter');
      goToNextPage();
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.employmentLetter,
      'Navigated to Employment Letter Screen',
    );
  }, []);

  return {
    uploadDocumentLoading,
    uploadDocumentError,
    handlePreview,
    handleUpload,
    goToPreviousPage,
    letterDetail,
  };
};

export default useEmploymentLetterUpload;
