import React from 'react';
import PropTypes from 'prop-types';
import './_RepaymentBreakdown.scss';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledUl = styled.section`
  background: ${props => transparentize(0.9, props.theme.secondaryColor)};
  border-color: ${props => props.theme.secondaryColor};
`;

const RepaymentBreakdown = ({ children }) => {
  return <StyledUl className="repayment-breakdown">{children}</StyledUl>;
};

RepaymentBreakdown.propTypes = {
  classes: PropTypes.string,
};

export default RepaymentBreakdown;
