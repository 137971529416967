import React from 'react';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { Alert, DataCard } from '../../../components';
import { SubmitButton } from '../../../components/FormikElements';
import useSupportingDocumentForm from './useSupportingDocumentForm';

const SupportingDocuments = () => {
  const {
    applicationLoading,
    clientInfo: { requiredSupportingDocuments },
    loading,
    uploadedDocs,
    error,
    handleDocumentUpload,
    handleSubmit,
  } = useSupportingDocumentForm();

  return (
    <S.Wrapper>
      <DataCard loading={applicationLoading || loading}>
        <S.Title>Supporting Documents</S.Title>
        {error && <Alert classes="error">{error}</Alert>}
        <S.Subtitle>
          Please upload all required documents to continue your application.
          Only PNG, JPEG, PDF files are allowed (maximum file size is 10MB)
        </S.Subtitle>
        <Formik
          initialValues={{}}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          <Form>
            <S.DocumentUploadContainer>
              {requiredSupportingDocuments?.map((item, index) => (
                <S.Label key={index}>
                  <S.Input
                    name={item.documentName}
                    type="file"
                    classes={`border-bottom`}
                    onChange={e => handleDocumentUpload(e)}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  <S.Section>
                    <S.DocumentUploadLabel />
                    <S.DocumentTitle>
                      {uploadedDocs[`${item.documentName}`]
                        ? `${
                            uploadedDocs[`${item.documentName}`].fileName
                          } of Size: ${
                            uploadedDocs[`${item.documentName}`].fileSize
                          }`
                        : item.documentName}
                    </S.DocumentTitle>
                  </S.Section>
                </S.Label>
              ))}
            </S.DocumentUploadContainer>
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default SupportingDocuments;
