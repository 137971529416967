import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import * as S from './styled';

const Checkbox = ({ label, children, ...restProps }) => {
  const [field, meta] = useField(restProps);
  return (
    <S.Wrapper>
      <S.CheckboxContainer>
        <S.Label>
          {label}
          <S.Input {...field} type="checkbox" {...restProps} />
          <S.Span></S.Span>
        </S.Label>
        <div>{children}</div>
      </S.CheckboxContainer>
      {meta.touched && meta.error ? <S.Error>{meta.error}</S.Error> : null}
    </S.Wrapper>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default Checkbox;
