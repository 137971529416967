import styled from 'styled-components';

export const SearchWrapper = styled.section`
  magin: 15px 0;
`;

export const ResetButton = styled.button`
  color: red;
  margin-top: 2rem;
  font-weight: 900;
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  
  &:hover {
    opacity: 0.8;
  }
`;
