import React, { Fragment } from 'react';
import './_BreadCrumbs.scss';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { HomeIcon } from '../Icon';
import { removeHyphen, authGetter } from '../../lib/utils';

const BreadCrumbs = ({ breadcrumbs }) => {
  const authentication = authGetter();
  const isAuthenticated =
    authentication.hasApiKey || authentication.isTempApiKey;
  const homeIconLink = isAuthenticated ? '/dashboard' : 'sign-in';

  return (
    <section className="container">
      <nav className="bread-crumbs">
        {breadcrumbs.map(({ breadcrumb, match }, index) => (
          <div className="bread-crumb" key={match.url}>
            {index === 0 ? (
              <NavLink exact to={homeIconLink}>
                <HomeIcon />
              </NavLink>
            ) : index < breadcrumbs.length - 1 ? (
              <NavLink exact to={match.url || ''}>
                {breadcrumb}
              </NavLink>
            ) : (
              <Fragment>
                {match.url.includes('loan-app') ? (
                  <span>Loan Details</span>
                ) : (
                  <span>{removeHyphen(breadcrumb.props.children)}</span>
                )}
              </Fragment>
            )}
          </div>
        ))}
      </nav>
    </section>
  );
};

export default withBreadcrumbs()(BreadCrumbs);
