import styled from 'styled-components';
import { Wrapper as Wrap, Aside as Asi, NextButton as Next } from '../styled';
export { Title, Subtitle, PreviousButton } from '../styled';

export const Wrapper = styled(Wrap)`
  .paystackbutton {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    border: none;
    padding: 0;
  }
`;

export const Aside = styled(Asi)`
  color: #4c5c6d;
  text-align: center;
  padding: 2rem;

  .left {
    padding-left: 3px;
  }

  .warning {
    color: rgb(255, 0, 0);
  }
`;

export const ReferenceError = styled.section`
  display: flex;
  color: #171923;
  border-radius: 4px;
  padding: 10px 5px;
  background: #fed7d7;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const RetryButton = styled.button`
  border: none;
  color: #171923;
  background: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 5px;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

export const NextButton = styled(Next)`
  margin-top: 0;
  cursor: pointer !important;

  &:disabled {
    opacity: 0.3;
  }
`;
