import { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { handleGraphQLErrors } from '../lib/utils';

export const UPLOAD_SUPPORTING_DOCUMENT = gql`
  mutation UploadSupportingDocument(
    $file: Upload!
    $documentName: String!
    $userId: ID!
  ) {
    uploadSupportingDocument(
      input: { documentName: $documentName, file: $file, userId: $userId }
    ) {
      documentName
      fileUrl
      id
      file {
        url
        key
        bucket
      }
      user {
        id
      }
    }
  }
`;

const useDocumentUploader = () => {
  const [uploadDocumentError, setUploadDocumentError] = useState(null);
  const [mutate, { data, loading: uploadDocumentLoading }] = useMutation(
    UPLOAD_SUPPORTING_DOCUMENT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setUploadDocumentError(
          errorMessage ||
            'There was an error uploading your image, please try again or contact customer care',
        );
      },
    },
  );

  const handleDocumentPreview = useCallback((e, setDocumentDetails) => {
    const { validity, files } = e.target;
    const file = files[0];
    const reader = new FileReader();
    setUploadDocumentError('');

    if (file && file.size > 10000000) {
      setUploadDocumentError('Please, upload a file lower than 10 MB');
      return;
    }

    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setDocumentDetails({
          url: reader.result,
          fileDetails: { file, validity: validity.valid },
        });
      };
    }
  }, []);

  const uploadDocument = useCallback((selectedFile, documentName, userId) => {
    if (!selectedFile) {
      setUploadDocumentError('Please select a document for upload');
    } else {
      const { validity, file } = selectedFile;
      validity &&
        mutate({
          variables: { file, documentName, userId },
        });
    }
  }, []);

  return {
    data,
    uploadDocument,
    handleDocumentPreview,
    uploadDocumentLoading,
    uploadDocumentError,
  };
};

export default useDocumentUploader;
