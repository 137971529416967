import React, { useEffect } from "react";
import { MerchantProvider } from "../../../context/merchantContext";
import { ThemeProvider } from "styled-components";
import theme from "../../../stylesheets/theme";
import { useClientInfoContext } from "../../../hooks";
import { Loader, MaintenancePage, MerchantNotFound } from "../../../components";

const Merchant = ({ children }) => {
  const {
    error,
    clientInfo: { clientTheme },
    loading,
  } = useClientInfoContext();

  useEffect(() => {
    if (error) {
      const clientErrorCount =
        parseInt(localStorage.getItem("clientError") || "0", 10) + 1;
      localStorage.setItem("clientError", clientErrorCount);

      if (clientErrorCount > 1) {
        localStorage.removeItem("clientError");
      } else {
        window.location.reload();
      }
    }
  }, [error]);

  if (loading) return <Loader />;

  if (error) {
    if (parseInt(localStorage.getItem("clientError"), 10) > 1) {
      if (error.message === "Cannot find merchant account") {
        return <MerchantNotFound />;
      } else {
        return <MaintenancePage />;
      }
    }
    return null;
  }

  return (
    <MerchantProvider value={{ ...theme, ...clientTheme }}>
      <ThemeProvider theme={{ ...theme, ...clientTheme }}>
        {children}
      </ThemeProvider>
    </MerchantProvider>
  );
};

export default Merchant;
