export const COMMON_ROUTES = {
  newApplication: 'new',
  homeAddress: 'home-address',
  employmentDetails: 'employment',
  uploadBankStatement: 'upload-bank-statement',
  pending: 'loan-pending',
  referee: 'referee',
  confirmLoan: 'breakdown',
  employmentLetterUpload: 'employment-letter',
  socialMedia: 'social-media',
  mbsInstruction: 'mbs-instruction',
  mbsOtp: 'mbs-otp',
  mbs: 'mbs',
  externalMbsOtp: 'external-mbs',
  okra: 'okra',
  passportUpload: 'upload-selfie',
  idUpload: 'upload-id',
  workIdUpload: 'upload-work-id',
  supportingDocuments: 'supporting-documents',
  utilityBillUpload: 'utility-bill',
  verifyWorkEmail: 'verify-work-email',
  verifyEmail: 'verify-email',
  verifyPhone: 'verify-phone',
  bankAccount: 'bank-account',
  addCard: 'add-card',
  failure: 'failure',
  loader: 'loader',
};

export const NEW_LOAN_ROUTES = {
  ...COMMON_ROUTES,
  confirmDetails: 'confirm-details',
};

export const SIGN_UP_ROUTES = {
  ...COMMON_ROUTES,
};

export const ALL_ROUTES = {
  ...NEW_LOAN_ROUTES,
  ...SIGN_UP_ROUTES,
};
