import React, { useEffect, useState } from 'react';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import { useClientInfoContext } from '../../hooks';
import ElectronicRemitalMandate from '../../containers/Dashboard/ElectronicRemitaMandate';
import ManualRemitalMandate from '../../containers/Dashboard/ManualRemitalMandate';

const RemitaMandateBanner = ({ refetch, activePortfolio }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { clientInfo, refetch: fetchClientInfo } = useClientInfoContext({
    loanCategoryId: activePortfolio?.application?.loanCategory?.id,
  });

  const pendingDebitMandate =
    activePortfolio?.debitMandate?.status?.name === 'PENDING_ACTIVATION';

  const isElectronicMandate =
    activePortfolio?.debitMandate?.electronicMandateIsSupported;

  useEffect(() => {
    if (
      !!pendingDebitMandate &&
      activePortfolio?.application?.loanCategory?.id
    ) {
      fetchClientInfo();
    }
  }, [activePortfolio?.application, pendingDebitMandate]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {activePortfolio &&
        pendingDebitMandate &&
        clientInfo?.requiresRemitaMandate && (
          <Alert classes="error">
            We require that you activate direct debit on your bank account
            before loan disbursement
            <Button
              classes="right secondary"
              button_text="Activate Direct Debit"
              onClick={openModal}
            />
          </Alert>
        )}

      {isModalOpen && (
        <>
          {isElectronicMandate ? (
            <ElectronicRemitalMandate
              debitMandate={activePortfolio?.debitMandate}
              loanAmount={activePortfolio?.amount}
              clientId={clientInfo?.clientId}
              handleCloseModal={closeModal}
              refetch={refetch}
            />
          ) : (
            <ManualRemitalMandate
              debitMandate={activePortfolio?.debitMandate}
              loanAmount={activePortfolio?.amount}
              handleCloseModal={closeModal}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  );
};

export default RemitaMandateBanner;
