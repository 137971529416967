import React, { Fragment } from 'react';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../components';
import DataCard from '../../components/DataCard/DataCard';
import {
  EmploymentSettings,
  PaymentSettings,
  ProfileSettings,
  SecuritySettings,
  SupportingDocuments,
} from '../index';
import './_Settings.scss';

const StyledSettingMenu = styled.nav`
  a {
    &.active {
      border-color: ${props => props.theme.secondaryColor};
      color: ${props => props.theme.secondaryColor};
    }
  }
`;

export const Settings = () => {
  return (
    <Fragment>
      <section className={'container'}>
        <DataCard noPad>
          <div className="settings-menu__holder">
            <StyledSettingMenu className="settings-menu">
              <NavLink to="/settings" exact data-testid="settings-profile">
                Profile
              </NavLink>
              <NavLink
                to="/settings/employment"
                data-testid="settings-employment"
              >
                Employment Information
              </NavLink>
              <NavLink to="/settings/payment" data-testid="settings-payment">
                Payment Options
              </NavLink>
              <NavLink
                to="/settings/security"
                data-testid="settings-change-password"
              >
                Change Password
              </NavLink>
              <NavLink
                to="/settings/documents"
                data-testid="settings-documents"
              >
                Documents
              </NavLink>
            </StyledSettingMenu>
          </div>
          <section className="main">
            <Switch>
              <Route
                path="/settings"
                exact
                render={props => (
                  <Page
                    {...props}
                    component={ProfileSettings}
                    title="Settings"
                  />
                )}
              />

              <Route
                path="/settings/employment"
                exact
                render={props => (
                  <Page
                    {...props}
                    component={EmploymentSettings}
                    title="Settings"
                  />
                )}
              />

              <Route
                path="/settings/payment"
                exact
                render={props => (
                  <Page
                    {...props}
                    component={PaymentSettings}
                    title="Settings"
                  />
                )}
              />

              <Route
                path="/settings/security"
                exact
                render={props => (
                  <Page
                    {...props}
                    component={SecuritySettings}
                    title="Settings"
                  />
                )}
              />

              <Route
                path="/settings/documents"
                exact
                render={props => (
                  <Page
                    {...props}
                    component={SupportingDocuments}
                    title="Documents"
                  />
                )}
              />

              <Route
                path=""
                render={() => (
                  <section className="container not-found">
                    <DataCard>
                      <h1>404</h1>
                      <h3>Page not found</h3>
                      <Link to="/dashboard" className="button">
                        Return to Dashboard
                      </Link>
                    </DataCard>
                  </section>
                )}
              />
            </Switch>
          </section>
        </DataCard>
      </section>
    </Fragment>
  );
};

export default Settings;
