import React from 'react';
import PropTypes from 'prop-types';
import './_Select.scss';

const Select = ({ label, classes, children, errorMessage, ...restProps }) => {
  return (
    <label className={'select__group'}>
      {label == null ? '' : <span className={'select__label'}>{label}</span>}
      <select
        className={`select ${classes == null ? '' : classes}`}
        {...restProps}
      >
        {children}
      </select>
      {errorMessage && <span className={'input__error'}>{errorMessage}</span>}
    </label>
  );
};

Select.propTypes = {
  classes: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

export default Select;
