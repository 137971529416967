import * as React from 'react';

const DocumentIcon = props => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 3H9a3 3 0 00-3 3v24a3 3 0 003 3h18a3 3 0 003-3V12l-9-9z"
      stroke="#6E798F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 3v9h9M24 19.5H12M24 25.5H12M15 13.5h-3"
      stroke="#6E798F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
