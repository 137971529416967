import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 3rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme }) => theme.secondaryColor};
  }
  &:checked ~ span:after {
    display: block;
  }
  &:disabled ~ span {
    cursor: not-allowed;
  }
`;

export const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.secondaryColor};

  :after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
`;

export const Error = styled.label`
  color: red;
  font-size: 1.1rem;
  margin: 0;
`;
