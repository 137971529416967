import React from 'react';
import { Alert, Loader, PasswordInput } from '../../components';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import { UpdatePasswordProvider, ViewerProvider } from '../providers';
import './_Settings.scss';

export const SecuritySettings = () => (
  <ViewerProvider>
    {() => (
      <UpdatePasswordProvider>
        {({ form, onSubmit, loading, errorMessage, successMessage }) => (
          <>
            <Card
              title={'Password'}
              subtitle={
                'Please provide your current password and choose a new password'
              }
            >
              <div>
                <PasswordInput
                  label="Current Password"
                  classes="border-bottom"
                  placeholder=""
                  name="currentPassword"
                  {...form.password.input}
                />
              </div>
            </Card>
            <Card>
              <div>
                <PasswordInput
                  label="New Password"
                  classes="border-bottom"
                  placeholder=""
                  name="newPassword"
                  {...form.newPassword.input}
                />
              </div>
            </Card>
            <Card>
              <div>
                <PasswordInput
                  label="Retype New Password"
                  classes="border-bottom"
                  placeholder=""
                  name="retypeNewPassword"
                  {...form.confirmPassword.input}
                />
              </div>
            </Card>
            <Card>
              {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
              {successMessage && (
                <Alert classes="success">{successMessage}</Alert>
              )}
              {loading ? (
                <Loader />
              ) : (
                <Button click_event={onSubmit}>Update Password</Button>
              )}
            </Card>
          </>
        )}
      </UpdatePasswordProvider>
    )}
  </ViewerProvider>
);

export default SecuritySettings;
