import React from 'react';
import { numberWithCommas } from '../../lib/utils';
import NairaIcon from './NairaIcon';

const NairaAmount = ({ amount, emptyValue = '--' }) => {
  const value = numberWithCommas(amount);

  if (value === 0) {
    return <>{emptyValue}</>;
  }

  return (
    <>
      <NairaIcon />
      {value}
    </>
  );
};

export default NairaAmount;
