module.exports = {
  status: {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
  },
  duration: {
    SHORT: 4000,
    LONG: 15000,
    INDEFINITE: -1,
  },
  validation: {
    PHONE_LENGTH: 11,
    BVN_LENGTH: 11,
    ACCOUNT_NUMBER_LENGTH: 10,
    EMAIL_TYPE: "Invalid email. Example Email 'johndoe@email.com'",
    code: 'please enter code sent to your phone',
  },
  emitted_events: {
    RAVE_3D_SUCCESS: 'Rave-3DSecure-Response',
    RAVE_3D_FAILURE: 'Rave-3DSecure-Failure',
    RAVE_3D_ERROR: 'Rave-3DSecure-Error',
  },
  rave: {
    PIN: 'PIN',
    SECURE_3D: 'VBVSECURECODE',
  },
  facebook: {
    APP_ID: process.env.FACEBOOK_APP_ID,
  },
  authTypes: {
    TEMPORARY: 'TEMPORARY',
    FIXED: 'FIXED',
  },
  transactType: {
    transactionType: 'ADD_CARD',
    repaymentTransaction: 'LOAN_REPAYMENT',
  },
  CLIENT_ID: 'clientId',
  LOAN_APP_TYPE: {
    SIGN_UP: 'SIGN_UP',
    NEW_LOAN: 'NEW_LOAN',
  },
  pages: {
    addCard: 'Add Card Details',
    bankDetails: 'Bank Details',
    bankStatementUpload: 'Upload Bank Statement',
    confirmRequest: 'Breakdown',
    employmentDetails: 'Employment Details',
    employmentLetter: 'Employment Letter',
    governmentId: 'Upload ID',
    homeAddress: 'Home Address',
    dashboard: 'Dashboard',
    mbsBankDetails: 'MBS Bank Details',
    okra: 'Okra',
    referee: 'Referee',
    socialMedia: 'Social Media',
    supportingDocuments: 'Supporting Documents',
    uploadSelfie: 'Upload Selfie',
    utilityBill: 'Utility Bill',
    workEmailVerification: 'Work Email Verification',
    uploadWorkId: 'Upload Work ID',
    emailVerification: 'Email Verification',
    phoneVerification: 'Phone Number Verification',
  },
};
