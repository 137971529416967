import { useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import { handleGraphQLErrors } from '../lib/utils';
import { useMutation } from '@apollo/client';
import useDocumentUploader from './useDocumentUploader';

export const UPLOAD_IMAGE = gql`
  mutation ($image: Upload!, $fileName: String!, $type: UploadImageTypes!) {
    uploadImageAndSaveToUserMetaData(
      input: { image: $image, fileName: $fileName, type: $type }
    ) {
      status
      fileUrl
      key
      bucket
    }
  }
`;

const useImageUploader = () => {
  const [uploadImageError, setImageUploadError] = useState(null);
  const { uploadDocumentError, handleDocumentPreview } = useDocumentUploader();
  const [mutate, { data, loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setImageUploadError(
          errorMessage ||
            'There was an error uploading your image, please try again or contact customer care',
        );
      },
    },
  );

  const uploadImage = useCallback((selectedFile, fileName, type) => {
    if (!selectedFile) {
      setImageUploadError('Please select an image for upload');
    } else {
      const { validity, file } = selectedFile;
      validity &&
        mutate({
          variables: { image: file, fileName, type },
        });
    }
  }, []);

  const handleImagePreview = (e, setImageDetails) => {
    handleDocumentPreview(e, setImageDetails);
  };

  useEffect(() => {
    setImageUploadError(uploadDocumentError);
  }, [uploadDocumentError]);

  return {
    data,
    uploadImage,
    handleImagePreview,
    uploadImageLoading,
    uploadImageError,
  };
};

export default useImageUploader;
