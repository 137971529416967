import React, { Fragment } from 'react';
import { Alert, Loader } from '../../components';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Input from '../../components/FormElements/Input/Input';
import Radio from '../../components/FormElements/Radio/Radio';
import RadioGroup from '../../components/FormElements/RadioGroup/RadioGroup';
import {
  CurrentTimeProvider,
  CustomerRemitaStatus,
  UpdateUserDetailsProvider,
  ViewerProvider,
} from '../providers';
import './_Settings.scss';

export const EmploymentSettings = () => {
  return (
    <ViewerProvider>
      {({ user, currentLoan }) => (
        <section className={'container'}>
          {
            <CustomerRemitaStatus customerPhoneNumber={user.phone}>
              {({ hasRemitaData }) => (
                <CurrentTimeProvider>
                  {timeProps => (
                    <UpdateUserDetailsProvider
                      user={user}
                      timeProps={timeProps}
                    >
                      {({
                        form,
                        onSubmit,
                        loading,
                        errorMessage,
                        successMessage,
                      }) => (
                        <Fragment>
                          <Card
                            title={'Employment Status'}
                            subtitle={'Do you currently have a job'}
                          >
                            <RadioGroup classes="two-grid-column">
                              <Radio
                                label="Employed Full Time"
                                name="employmentStatus"
                                disabled={currentLoan.id}
                                {...form.employmentStatus.input(
                                  'Employed Full Time',
                                )}
                              />
                              <Radio
                                label="Employed Part Time"
                                name="employmentStatus"
                                disabled={currentLoan.id}
                                {...form.employmentStatus.input(
                                  'Employed Part Time',
                                )}
                              />
                              <Radio
                                label="Self Employed"
                                name="employmentStatus"
                                disabled={currentLoan.id}
                                {...form.employmentStatus.input(
                                  'Self Employed',
                                )}
                              />
                              <Radio
                                label="Unemployed"
                                name="employmentStatus"
                                disabled={currentLoan.id}
                                {...form.employmentStatus.input('Unemployed')}
                              />
                            </RadioGroup>
                          </Card>

                          <Card
                            title={'Company Name'}
                            subtitle={'Where do you work?'}
                          >
                            <div>
                              <Input
                                label="Company Name"
                                classes="border-bottom"
                                placeholder=""
                                name="companyName"
                                readOnly={hasRemitaData || currentLoan.id}
                                {...form.employerName.input}
                              />
                            </div>
                          </Card>
                          <Card
                            title={'Current Position'}
                            subtitle={'Your title at your job'}
                          >
                            <div>
                              <Input
                                label="Current Position"
                                classes="border-bottom"
                                placeholder=""
                                name="currentPosition"
                                readOnly={currentLoan.id}
                                {...form.jobRole.input}
                              />
                            </div>
                          </Card>

                          <Card
                            title={'Work Email'}
                            subtitle={'Your official email address at work'}
                          >
                            <div>
                              <Input
                                label="Work Email"
                                classes="border-bottom"
                                placeholder=""
                                name="workEmail"
                                readOnly={currentLoan.id}
                                {...form.workEmail.input}
                              />
                            </div>
                          </Card>

                          <Card
                            title={'Employment Duration'}
                            subtitle={'How long have you worked there?'}
                          >
                            <RadioGroup classes="three-grid-column">
                              <Radio
                                label="< 1 Year"
                                name="employmentDuration"
                                disabled={currentLoan.id}
                                {...form.workStartDate.input(
                                  timeProps.oneMonthAgo,
                                )}
                              />
                              <Radio
                                label="1 - 3 Years"
                                name="employmentDuration"
                                disabled={currentLoan.id}
                                {...form.workStartDate.input(
                                  timeProps.oneYearAgo,
                                )}
                              />
                              <Radio
                                label="4 - 6 Years"
                                name="employmentDuration"
                                disabled={currentLoan.id}
                                {...form.workStartDate.input(
                                  timeProps.fourYearsAgo,
                                )}
                              />
                              <Radio
                                label="7 - 9 Years"
                                name="employmentDuration"
                                disabled={currentLoan.id}
                                {...form.workStartDate.input(
                                  timeProps.sevenYearsAgo,
                                )}
                              />
                              <Radio
                                label="> 10 Years"
                                name="employmentDuration"
                                disabled={currentLoan.id}
                                {...form.workStartDate.input(
                                  timeProps.tenYearsAgo,
                                )}
                              />
                            </RadioGroup>
                          </Card>

                          <Card
                            title={'Company Address'}
                            subtitle={'Where is your company located?'}
                          >
                            <div>
                              <Input
                                label="company address"
                                classes="border-bottom"
                                placeholder=""
                                name="companyAddress"
                                readOnly={currentLoan.id}
                                {...form.workAddress.input}
                              />
                            </div>
                          </Card>
                          <Card
                            title={'Net Monthly Income'}
                            subtitle={'What is your take home?'}
                          >
                            <div>
                              <Input
                                label="Net monthly income"
                                classes="border-bottom"
                                placeholder=""
                                amountField
                                name="netMonthlyIncome"
                                readOnly={hasRemitaData || currentLoan.id}
                                {...form.netIncome.input}
                              />
                            </div>
                          </Card>
                          <Card>
                            {errorMessage && (
                              <Alert classes="error">{errorMessage}</Alert>
                            )}
                            {successMessage && (
                              <Alert classes="success">{successMessage}</Alert>
                            )}
                            {loading ? (
                              <Loader />
                            ) : currentLoan.id ? (
                              ''
                            ) : (
                              <Button click_event={onSubmit}>
                                Update Profile
                              </Button>
                            )}
                          </Card>
                        </Fragment>
                      )}
                    </UpdateUserDetailsProvider>
                  )}
                </CurrentTimeProvider>
              )}
            </CustomerRemitaStatus>
          }
        </section>
      )}
    </ViewerProvider>
  );
};

export default EmploymentSettings;
