import React, { Fragment, useState } from 'react';
import moment from 'moment';
import {
  DataCard,
  Tag,
  TransactionTable,
  LoanDetailsCard,
  ReviewFeedback,
  OfferLetter,
  Button,
  Popup,
} from '../../components';
import { ApplicationDetailsProvider, CdnFileProvider } from '../providers';
import RepaymentBreakdownProvider from '../../containers/providers/Repayments/RepaymentBreakdownProvider';
import { SadSmiley, DocumentIcon, NairaAmount } from '../../components/Icon';
import { Link } from 'react-router-dom';
import {
  removeUnderscore,
  getDisplayInterestRate,
  toTitleCase,
  MANDATE_TYPES,
  PENDING_DISBURSEMENT_STATUSES,
} from '../../lib/utils';
import './_LoanDetails.scss';
import ProdCampNPSWidget from '../../components/ProdCampNPSWidget/ProdCampNPSWidget';
import RecovaMandateBanner from '../../components/RecovaMandateBanner/RecovaMandateBanner';
import * as S from '../SignUp/ConfirmRequest/styled';
import LidyaMandateBanner from '../../components/LidyaMandateBanner/LidyaMandateBanner';

const LoanDetails = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <Fragment>
      <ApplicationDetailsProvider>
        {({
          error,
          application,
          amount,
          refetch,
          fullAmount,
          status,
          createdAt,
          portfolio,
          applicationNumber,
          dateOfRepayment,
          rejectReason,
          reviewFeedbacks,
          moratoriumData,
        }) => {
          const nextRepaymentDate =
            portfolio &&
            (portfolio?.repayments?.find(
              repayment => repayment?.status?.name === 'PENDING',
            ) ||
              dateOfRepayment);
          const hasRepayments =
            portfolio && portfolio?.repayments?.find(item => item.amountPaid);
          const reviewComplete = reviewFeedbacks?.find(
            feedback => feedback?.status === 'COMPLETED',
          );
          const hasDocuments = reviewComplete?.documentRequests?.find(
            document => document?.status === 'COMPLETED',
          );

          const pendingDebitMandate =
            PENDING_DISBURSEMENT_STATUSES.includes(portfolio?.status?.name) &&
            portfolio?.debitMandateMeta?.status?.name === 'PENDING_ACTIVATION';

          const handleShowPopup = () => {
            setShowPopup(!showPopup);
          };

          const activeRepayments = portfolio?.repayments?.filter(
            item => item.status.name.toLowerCase() !== 'cancelled',
          );

          const hasCancelledRepayment = portfolio?.repayments?.find(
            item => item.status.name.toLowerCase() === 'cancelled',
          );

          const nextRepayment = portfolio?.repayments?.filter(
            item => item.status.name.toLowerCase() === 'pending',
          );

          return (
            <section className={'container'}>
              {!error && application ? (
                <Fragment>
                  <OfferLetter application={application} refetch={refetch} />
                  {portfolio ? (
                    <Fragment>
                      {pendingDebitMandate &&
                        portfolio.debitMandateMeta?.type?.name ===
                          MANDATE_TYPES.RECOVA && (
                          <RecovaMandateBanner
                            debitMandate={portfolio.debitMandateMeta}
                          />
                        )}

                      {pendingDebitMandate &&
                        portfolio.debitMandateMeta?.type?.name ===
                          MANDATE_TYPES.LIDYA && (
                          <LidyaMandateBanner
                            debitMandate={portfolio.debitMandateMeta}
                          />
                        )}

                      <DataCard>
                        <LoanDetailsCard
                          amountRequested={amount}
                          amountPaid={portfolio.amountPaid || 0}
                          fullAmount={portfolio.fullAmount || fullAmount}
                          portfolioNumber={portfolio.portfolioNumber}
                          applicationNumber={applicationNumber}
                          loanDate={portfolio.createdAt || createdAt}
                          amountDisbursed={portfolio.amountDisbursed || 0}
                          interestRate={
                            hasCancelledRepayment
                              ? null
                              : getDisplayInterestRate(application, portfolio)
                          }
                          numberOfInstallments={activeRepayments?.length}
                          status={portfolio?.status?.name}
                          nextInstallment={nextRepayment?.totalPayment}
                          repaymentDate={nextRepaymentDate?.dueDate}
                          lateRepaymentFee={portfolio.lateRepaymentFee}
                          application={application}
                        />
                        <Button
                          classes="button-link"
                          click_event={handleShowPopup}
                          data-testid="repayment-breakdown"
                        >
                          <small>View Repayment Breakdown</small>
                        </Button>
                      </DataCard>

                      {hasRepayments && (
                        <DataCard>
                          <TransactionTable
                            txTableHeaders={[
                              'Date',
                              'Status',
                              'Total Amount',
                              'Total Amount Paid',
                            ]}
                          >
                            {portfolio.repayments.map((data, index) => (
                              <Fragment key={index}>
                                {data.repaymentDate && (
                                  <li key={index}>
                                    <div
                                      className="tx-column transaction-date"
                                      data-header="Date"
                                    >
                                      {moment(data.repaymentDate).format('ll')}
                                    </div>
                                    <div
                                      className="tx-column"
                                      data-header="Status"
                                    >
                                      <Tag
                                        status={removeUnderscore(
                                          data.status.name,
                                        ).toLowerCase()}
                                      />
                                    </div>
                                    <div
                                      className="tx-column"
                                      data-header="Total Amount"
                                    >
                                      <span>
                                        <NairaAmount
                                          amount={data.totalPayment}
                                        />
                                      </span>
                                    </div>
                                    <div
                                      className="tx-column"
                                      data-header="Total Amount Paid"
                                    >
                                      <span>
                                        <NairaAmount
                                          amount={data.amountPaid || 0}
                                        />
                                      </span>
                                    </div>
                                  </li>
                                )}
                              </Fragment>
                            ))}
                          </TransactionTable>
                        </DataCard>
                      )}
                    </Fragment>
                  ) : (
                    <DataCard>
                      <LoanDetailsCard
                        amountRequested={amount}
                        amountPaid={0}
                        fullAmount={fullAmount}
                        applicationNumber={applicationNumber}
                        applicationDate={createdAt}
                        amountDisbursed={0}
                        status={status.name}
                        rejectReason={rejectReason}
                        repaymentDate={dateOfRepayment}
                        application={application}
                      />

                      {application.policy && (
                        <Button
                          classes="button-link"
                          click_event={handleShowPopup}
                          data-testid="repayment-breakdown"
                        >
                          <small>View Repayment Breakdown</small>
                        </Button>
                      )}
                    </DataCard>
                  )}
                  {showPopup && (
                    <div className="breakdown-popup">
                      <Popup title="Repayment Breakdown">
                        {portfolio?.repayments &&
                        portfolio?.repayments.length !== 0 ? (
                          <RepaymentSchedule
                            repayments={portfolio?.repayments}
                          />
                        ) : (
                          <RepaymentBreakdownProvider
                            policyId={application.policy.id}
                            principalAmount={application.amount}
                            duration={application.loanDuration}
                            moratoriumPeriod={
                              moratoriumData &&
                              moratoriumData.numberOfPeriods != null
                                ? moratoriumData.numberOfPeriods
                                : 0
                            }
                          >
                            {({ repaymentBreakdown, repaymentTable }) =>
                              repaymentBreakdown && repaymentTable
                            }
                          </RepaymentBreakdownProvider>
                        )}
                        <div className="decision_buttons">
                          <Button
                            classes="close_button"
                            click_event={handleShowPopup}
                            data-testid="close-repayment-breakdown"
                          >
                            CLOSE
                          </Button>
                        </div>
                      </Popup>
                    </div>
                  )}

                  {hasDocuments && reviewComplete && (
                    <DataCard>
                      <h3>Supporting Documents</h3>
                      <div className="supporting-document">
                        {reviewFeedbacks.map((review, index) =>
                          review.documentRequests.map(
                            doc =>
                              doc.document && (
                                <Fragment key={index}>
                                  <CdnFileProvider
                                    file={doc.document.file}
                                    key={index}
                                  >
                                    {({ openFile }) => (
                                      <article
                                        className="doc-box"
                                        title={doc.document.documentName}
                                      >
                                        <DocumentIcon className="icon" />
                                        <span className="document-name">
                                          {doc.document.documentName}
                                        </span>
                                        <button
                                          className="view-button"
                                          onClick={openFile}
                                        >
                                          View Document
                                        </button>
                                      </article>
                                    )}
                                  </CdnFileProvider>
                                </Fragment>
                              ),
                          ),
                        )}
                      </div>
                    </DataCard>
                  )}

                  <ReviewFeedback application={application} refetch={refetch} />
                </Fragment>
              ) : (
                <section className="container no-loan">
                  <DataCard>
                    <div className="smiley-holder">
                      <SadSmiley />
                    </div>
                    <h3 className="center-text">
                      Sorry this loan application does not exist!
                    </h3>
                    <Link to="/loans/" className="button">
                      Back to Loans
                    </Link>
                  </DataCard>
                </section>
              )}
            </section>
          );
        }}
      </ApplicationDetailsProvider>

      <ProdCampNPSWidget />
    </Fragment>
  );
};

export default LoanDetails;

const RepaymentSchedule = ({ repayments }) => {
  return (
    <S.Table>
      <S.TableHeader>
        <S.TableRow>
          <S.TableHeading span="col">Status</S.TableHeading>
          <S.TableHeading span="col">Principal</S.TableHeading>
          <S.TableHeading span="col">Interest</S.TableHeading>
          <S.TableHeading span="col">Total Payment</S.TableHeading>
          <S.TableHeading span="col">Due Date</S.TableHeading>
        </S.TableRow>
      </S.TableHeader>
      <S.TableBody>
        {repayments.map(item => (
          <Fragment key={item.id}>
            {item.status.name.toLowerCase() !== 'cancelled' && (
              <S.TableRow>
                <S.TableData>{toTitleCase(item?.status.name)}</S.TableData>
                <S.TableData>
                  <NairaAmount amount={item?.principalPortion} />
                </S.TableData>
                <S.TableData>
                  <NairaAmount amount={item?.interestPortion} />
                </S.TableData>
                <S.TableData>
                  <NairaAmount amount={item?.totalPayment} />
                </S.TableData>
                <S.TableData>{moment(item.dueDate).format('ll')}</S.TableData>
              </S.TableRow>
            )}
          </Fragment>
        ))}
      </S.TableBody>
    </S.Table>
  );
};
