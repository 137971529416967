/* eslint-disable prettier/prettier */
import React, { useState, useCallback, useContext, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { notify } from "react-notify-toast";
import { logEvent } from "../lib/GAHelper";
import { ApplicationContext } from "../containers/Application/ApplicationContext";
import Constants from "../lib/constants";
import {
  UPDATE_USER_DETAILS,
  CREATE_CONTEXT_STATE,
  UPDATE_CONTEXT_STATE,
  CREATE_APPLICATION_TRACE,
} from "../containers/LoanApplication/mutations";
import { CUSTOMER_REMITA_STATUS } from "../containers/LoanApplication/queries";
import { useUserViewer } from "./index";
import { UserService } from "../services";
import useLoanApplicationRoute from "./useLoanApplicationRoute";
import { ToastCloseIcon } from "../components/Icon";

const useLoanApplicationContext = () => {
  const stepCategory = "New Loan";

  const contextValue = useContext(ApplicationContext);

  const [
    loanApplicationState,
    setLoanApplicationState,
    applicationState,
    clientInfo,
    categoryId,
  ] = contextValue;

  const { status, duration, authTypes } = Constants;
  const [loanApplicationError, setLoanApplicationError] = useState(null);
  const [loanApplicationIsLoading, setLoanApplicationIsLoading] =
    useState(null);
  const {
    viewerData,
    loading: userLoading,
    refetch,
    getUserViewer,
  } = useUserViewer();

  const user = viewerData?.loaded?.viewer?.me;
  const account = viewerData?.loaded?.viewer?.account;
  const application = applicationState?.data;
  const applicationId = applicationState?.data?.application?.id;
  const applicationNumber =
    applicationState?.data?.application?.applicationNumber;
  const customApplicationForm =
    applicationState?.data?.application?.customApplicationForm;
  const applicationLoading = applicationState?.loading;
  const refetchApplication = applicationState?.refetch;
  const { clientId, addCardCharge } = clientInfo;

  const {
    goToNextPage,
    goToPreviousPage,
    goToPage,
    isLoaded,
    goToLoanDetailsPage,
    setCompletedStep,
    initRequiredStepsState,
    fetchNextStep,
  } = useLoanApplicationRoute({
    loanApplicationState,
    clientInfo,
    viewer: viewerData?.loaded?.viewer,
    application,
  });
  const [createContext, { loading: createContextLoading }] = useMutation(
    CREATE_CONTEXT_STATE,
    {
      onCompleted({ createContextState: { id, page, state } }) {
        setLoanApplicationState((prevState) => ({
          ...prevState,
          contextId: id,
          contextPage: page,
          currContextState: state,
        }));
      },
    }
  );
  const [updateContext, { loading: updateContextLoading }] = useMutation(
    UPDATE_CONTEXT_STATE,
    {
      onCompleted({ updateContextState: { page, state } }) {
        setLoanApplicationState((prevState) => ({
          ...prevState,
          contextPage: page,
          currContextState: state,
        }));
      },
    }
  );

  const [createTrace, { loading: createTraceLoading }] = useMutation(
    CREATE_APPLICATION_TRACE
  );

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onError() {
        setLoanApplicationError(
          "An error occurred while updating your details. Please try again."
        );
      },
    }
  );

  const [customerRemitaStatus, { loading: customerRemitaLoading }] =
    useLazyQuery(CUSTOMER_REMITA_STATUS, {
      onCompleted({ customerRemitaStatus }) {
        if (customerRemitaStatus) {
          const { bankId, companyName, accountNumber, salaryPaymentDetails } =
            customerRemitaStatus;
          const monthlySalary = salaryPaymentDetails[0].amount;

          setLoanApplicationState((prevState) => ({
            ...prevState,
            remitaCustomer: true,
            remitaBankId: bankId,
            accountNumber: accountNumber,
            remitaNetIncome: monthlySalary,
            remitaEmployerName: companyName,
            remitaEmploymentStatus: "employedfulltime",
          }));
        }
      },
    });

  const customerRemita = useCallback(async () => {
    logEvent(stepCategory, "Customer Remita Status.", true);
    const userPhone = await UserService.viewer();
    const { phone } = userPhone.data.data.viewer.me || this.state.phone;
    customerRemitaStatus({
      variables: {
        clientId,
        customerPhoneNumber: phone,
      },
    });
  }, []);

  const updateUserDetails = useCallback((userDetails, callback) => {
    updateUser({
      variables: {
        ...userDetails,
      },
    })
      .then(() => {
        callback();
      })
      .catch(() => {});
  }, []);

  const showErrorAlert = (text, d = duration.SHORT) => {
    if (d === duration.INDEFINITE) {
      text = (
        <div>
          <span>{text}</span>
          <button
            className="close-icon"
            onClick={notify.hide}
          >
            <ToastCloseIcon />
          </button>
        </div>
      );
    }
    notify.show(text, status.ERROR, d);
  };

  const showSuccessAlert = (text, d = duration.SHORT) => {
    notify.show(text, status.SUCCESS, d);
  };

  const showInfoAlert = (text, d = duration.SHORT) => {
    notify.show(text, status.INFO, d);
  };

  const updateAuthType = useCallback(() => {
    const authCreds = JSON.parse(localStorage.getItem("Auth"));
    authCreds.keyType = authTypes.FIXED;
    localStorage.setItem("Auth", JSON.stringify(authCreds));
  }, []);

  const createContextState = useCallback(
    (page, state) => {
      if (!createContextLoading) {
        createContext({
          variables: {
            state,
            page: page || "verify-phone",
            context: "NEW_LOAN",
          },
        });
      }
    },
    [loanApplicationState, createContextLoading]
  );

  const updateContextState = useCallback(
    (page, state) => {
      const { contextId, contextPage, currContextState } = loanApplicationState;
      if (!contextId && viewerData?.loaded?.viewer) {
        createContextState(page);
        return;
      }
      if (!contextId) return;
      if (!updateContextLoading) {
        const updateState = state
          ? { ...currContextState, ...state }
          : undefined;
        updateContext({
          variables: {
            id: contextId,
            page: page || contextPage,
            state: updateState,
          },
        });
      }
    },
    [loanApplicationState, updateContextLoading, viewerData?.loaded?.viewer]
  );

  const createApplicationTrace = useCallback(
    (page, comment, isDebug, metadata) => {
      if (!createTraceLoading) {
        createTrace({
          variables: {
            applicationId,
            page,
            comment,
            isDebug,
            metadata,
          },
        });
      }
    },
    [createTraceLoading]
  );

  useEffect(() => {
    if (loanApplicationError) {
      setTimeout(() => {
        setLoanApplicationError(null);
      }, 5000);
    }
  }, [loanApplicationError]);

  useEffect(() => {
    setLoanApplicationIsLoading(updateUserLoading || customerRemitaLoading);
  }, [updateUserLoading, customerRemitaLoading]);

  useEffect(() => {
    const amount = addCardCharge || "100";
    setLoanApplicationState((prevState) => ({ ...prevState, amount }));

    if (viewerData && viewerData?.loaded?.viewer) {
      const { viewer } = viewerData?.loaded;
      const user = viewer && viewer.me;
      const { contextStates } = viewer && viewerData?.loaded?.viewer?.account;
      const ctxState = contextStates.find((item) => item.context === "newLoan");
      if (ctxState) {
        const { id, page, state } = ctxState;
        setLoanApplicationState((prevState) => ({
          ...prevState,
          user,
          ...state,
          contextId: id,
          currContextState: state,
          contextPage: page,
          phoneNumber: "0".concat(user.phone),
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }));
      }
    }
  }, [addCardCharge, viewerData?.loaded?.viewer]);

  return {
    user,
    account,
    application,
    applicationId,
    applicationLoading,
    refetchApplication,
    loanApplicationState,
    loanApplicationError,
    loanApplicationIsLoading,
    setLoanApplicationState,
    setLoanApplicationError,
    setLoanApplicationIsLoading,
    showErrorAlert,
    showInfoAlert,
    showSuccessAlert,
    updateAuthType,
    customerRemita,
    createContextState,
    updateContextState,
    updateUserDetails,
    clientInfo,
    goToNextPage,
    goToPage,
    goToPreviousPage,
    isLoaded,
    userLoading,
    goToLoanDetailsPage,
    setCompletedStep,
    initRequiredStepsState,
    categoryId,
    fetchNextStep,
    createApplicationTrace,
    customApplicationForm,
    applicationNumber,
    refetch,
    getUserViewer,
  };
};

export default useLoanApplicationContext;
