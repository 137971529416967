import { useLoanApplicationContext } from '../../../hooks';
import { UploadingSupportingDocument } from '../../providers';
import { bytesToSize, getErrorMessages } from '../../../lib/utils';
import { notify } from 'react-notify-toast';
import { logEvent } from '../../../lib/GAHelper';
import Constants from '../../../lib/constants';
import { useCallback, useEffect, useState } from 'react';

const useSupportingDocumentForm = () => {
  const {
    loanApplicationState,
    createApplicationTrace,
    clientInfo: { requiredSupportingDocuments },
    user,
    applicationLoading,
    goToNextPage,
    setCompletedStep,
  } = useLoanApplicationContext();

  const [loading, setLoading] = useState(false);
  const [uploadedDocs, updateDocs] = useState([]);
  const [error, setError] = useState(false);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const { status, duration, pages } = Constants;

  const { uploadSupportingDocumentFile } = UploadingSupportingDocument({
    uploadedDocs,
  });

  const handleFileUpload = async data => {
    setLoading(true);

    const response = await uploadSupportingDocumentFile(data);
    setLoading(false);

    if (response.data && response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setError({ documentUpload: error });
      return;
    }

    if (response.data && response.data.uploadSupportingDocument) {
      notify.show('File uploaded successfully', status.SUCCESS, duration.SHORT);

      return;
    } else {
      setError({ documentUpload: 'File could not be uploaded, kindly retry.' });
      return;
    }
  };

  const handleDocumentUpload = e => {
    const { name, validity, files } = e.target;

    const file = files[0];

    if (file && file.size < 10000000) {
      updateDocs(prevState => ({
        ...prevState,
        [`${name}`]: {
          fileName: file.name,
          fileSize: bytesToSize(file.size),
          validity: validity,
        },
      }));

      const data = {
        selectedFile: e.target,
        documentName: `${name}_${user.id}_${file.name}`,
        userId: user.id,
      };

      handleFileUpload(data);
    } else {
      setError({
        documentUpload: 'Please, upload a file not higher than 10 MB',
      });
    }
  };

  const handleSubmit = useCallback(() => {
    logEvent('Signup', 'Upload a Supporting Document');

    let uploadedRequiredDocs = [];
    requiredSupportingDocuments.forEach(item => {
      if (
        uploadedDocs[item.documentName] &&
        uploadedDocs[item.documentName].fileName
      ) {
        uploadedRequiredDocs.push({ isUploaded: true });
      }
    });

    if (uploadedRequiredDocs.length < requiredSupportingDocuments.length) {
      notify.show(
        'Please Upload Required Supporting Document(s)',
        status.ERROR,
        duration.SHORT,
      );
    } else {
      setValuesUpdated(true);
    }
  });

  useEffect(() => {
    createApplicationTrace(
      pages.supportingDocuments,
      'Navigated to Supporting Document Upload Screen',
    );
  }, []);

  useEffect(() => {
    if (valuesUpdated) {
      setCompletedStep('requireSupportingDoc');
      goToNextPage();
    } else {
      setValuesUpdated(false);
    }
  }, [valuesUpdated, loanApplicationState]);

  return {
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    clientInfo: { requiredSupportingDocuments },
    loading,
    setLoading,
    uploadedDocs,
    updateDocs,
    error,
    setError,
    valuesUpdated,
    setValuesUpdated,
    uploadSupportingDocumentFile,
    handleFileUpload,
    handleDocumentUpload,
    handleSubmit,
  };
};
export default useSupportingDocumentForm;
