import React from 'react';
import { Popup, Button } from '../../components';
import { PdfIcon } from '../../components/Icon';
import { numberWithCommas } from '../../lib/utils';
import { useClientInfoContext } from '../../hooks';
import * as S from './styled';

const ManualRemitalMandate = ({
  handleCloseModal,
  loanAmount,
  debitMandate,
}) => {
  const {
    clientInfo: {
      clientTheme: { secondaryColor },
    },
  } = useClientInfoContext();

  return (
    <S.Wrapper>
      <Popup
        title="Activate Direct Debit"
        closeButton={true}
        handleCloseButton={handleCloseModal}
      >
        <p>
          Your loan request of {numberWithCommas(loanAmount)} has been approved.{' '}
        </p>

        <p>
          Before disbursement, we require that you activate direct debit to
          enable us withdraw directly from your account on withdrawal dates. We
          require that you download the mandate form and take to your bank for
          approval.
        </p>

        <S.Preview>
          <PdfIcon fill={secondaryColor} />

          <a
            href={debitMandate?.formUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Remita Mandate Activation Form.pdf
          </a>
        </S.Preview>

        <S.Btns>
          <Button classes="right">
            <a
              href={debitMandate.formUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </Button>
        </S.Btns>
      </Popup>
    </S.Wrapper>
  );
};

export default ManualRemitalMandate;
