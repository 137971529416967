import styled from 'styled-components';

export const StyledDebitCard = styled.section`
  background-color: ${props => props.theme.primaryColor};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  .radio {
    input {
      :checked + span {
        :after {
          background-color: ${props => props.theme.secondaryColor};
        }
      }
    }
  }
`;

export const MaskPan = styled.section`
  display: flex;
  justify-content: space-between;
  font-size: 2rem;

  div {
    display: flex;
    align-items: center;
  }
  .circle {
    background-color: rgba(243, 243, 243, 0.5);
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-left: 6px;
  }
`;

export const CustomTop = styled.div`
  display: flex;
  align-items: center;

  .circle {
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 50%;
    background-color: ${({ theme, active }) =>
      active ? theme.secondaryColor : ''} !important;
  }

  .left_label {
    color: ${props => props.theme.colors.color_white} !important;
    margin-left: 12px;
    font-size: 12px;
    font-weight: bold;
  }
  .bank_name {
    margin: 0 0 0 auto;
  }
`;
