import React from 'react';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import { Formik, Form } from 'formik';
import { SubmitButton } from '../../../components/FormikElements';
import WorkIdPlaceholder from '../../../static/images/workid-placeholder.jpg';
import useEmploymentLetterUpload from './useEmloymentLetterUploadForm';

const EmploymentLetterUpload = () => {
  const {
    uploadDocumentLoading,
    uploadDocumentError,
    handlePreview,
    handleUpload,
    goToPreviousPage,
    letterDetail,
  } = useEmploymentLetterUpload();
  return (
    <S.Wrapper>
      <DataCard loading={uploadDocumentLoading}>
        <S.Title>Upload employment letter</S.Title>
        {uploadDocumentError && (
          <Alert classes="error">{uploadDocumentError}</Alert>
        )}
        <S.Subtitle>
          Upload a copy of your employment letter or confirmation letter or your
          recent promotion letter
        </S.Subtitle>
        <Formik initialValues={{}} onSubmit={() => handleUpload()}>
          <Form>
            <S.ImageUpload>
              <input
                name="employmentletter"
                type="file"
                accept="image/png, image/jpeg, application/pdf, application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handlePreview}
              />
              <section>
                <img
                  src={letterDetail.url || WorkIdPlaceholder}
                  alt="employmentletter"
                />
              </section>
            </S.ImageUpload>
            <SubmitButton value="Next" />
            <S.PreviousButton
              type="button"
              onClick={goToPreviousPage}
              value="Previous"
            />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default EmploymentLetterUpload;
