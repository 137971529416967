import moment from 'moment';
import { v1 as uuidV1 } from 'uuid';
import { authTypes, CLIENT_ID } from './constants';
import React from 'react';
import naijaXbyState from 'naija-xbystate';
import { addDays, addMonths, addWeeks, addYears, isAfter } from 'date-fns';

export const getErrorMessages = arr => {
  let errorText = '';

  for (let errorMsg = 0; errorMsg < arr.length; errorMsg++) {
    errorText += `${arr[errorMsg].message}\n`;
  }

  return errorText;
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateFacebookUrl = url => {
  const re =
    /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]*)/;
  return re.test(url);
};

const generateRandomCharacter = length => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const generateTransactionReference = () => {
  const date = new Date();
  const dateFormat = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ].join('');

  return `IND-PAYSTACK-${dateFormat}${generateRandomCharacter(4)}`;
};

export const roundWithDoublePrecision = num =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const generateCardReference = () => {
  const date = new Date();
  const dateFormat = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ].join('');

  return `IND-CARD${dateFormat}${generateRandomCharacter(4)}`;
};

export const numberWithCommas = e => {
  const number = typeof e === 'number' ? e : parseFloat(e) || 0;
  return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeCommas = e => {
  const hasComma = /,/;

  return hasComma.test(e) ? e.replace(/,/g, '') : e;
};

export const removeUnderscore = e => {
  return e.replace(/[_-]/g, ' ');
};

export const removeHyphen = str => {
  if (str) {
    return str.replace(/-/g, ' ');
  } else {
    return str;
  }
};

export const validatePasswordStrength = password => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,20})/;

  return re.test(password);
};

export const validatePassportNumber = documentNo => {
  const re = /^([A-Z a-z]){1}([0-9]){7}/;

  return re.test(documentNo);
};

export const validateDriverLicense = documentNo => {
  const re = /^([A-Z a-z]){3}([0-9]){4,5}([A-Z a-z]){2}([0-9]){2}/;

  return re.test(documentNo);
};
export const validateVoterCardNumber = documentNo => {
  const re = /^(?=.*[A-Z])(?=.*[0-9])(?=.{20})/;

  return re.test(documentNo);
};

export const validateMBSTicket = mbsTicketNo => {
  const re = /^(\d{6,8})-(\d{1,3})/;

  return re.test(mbsTicketNo);
};

export const generateUUID = () => uuidV1();

export const authGetter = () => {
  const authCreds = JSON.parse(localStorage.getItem('Auth'));
  const defaultCreds = {
    authenticationType: 'unauthenticated',
    hasApiKey: false,
  };

  if (typeof authCreds === 'undefined' || authCreds == null) {
    return defaultCreds;
  }

  if (typeof authCreds.apiKey !== 'undefined' && authCreds.apiKey != null) {
    const isTempApiKey = authCreds.keyType === authTypes.TEMPORARY;

    return {
      ...authCreds,
      isTempApiKey,
      hasApiKey: true,
      authenticationType: isTempApiKey ? 'unauthenticated' : 'authenticated',
    };
  }

  return defaultCreds;
};

export const round = (value, precision) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const formatPhone = phone => {
  return (
    phone &&
    phone.substr(0, 4) + ' ' + phone.substr(4, 3) + ' ' + phone.substr(7)
  );
};

export const splitCommaToArray = string => {
  return string && string.split(',');
};

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1000 ** i).toFixed(0)} ${sizes[i]}`;
};

export const sumColumn = array => {
  if (array && Object.entries(array).length !== 0) {
    return array
      .filter(item => item.expectedPayment)
      .map(item => item.expectedPayment)
      .reduce((sum, current) => sum + current);
  }
  return 'n/a';
};

export const getLastElement = array => {
  if (array && Object.entries(array).length !== 0) {
    return array
      .slice(-1)
      .map(item => item.dueDate)
      .toString();
  }
  return 'n/a';
};

const degreesToRadians = degrees => {
  return (degrees * Math.PI) / 180;
};

export const distanceInKm = (lat1, lon1, lat2, lon2) => {
  var earthRadiusKm = 6371;

  var dLat = degreesToRadians(lat2 - lat1);
  var dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (earthRadiusKm * c).toFixed(2);
};

export const handleGraphQLErrors = error => {
  let errorMessages = '';
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    for (const element of graphQLErrors) {
      if (element?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
        errorMessages += `${
          element?.message || 'There was an error processing your request.'
        }`;
      } else if (typeof element === 'string') {
        errorMessages += `${element}\n`;
      } else {
        errorMessages += `${element.message}\n`;
      }
    }
  }
  return (errorMessages.length && errorMessages) || null;
};

export const startCase = str => {
  const arr = str.split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const capitalizedCase = arr.join(' ');
  return capitalizedCase;
};

export const trimGraphQLErrors = error => {
  let errorMessages = '';
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    for (const element of graphQLErrors) {
      if (element?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
        return (
          startCase(element?.message) ||
          `There was an error processing your request.`
        );
      } else if (typeof element === 'string') {
        errorMessages += `${element}\n`;
      } else {
        errorMessages += `${element.message}\n`;
      }
    }
  }
  return (errorMessages.length && errorMessages) || null;
};
export const camelize = text => {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};

export const titleCase = str => {
  if (str && typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const getLoanPolicy = generatedOffer => {
  const { chargesAmount, noOfRepayments, baseAmount, repaymentBreakdown } =
    generatedOffer;
  const interestRate =
    Math.round((((chargesAmount / baseAmount) * 100) / noOfRepayments) * 10) /
    10;
  let repaymentType = '';
  let repaymentDate = '';
  let loanTenor = '';
  if (repaymentBreakdown && repaymentBreakdown.length > 1) {
    const duration = moment.duration(
      moment(repaymentBreakdown[1].dueDate).diff(
        moment(repaymentBreakdown[0].dueDate),
      ),
    )._data;
    repaymentDate = moment(repaymentBreakdown[0].dueDate).format('ll');
    loanTenor = `${noOfRepayments} `;
    if (duration.years || duration.months >= 11) {
      repaymentType = 'Annually';
      loanTenor += noOfRepayments === 1 ? 'Year' : 'Years';
    } else if (duration.months || duration.days >= 28) {
      repaymentType = 'Monthly';
      loanTenor += noOfRepayments === 1 ? 'Month' : 'Months';
    } else if (duration.days >= 6) {
      repaymentType = 'Weekly';
      loanTenor += noOfRepayments === 1 ? 'Week' : 'Weeks';
    } else if (duration.days === 1) {
      repaymentType = 'Daily';
      loanTenor += noOfRepayments === 1 ? 'Day' : 'Days';
    }
  } else {
    repaymentType = 'flat';
    repaymentDate = moment(repaymentBreakdown[0].dueDate).format('ll');
  }
  return { interestRate, repaymentType, repaymentDate, loanTenor };
};

export const toTitleCase = str => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
};

export const getStatusName = status => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'PEN';
    case 'approved':
      return 'APP';
    case 'denied':
      return 'DEN';
    case 'under_review':
      return 'REV';
    case 'active':
      return 'ACT';
    case 'pending_disbursement':
      return 'PEN';
    case 'Pending_manual_disbursement':
      return 'PEN';
    case 'disbursing':
      return 'DIS';
    case 'disbursed':
      return 'ACT';
    case 'closed':
      return 'CLO';
    case 'overdue':
      return 'OVR';
    case 'paid':
      return 'CLO';
    case 'abandoned':
      return 'ABA';
    default:
      return 'PEN';
  }
};

export const validateSocialMediaUrl = (name, url) => {
  let re;
  switch (name) {
    case 'twitter':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w*]*)/;
      return re.test(url);
    case 'facebook':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]*)/;
      return re.test(url);
    case 'linkedin':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?linkedin\.com\/((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))/;
      return re.test(url);
    case 'instagram':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im;
      return re.test(url);
    default:
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]*)/;
      return re.test(url);
  }
};

const today = new Date();
export const dobMaxSelectableDate = today.setFullYear(today.getFullYear() - 12);
export const maxSelectableDate = new Date();

export const CONTEXTS = {
  DASHBOARD: 'REGISTERED_USER',
};

export const MANDATE_TYPES = {
  LIDYA: 'LIDYA',
  RECOVA: 'RECOVA',
};

export const PENDING_DISBURSEMENT_STATUSES = [
  'PENDING_DISBURSEMENT',
  'PENDING_MANUAL_DISBURSEMENT',
];

export const getDisplayInterestRate = (application = {}, portfolio = {}) => {
  let interestRate = 'N/A';
  const { policy: aPolicy, amount: aAmount } = application;
  const { policy: pPolicy, amount: pAmount } = portfolio;
  const policy = pPolicy || aPolicy || {};
  const amount = pAmount || aAmount;

  if (policy.interestRate) {
    interestRate = policy.interestRate.value;
  } else if (
    policy.graduatedLoanCycles &&
    policy.graduatedLoanCycles.length > 0
  ) {
    const gCycle = policy.graduatedLoanCycles.find(
      cycle => cycle.amount === amount,
    );
    if (gCycle && gCycle.interestRate) {
      interestRate = gCycle.interestRate;
    }
  }
  return interestRate;
};

export const replaceTideSymbol = e => e.split('~');

export const setGlobalClientId = clientId => {
  localStorage.setItem(CLIENT_ID, clientId);
};

export const getGlobalClientId = () => {
  return localStorage.getItem(CLIENT_ID);
};

export const formatNumber = number => {
  if (typeof number !== 'number') return number;

  return Intl.NumberFormat(navigator.language || 'en-GB', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

export const debounce = (func, timeout = 300) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const populateStates = () => {
  const nigeriaStates = naijaXbyState.all().map(item => item.state);
  return nigeriaStates.map((state, index) => (
    <option key={index}>{state}</option>
  ));
};

export const getLocalGovernmentArea = state => {
  if (state !== '') {
    return naijaXbyState
      .lgas(state)
      .map((lga, index) => <option key={index}>{lga}</option>);
  }
};

export const formatLoanDuration = duration => {
  switch (duration) {
    case '1 days':
      return '1 day';
    case '1 weeks':
      return '1 week';
    case '1 months':
      return '1 month';
    case '1 years':
      return '1 year';
    default:
      return duration;
  }
};

export const validateCardAndDuration = (duration, cards) => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  let totalDurationDate = new Date(year, month);

  const durations = duration.split(' ');
  switch (durations[1]) {
    case 'weeks':
      totalDurationDate = addWeeks(totalDurationDate, durations[0]);
      break;
    case 'months':
      totalDurationDate = addMonths(totalDurationDate, durations[0]);
      break;
    case 'years':
      totalDurationDate = addYears(totalDurationDate, durations[0]);
      break;
    default:
      totalDurationDate = addDays(totalDurationDate, durations[0]);
  }

  const aValidCard = cards.find(card => {
    const cardExpiryDate = new Date(
      `20${card.expiryDate.slice(-2)}`,
      card.expiryDate.slice(0, 2),
    );
    return !isAfter(totalDurationDate, cardExpiryDate);
  });

  if (!aValidCard) {
    return {
      valid: false,
      message:
        'Invalid card. Please add a card that is valid for the loan period.',
    };
  }

  return { valid: true };
};

export const maskPhoneNumber = phone =>
  phone?.replace(phone.substring(5, 9), '****');

export function excludeProperties(data, keyToRemove) {
  // Check if the provided data is not an object or keyToRemove is not a string
  if (
    typeof data !== 'object' ||
    !keyToRemove ||
    typeof keyToRemove !== 'string'
  ) {
    return null; // Return null or handle error based on your requirement
  }

  // Create a copy of the original object to avoid mutating the original data
  const parsedData = { ...data };

  // Remove the specified key from the copied object
  delete parsedData[keyToRemove];

  // Return the modified object
  return parsedData;
}

export function getAttributeByLoanCategoryId(
  data,
  loanCategoryId,
  attributeName,
) {
  // Find the loan category by ID
  const loanCategory = data.find(category => category.id === loanCategoryId);

  if (!loanCategory) {
    return null; // or throw an error, depending on your preference
  }

  // Find the attribute by name within the found loan category
  const attribute = loanCategory.loanCategoryAttributes.find(
    attr => attr.attribute.name === attributeName,
  );

  if (attribute) {
    return attribute?.value;
  } else {
    null;
  }
}

export const isAbsolutePath = url => {
  if (url?.includes('http')) {
    return url;
  } else {
    return `//${url}`;
  }
};
