import React from 'react';
import { useClientInfoContext } from '../../hooks';

const Logo = () => {
  const {
    clientInfo: { logoUrl },
  } = useClientInfoContext();

  return <img alt="Logo" src={logoUrl} />;
};

export default Logo;
