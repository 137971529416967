import React from 'react';
import { Link } from 'react-router-dom';

import './_TrigerResetPassword.scss';

import { Button, DataCard, Input, Alert } from '../../components';
import TriggerResetPwdProvider from '../providers/Auth/TriggerResetPwdProvider';

const TriggerResetPassword = () => (
  <TriggerResetPwdProvider>
    {({ onSubmit, loading, errorMessage, form }) => (
      <section className="container forgot-password">
        <DataCard title="Enter your email address to reset your password">
          <form onSubmit={onSubmit}>
            {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
            <Input
              type="email"
              placeholder="Email Address"
              name="email"
              readOnly={false}
              classes={`border-bottom ${
                form.email.input.errorMessage ? 'error' : ''
              }`}
              {...form.email.input}
            />
            <Button
              type="submit"
              classes={`center ${loading ? 'loading' : ''}`}
            >
              Reset Password
            </Button>
            <div className="create-account">
              Don't have an account? <Link to="/sign-up">Sign Up</Link>
            </div>
          </form>
        </DataCard>
      </section>
    )}
  </TriggerResetPwdProvider>
);

export default TriggerResetPassword;
