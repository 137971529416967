import * as React from 'react';

const PdfIcon = ({ fill }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1528 0H4.85241C4.43392 0.00142795 4.04572 0.218666 3.82531 0.574762L0.590378 3.81286C0.223513 4.03251 -0.000771634 4.42924 1.99491e-06 4.85714V15.7857C1.99491e-06 16.4563 0.543126 17 1.2131 17H14.1528C14.8228 17 15.3659 16.4563 15.3659 15.7857V1.21429C15.3659 0.543654 14.8228 0 14.1528 0ZM3.63937 2.47712V3.64284H2.47479L3.63937 2.47712ZM14.1528 15.7857H1.21301V4.89762H1.23728L1.26154 4.87334H4.85232V1.27905L4.87658 1.25477V1.23048H14.1366L14.1528 15.7857Z"
      fill={fill || '#0077FF'}
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7268 9.30951H3.63948C3.30449 9.30951 3.03293 9.58134 3.03293 9.91665C3.03293 10.252 3.30449 10.5238 3.63948 10.5238H11.7268C12.0618 10.5238 12.3334 10.252 12.3334 9.91665C12.3334 9.58134 12.0618 9.30951 11.7268 9.30951Z"
      fill={fill || '#0077FF'}
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5137 12.1429H3.63948C3.30449 12.1429 3.03293 12.4147 3.03293 12.75C3.03293 13.0853 3.30449 13.3572 3.63948 13.3572H10.5137C10.8487 13.3572 11.1203 13.0853 11.1203 12.75C11.1203 12.4147 10.8487 12.1429 10.5137 12.1429Z"
      fill={fill || '#0077FF'}
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89605 7.69046H11.3223C11.9922 7.69046 12.5354 7.1468 12.5354 6.47617V4.0476C12.5354 3.37697 11.9922 2.83331 11.3223 2.83331H8.89605C8.22608 2.83331 7.68295 3.37697 7.68295 4.0476V6.47617C7.68295 7.1468 8.22608 7.69046 8.89605 7.69046ZM8.89582 4.04761H11.322V6.47618H8.89582V4.04761Z"
      fill={fill || '#0077FF'}
    />
  </svg>
);

export default PdfIcon;
